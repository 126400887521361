import React, { FC, JSX } from 'react';
import { observer } from 'mobx-react-lite';
import { ProductItemReserve } from '../../../api/marketx';
import { Grid, IconButton, Stack } from '@mui/material';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import { Link as MxLink, MxLabel } from '@mx-ui/ui';
import { ThreadLink } from '../../Bills/ThreadLink';
import AcUnitOutlinedIcon from '@mui/icons-material/AcUnitOutlined';
import QuizOutlinedIcon from '@mui/icons-material/QuizOutlined';
import Typography from '@mui/material/Typography';
import HdrAutoOutlinedIcon from '@mui/icons-material/HdrAutoOutlined';
import { billShipmentStateColor, billStateColor, paymentStateColor } from '../../Bills/BillListItem';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ReserveListStore } from 'src/store/ReserveListStore';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useRouter } from 'next/router';

interface ReserveItemHeaderProps {
  reserveStore?: ReserveListStore;
  reserve: ProductItemReserve;
  documentNumber?: string;
  selectedWarehouse?: string;
  isForReport?: boolean;
}

export const ReserveItemHeader: FC<ReserveItemHeaderProps> = observer(
  // eslint-disable-next-line complexity
  ({ reserve, documentNumber, selectedWarehouse, isForReport, reserveStore }): JSX.Element => {
    const matches = useMediaQuery('(min-width:650px)');
    const theme = useTheme();
    const backgroundColor =
      (documentNumber === reserve.billDocumentNumber && !!reserve.billDocumentNumber && !!documentNumber) ||
      (documentNumber === reserve.freezeDocumentNumber && !!reserve.freezeDocumentNumber && !!documentNumber)
        ? theme.palette.mode === 'dark'
          ? '#121212'
          : '#F5F5F5'
        : 'background.paper';
    const isSelectedWarehouse = selectedWarehouse ? reserve.warehouseCode === selectedWarehouse : true;
    const colorText = isSelectedWarehouse ? 'textPrimary' : 'textSecondary';
    const fontWeight = selectedWarehouse && isSelectedWarehouse ? 500 : undefined;
    const isReserveAwaiting = useRouter().asPath.includes('reserves-awaiting');
    const isProductReserve = useRouter().asPath.includes('app/product');

    const handleBillPaymentChange = (status?: string): void => {
      if (reserveStore.request?.paymentStateCodes?.includes(status)) {
        return;
      }
      reserveStore.mergeRequest({ paymentStateCodes: [status] });
    };

    const handleBillPaymentRemoveChange = (status?: string): void => {
      if (!reserveStore.request?.paymentStateCodes?.includes(status)) {
        return;
      }
      const newPaymentStateCodes = reserveStore.request?.paymentStateCodes?.filter(i => i !== status);
      reserveStore?.mergeRequest({ paymentStateCodes: newPaymentStateCodes });
    };

    const handleProductCodeChange = (productCode?: string): void => {
      if (reserveStore.request?.productCode?.includes(productCode)) {
        return;
      }
      reserveStore.mergeRequest({ productCode: productCode });
    };

    const handleProductCodeRemoveChange = (): void => {
      reserveStore?.mergeRequest({ productCode: undefined });
    };

    const handleAutoReserveChange = (): void => {
      reserveStore?.mergeRequest({ autoReserveEnabled: true });
    };

    const handleAutoReserveRemoveChange = (): void => {
      reserveStore?.mergeRequest({ autoReserveEnabled: false });
    };

    return (
      <Grid container sx={{ backgroundColor: backgroundColor }}>
        <Grid item xs={matches ? 6 : 12}>
          <Grid container>
            {!!reserve.billDocumentNumber ? (
              <Grid item xs={12} mb={1}>
                <Grid container alignItems="center">
                  <Grid item pr={1} height={'24px'}>
                    <CreditCardOutlinedIcon />
                  </Grid>
                  <Grid item>
                    <MxLink
                      href={`/app/bills/${reserve.billDocumentNumber}`}
                      variant="body2"
                      color={colorText}
                      typographySx={{ fontWeight: documentNumber === reserve.billDocumentNumber ? 600 : fontWeight }}
                    >
                      {reserve.billDocumentNumber}
                    </MxLink>
                  </Grid>
                  <Grid item>
                    <ThreadLink link={`/app/thread/bills/${encodeURIComponent(reserve.billDocumentNumber)}`} />
                  </Grid>
                  {reserve.autoReserveEnabled && (
                    <Grid item height={'20px'} pr={1} title={'Авторезерв'}>
                      <Stack flexDirection={'row'} alignItems={'flex-start'}>
                        <HdrAutoOutlinedIcon fontSize={'small'} />
                        {reserveStore && isReserveAwaiting ? (
                          reserveStore?.request?.autoReserveEnabled === reserve?.autoReserveEnabled ? (
                            <IconButton
                              size={'small'}
                              onClick={() => handleAutoReserveRemoveChange()}
                              title={`Убрать фильтрацию по авторезерву`}
                              sx={{ p: '0 4px 0 4px' }}
                            >
                              <DoDisturbOnOutlinedIcon color={'primary'} sx={{ fontSize: '20px' }} />
                            </IconButton>
                          ) : (
                            <IconButton
                              size={'small'}
                              onClick={() => handleAutoReserveChange()}
                              title={`Найти счета с авторезервом`}
                              sx={{ p: '0 4px 0 4px' }}
                            >
                              <FilterListIcon color={'primary'} sx={{ fontSize: '20px' }} />
                            </IconButton>
                          )
                        ) : null}
                      </Stack>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            ) : null}
            {!!reserve.freezeDocumentNumber ? (
              <Grid item xs={12} mb={1}>
                <Grid container alignItems="center">
                  <Grid item pr={1} height={'24px'}>
                    <AcUnitOutlinedIcon />
                  </Grid>
                  <Grid item>
                    <MxLink
                      href={`/app/freeze/${reserve.freezeDocumentNumber}`}
                      variant="body2"
                      color={colorText}
                      typographySx={{ fontWeight: documentNumber === reserve.freezeDocumentNumber ? 600 : fontWeight }}
                    >
                      {reserve.freezeDocumentNumber}
                    </MxLink>
                  </Grid>
                  {reserve.autoReserveEnabled && (
                    <Grid item height={'20px'} pr={1} title={'Авторезерв'}>
                      <HdrAutoOutlinedIcon fontSize={'small'} />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            ) : null}
            {!!reserve.documentNumber && !reserve.billDocumentNumber && !reserve.freezeDocumentNumber ? (
              <Grid item xs={12} mb={1}>
                <Grid container alignItems="center">
                  <Grid item pr={1} height={'24px'}>
                    <QuizOutlinedIcon />
                  </Grid>
                  <Grid item>
                    <Typography variant="body1">{reserve.documentNumber}</Typography>
                  </Grid>
                  {reserve.autoReserveEnabled && (
                    <Grid item height={'20px'} pr={1} title={'Авторезерв'}>
                      <HdrAutoOutlinedIcon fontSize={'small'} />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            ) : null}

            {reserve?.customerInn ? (
              <Grid container mb={1} alignItems="center">
                <Grid item md={4} xs={6}>
                  <Typography variant="body2">ИНН {reserve?.customerInn}</Typography>
                </Grid>
              </Grid>
            ) : null}

            {reserve?.customerCode ? (
              <Grid item xs={12} mb={1}>
                <MxLink
                  href={`/app/clients/${reserve?.customerCode}`}
                  variant="body2"
                  color={colorText}
                  typographySx={{ fontWeight: fontWeight }}
                >
                  {reserve.customerShort || reserve.customerTitle || '-'}
                </MxLink>
              </Grid>
            ) : (
              ((reserve.customerShort || reserve.customerTitle) && (
                <Grid item xs={12} mb={1}>
                  <Typography variant="body2" color={colorText} fontWeight={fontWeight}>
                    {reserve.customerShort || reserve.customerTitle}
                  </Typography>
                </Grid>
              )) ||
              null
            )}
            {reserve?.billStateCode ? (
              <Grid container mb={1} alignItems="center">
                <Grid item md={4} xs={6}>
                  <Typography fontSize="14px" color="text.secondary">
                    Статус
                  </Typography>
                </Grid>
                <Grid item md={8} xs={6}>
                  <MxLabel color={billStateColor(reserve?.billStateCode)} style={{ fontSize: '10px' }}>
                    {reserve?.billStateTitle}
                  </MxLabel>
                </Grid>
              </Grid>
            ) : null}
            {reserve?.freezeDocumentNumber ? (
              <Grid container mb={1} alignItems="center">
                <Grid item md={4} xs={6}>
                  <Typography fontSize="14px" color="text.secondary">
                    Статус
                  </Typography>
                </Grid>
                <Grid item md={8} xs={6}>
                  <MxLabel color={'success'} style={{ fontSize: '10px' }}>
                    Резерв подтвержден
                  </MxLabel>
                </Grid>
              </Grid>
            ) : null}
            {reserve.dealEmployeeSurname || reserve.dealEmployeeName || reserve.dealEmployeePatronymic ? (
              <Grid container mb={1} alignItems="center">
                <Grid item md={4} xs={6}>
                  <Typography fontSize="14px" color="text.secondary">
                    Реализатор
                  </Typography>
                </Grid>
                <Grid item md={8} xs={6}>
                  <Typography fontSize="14px" color="text.secondary">
                    {`${reserve.dealEmployeeSurname || ''} ${reserve.dealEmployeeName || ''} ${
                      reserve.dealEmployeePatronymic || ''
                    }`.trim()}
                  </Typography>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </Grid>

        <Grid item xs={matches ? 6 : 12}>
          {reserve?.billPaymentStateCode ? (
            <Grid container mb={1} alignItems="center">
              <Grid item md={4} xs={6}>
                <Typography fontSize="14px" color="text.secondary">
                  Статус оплаты
                </Typography>
              </Grid>
              <Grid item md={8} xs={6} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                <MxLabel color={paymentStateColor(reserve?.billPaymentStateCode)} style={{ fontSize: '10px' }}>
                  {reserve.billPaymentStateTitle}
                </MxLabel>
                {reserveStore ? (
                  reserveStore?.request?.paymentStateCodes?.includes(reserve?.billPaymentStateCode) ? (
                    <Grid item md={8} xs={6}>
                      <IconButton
                        size={'small'}
                        onClick={() => handleBillPaymentRemoveChange(reserve?.billPaymentStateCode)}
                        title={`Убрать фильтрацию по статусу оплаты ${reserve?.billPaymentStateTitle}`}
                      >
                        <DoDisturbOnOutlinedIcon color={'primary'} sx={{ fontSize: '20px' }} />
                      </IconButton>
                    </Grid>
                  ) : (
                    <Grid item ml={1}>
                      <IconButton
                        size={'small'}
                        onClick={() => handleBillPaymentChange(reserve?.billPaymentStateCode)}
                        title={`Найти счета по статусу оплаты ${reserve?.billPaymentStateTitle}`}
                      >
                        <FilterListIcon color={'primary'} sx={{ fontSize: '20px' }} />
                      </IconButton>
                    </Grid>
                  )
                ) : null}
              </Grid>
            </Grid>
          ) : null}
          {reserve?.shipmentStateCode ? (
            <Grid container mb={1} alignItems="center">
              <Grid item md={4} xs={6}>
                <Typography fontSize="14px" color="text.secondary">
                  Статус отгрузки
                </Typography>
              </Grid>
              <Grid item md={8} xs={6}>
                <MxLabel color={billShipmentStateColor(reserve?.shipmentStateCode)} style={{ fontSize: '10px' }}>
                  {reserve.shipmentStateTitle || ''}
                </MxLabel>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
        {isForReport && reserve?.productCode && (
          <Grid item xs={12} display={'flex'} flexDirection={'row'} alignItems={'center'}>
            <MxLink
              href={`/app/product?productCode=${encodeURIComponent(reserve.productCode)}${
                reserve.warehouseCode ? `&warehouseCode=${encodeURIComponent(reserve.warehouseCode)}` : ''
              }`}
              fontSize={'14px'}
              color={'text.primary'}
            >
              {`${reserve?.productCode} ${reserve.productTitle}` || ''}
            </MxLink>
            {reserveStore && !isProductReserve ? (
              reserveStore?.request?.productCode?.includes(reserve?.productCode) ? (
                <Grid item ml={1}>
                  <IconButton
                    size={'small'}
                    onClick={() => handleProductCodeRemoveChange()}
                    title={`Убрать фильтрацию по товару ${reserve?.productTitle}`}
                  >
                    <DoDisturbOnOutlinedIcon color={'primary'} sx={{ fontSize: '20px' }} />
                  </IconButton>
                </Grid>
              ) : (
                <Grid item ml={1}>
                  <IconButton
                    size={'small'}
                    onClick={() => handleProductCodeChange(reserve?.productCode)}
                    title={`Найти счета по товару ${reserve?.productTitle}`}
                  >
                    <FilterListIcon color={'primary'} sx={{ fontSize: '20px' }} />
                  </IconButton>
                </Grid>
              )
            ) : null}
          </Grid>
        )}
      </Grid>
    );
  }
);
