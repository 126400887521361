import React, { FC, JSX } from 'react';
import { observer } from 'mobx-react-lite';
import Grid from '@mui/material/Grid';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link as MxLink } from '@mx-ui/ui';
import { ClientTitleBreadcrumb } from '../../views/deals/DealHeader';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ThreadLink } from '../Bills/ThreadLink';
import Skeleton from '@mui/material/Skeleton';
import { Customer } from '../../api/marketx';
import { saleDocuments } from '../../store/Documents/DocumentsSearchStore';

export type DocTypes = 'agreements' | 'contracts' | 'deals' | 'bills' | 'shipments' | 'returns' | 'payments' | 'debts' | 'approvals';

interface SalesCrumbsProps {
  customer?: Customer;
  docName?: DocTypes;
  threadLink?: string;
  docDescription?: string;
  isLoaded?: boolean;
}

export const SalesCrumbs: FC<SalesCrumbsProps> = observer(
  ({ customer, docName, threadLink, docDescription, isLoaded = true }): JSX.Element => {
    return (
      <Grid item mb={1}>
        <Grid container alignItems="center">
          <Grid item>
            <Breadcrumbs sx={{ cursor: 'default', display: 'inline-flex' }}>
              <MxLink href={'/app/sales'} variant="body1" color="textPrimary">
                Продажи
              </MxLink>
              {docName ? (
                saleDocuments[docName].href ? (
                  <MxLink href={saleDocuments[docName].href} variant="body1" color="textPrimary">
                    {saleDocuments[docName].title}
                  </MxLink>
                ) : (
                  <MxLink variant="body1" color="textPrimary">
                    {saleDocuments[docName].title}
                  </MxLink>
                )
              ) : null}
              {customer && <ClientTitleBreadcrumb customer={customer} />}
              {isLoaded && docDescription && (
                <Grid
                  position={'relative'}
                  sx={{
                    ['@media (max-width:500px)']: {
                      width: '97%',
                    },
                  }}
                >
                  {docDescription && (
                    <Typography variant="body1" color="text.secondary">
                      {docDescription}
                    </Typography>
                  )}
                  {threadLink && (
                    <Box position={'absolute'} top={'-2px'} right={'-30px'}>
                      <ThreadLink link={threadLink} />
                    </Box>
                  )}
                </Grid>
              )}
              {!isLoaded && <Skeleton variant="text" width={400} />}
            </Breadcrumbs>
          </Grid>
        </Grid>
      </Grid>
    );
  }
);
