import { RootStore } from './StoreManager';
import { ApiStore, AxiosCallContext, getCallContext } from './Global/ApiStore';
import { makeAutoObservable, runInAction, set, toJS } from 'mobx';
import { Batch, BatchesResponse, BatchPack, Product } from '../api/marketx';
import { formatDateSwagger, toFloat } from '@mx-ui/helpers';
import { setClear } from 'src/utils/mobx';
import { RouterStore } from './RouterStore';
import { dateEnd, dateStart, defaultQuickDate } from './Documents/utils/defaultQuickDate';
import { quickDateRanges, RangeVariant } from 'src/components/SelectDateRange/MenuButtonNew';
const defaultPageSize = 12;

export interface BatchWithPacks extends Batch {
  packs?: BatchPack[];
  product?: Product;
  inStock?: number;
  inStockTne?: number;
}

export interface BatchIndicatorsCosts {
  illiquidMrcUnitCost: number;
  todhMrcUnitCost: number;
}

export interface BatchIndicatorsTarget {
  minRetailUnitCost?: number | string;
}

export const calculateBatchIndicators = (target: BatchIndicatorsTarget, batch: Batch): BatchIndicatorsCosts => {
  const res = <BatchIndicatorsCosts>{
    illiquidMrcUnitCost: 0,
    todhMrcUnitCost: 0,
  };

  if (target) {
    res.illiquidMrcUnitCost = (toFloat(target?.minRetailUnitCost ?? 0) * (100 - (batch?.illiquidDiscountPct ?? 0))) / 100;
    res.todhMrcUnitCost = (toFloat(target?.minRetailUnitCost ?? 0) * (100 - (batch?.todhDiscountPct ?? 0))) / 100;
  }

  return res;
};

export class BatchesListRequest {
  customerCode?: string;
  query?: string;
  page?: number;

  officeCodes?: string[];
  clientCode?: string;
  quickRange?: string;
  count?: number;
  dateFrom?: Date;
  dateTo?: Date;
}
export class BatchesListStore {
  apiStore?: ApiStore;
  routerStore: RouterStore;

  public isLoading = false;
  quickRange: RangeVariant = defaultQuickDate;
  requestInit: BatchesListRequest = {};

  public ignoreBeforeDate?: Date;
  public isLoaded = false;
  public isMoreLoading = false;
  public routerControlEnabled = false;

  request: BatchesListRequest = {
    count: defaultPageSize,
    query: '',
    dateFrom: dateStart,
    dateTo: dateEnd,
  };

  public hasMore = false;

  public items = new Array<BatchWithPacks>();

  constructor(rootStore: RootStore) {
    this.apiStore = rootStore.getApiStore();
    this.routerStore = rootStore.getRouter();
    this.loadMore = this.loadMore.bind(this);
    makeAutoObservable(this, {
      apiStore: false,
      transformItems: false,
    });
  }
  changeQuickRange(v: RangeVariant): void {
    this.quickRange = v;
    set(this.request, { quickRange: v.value });
  }
  transformItems(resWithItems: BatchesResponse): BatchWithPacks[] {
    const { batches, packs, products } = resWithItems;
    return batches.reduce((acc, item) => {
      const newItem: BatchWithPacks = item;
      newItem.inStock = 0;
      newItem.inStockTne = 0;
      newItem.packs = packs.filter(i => i.batchCode === item.code);
      newItem.product = products.find(i => i.code === item.productCode);
      newItem.packs.forEach(pack => {
        const inStockToTneRequired =
          pack.inStockUnitCode &&
          pack.inStockUnitCode == newItem.product.multiplicityUnitCode &&
          newItem.product.multiplicity &&
          newItem.product.multiplicity !== 1;
        const inStockToTneFactor = inStockToTneRequired ? newItem.product.multiplicity : 1;
        newItem.inStock += pack.inStock || 0;
        newItem.inStockTne += (pack.inStock || 0) * inStockToTneFactor;
      });

      acc.push(newItem);
      return acc;
    }, []);
  }
  mergeRequest(req: BatchesListRequest): void {
    set(this.request, req);
    this.request.page = undefined;
    this.isLoaded = false;
    this.isMoreLoading = false;
    this.isLoading = true;
    this.actualizeRouter(toJS(this.request));
    this.refresh();
  }
  actualizeRouter(req: BatchesListRequest): void {
    if (!this.routerControlEnabled) {
      return;
    }
    const params = new URLSearchParams();

    if (req.query) {
      params.set('query', req.query);
    }
    if (req.quickRange) {
      params.set('quickRange', req.quickRange);
    }
    if (req.dateFrom) {
      params.set('dateFrom', formatDateSwagger(req.dateFrom));
    }
    if (req.dateTo) {
      params.set('dateTo', formatDateSwagger(req.dateTo));
    }
    // params.set('csid', String(this.storeIdentifier));
    let paramsStr = params.toString();
    if (paramsStr) {
      paramsStr = '?' + paramsStr;
    }
    let url = '/app/batches';
    url += paramsStr;
    this.routerStore.replace(url, undefined, { shallow: true });
  }
  refresh(): void {
    this.loadList(this.request);
  }
  setRouterControl(enabled: boolean): void {
    this.routerControlEnabled = enabled;
  }
  setRequestInit(req: BatchesListRequest): void {
    setClear(this.requestInit, req);
  }
  loadList(r: BatchesListRequest): void {
    this.ignoreBeforeDate = new Date();
    this.isLoading = true;
    set(this.request, r);
    const req = toJS(this.request);
    req.count = req.count || defaultPageSize;
    runInAction(() => {
      if (req.quickRange) {
        this.quickRange = quickDateRanges.find(t => t.value === req.quickRange);
      }
    });
    this.apiStore
      .apiClientBatches()
      .batches({
        branchOfficeCodes: req.officeCodes,
        query: req.query || undefined,
        page: req.page || undefined,
        count: req.count,
        dateFrom: req.dateFrom ? formatDateSwagger(req.dateFrom) : undefined,
        dateTo: req.dateFrom ? formatDateSwagger(req.dateTo) : undefined,
        isBranchOfficesRequired: false,
      })

      .then((res): void => {
        this.loadListResponse(getCallContext(res), req, res.data);
      });
  }
  loadListResponse(ctx: AxiosCallContext, req: BatchesListRequest, res: BatchesResponse): void {
    if (this.ignoreBeforeDate && this.ignoreBeforeDate.getTime() > ctx.startTime.getTime()) {
      console.log('ignore irrelevant consignee list response');
      return;
    }
    this.ignoreBeforeDate = ctx.startTime;
    if (this.isMoreLoading) {
      this.isMoreLoading = false;
      this.items.push(...this.transformItems(res));
    } else {
      this.items = this.transformItems(res);
    }
    this.isLoading = false;
    this.isLoaded = true;
    this.hasMore = res.batches.length >= req.count;
  }

  loadMore(): void {
    if (this.isLoading) {
      // уже загружается
      return;
    }
    this.isMoreLoading = true;
    this.request.page = (this.request.page || 1) + 1;
    this.loadList(this.request);
  }
}
