import { makeAutoObservable } from 'mobx';
import { ApiStore } from './Global/ApiStore';
import { AppUserGroupCodes } from '../types/AppUserGroup';
import { AuthStore } from './AuthStore';
import { ReactElement } from 'react';

const accessUserGroupCodes: string[] = [
  AppUserGroupCodes.SELLER,
  AppUserGroupCodes.SELLER_CHIEF,
  AppUserGroupCodes.OFFICE_CHIEF,
  AppUserGroupCodes.OFFICE_MARKETER,
  AppUserGroupCodes.DIVISION_CHIEF,
  AppUserGroupCodes.COMPANY_CHIEF,
  AppUserGroupCodes.COMPANY_MARKETER,
  AppUserGroupCodes.ADMIN,
  AppUserGroupCodes.MOTIVATION_RESPONSIBLE,
  AppUserGroupCodes.CUSTOMER_EDITOR,
  AppUserGroupCodes.DIVISION_MARKETER,
  AppUserGroupCodes.CALL_CENTER_OPERATOR,
  AppUserGroupCodes.SUPPORT,
];

const accessMotivationUserGroupCodes: string[] = [
  AppUserGroupCodes.ADMIN,
  AppUserGroupCodes.MOTIVATION_RESPONSIBLE,
  AppUserGroupCodes.OFFICE_CHIEF,
  AppUserGroupCodes.DIVISION_CHIEF,
  AppUserGroupCodes.COMPANY_CHIEF,
  AppUserGroupCodes.SELLER,
  AppUserGroupCodes.SELLER_CHIEF,
];
interface MenuSection {
  href?: string;
  name: string;
  icon?: ReactElement;
  title: string;
  access?: string[];
  accessFunc?: (auth: AuthStore) => boolean;
}
export type ReportCatNames =
  | 'targets'
  | 'motivation'
  | 'payment'
  | 'receivable'
  | 'indicators'
  | 'agreements'
  | 'reserves'
  | 'metabase'
  | 'metabase_analytics';
export const linksWithAccess: Record<string, MenuSection> = {
  targets: {
    title: 'Показатели',
    name: 'reportTargets',
    href: '/app/report/targets',
    access: accessUserGroupCodes,
  },
  motivation: {
    title: 'Премии',
    name: 'reportMotivation',
    href: '/app/report/motivation',
    access: accessMotivationUserGroupCodes,
  },
  payment: {
    title: 'График платежей',
    name: 'reportPayment',
    href: '/app/report/payment',
    access: accessUserGroupCodes,
  },
  receivable: {
    title: 'Кредитный менеджмент',
    name: 'reportReceivable',
    href: '/app/report/receivable',
    access: accessUserGroupCodes,
  },
  indicators: {
    title: 'Воронка продаж',
    name: 'reportIndicators',
    href: '/app/report/indicators',
    access: accessUserGroupCodes,
  },
  agreements: {
    title: 'Соглашения',
    name: 'reportAgreements',
    href: '/app/report/agreements',
    access: accessUserGroupCodes,
  },
  reserves: {
    title: 'Резервы',
    name: 'reportReserves',
    href: '/app/report/reserves',
    access: accessUserGroupCodes,
  },
  metabase: {
    title: 'Управленческие',
    name: 'graphs',
    href: '/app/tools/metabase',
    access: [AppUserGroupCodes.ADMIN, AppUserGroupCodes.MANAGEMENT_REPORTING],
  },
  metabase_analytics: {
    title: 'Изменение МРЦ',
    name: 'analytics',
    href: '/app/tools/metabase_analytics',
    access: [AppUserGroupCodes.ADMIN, AppUserGroupCodes.MANAGEMENT_REPORTING],
  },
};

export class MenuSuccessStore {
  apiStore: ApiStore;
  authStore: AuthStore;
  constructor(authStore: AuthStore) {
    this.apiStore = authStore.apiStore;
    this.authStore = authStore;
    makeAutoObservable(this);
  }

  hasReportAccessByName(menuName: ReportCatNames): boolean {
    if (menuName in linksWithAccess) {
      return this.authStore.inAnyGroup(linksWithAccess[menuName].access);
    }
    return true;
  }
}
