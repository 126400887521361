import React, { FC, JSX, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { DealItemStore } from '../../../store/DealItemStore';
import { TabSelectWrapper } from '../../../components/Deals/CustomIconTabs/TabSelectWrapper';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Address, ParticipantPartner, Subdivision } from '../../../api/marketx';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import NearMeTwoToneIcon from '@mui/icons-material/NearMeTwoTone';
import { Link as MxLink } from '@mx-ui/ui';
import HubRoundedIcon from '@mui/icons-material/HubRounded';
import { DealSummaryTabHeader } from './DealSummaryTabHeader';

interface DealSummaryPartnersProps {
  dealStore: DealItemStore;
  minHeightForTabSections?: string;
}

export const DealSummaryPartners: FC<DealSummaryPartnersProps> = observer(({ dealStore, minHeightForTabSections }): JSX.Element => {
  const deal = dealStore.deal;
  const [consigneeValue, setConsigneeValue] = useState(dealStore.deal.partners?.consignee);
  const [addressValue, setAddressValue] = useState(dealStore.deal.partners.consignee?.address);
  const [payerValue, setPayerValue] = useState(dealStore.deal.partners.payer);
  const [subdivisionValue, setSubdivisionValue] = useState<Subdivision | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const dealPartnersStore = dealStore.dealPartnersStore;

  useEffect(() => {
    if (dealPartnersStore && dealStore.deal && !dealPartnersStore.dealCode) {
      dealPartnersStore.loadListForDeal(dealStore.deal);
    }
  }, [dealStore.deal, dealPartnersStore]);

  useEffect(() => {
    if (dealPartnersStore.subdivisionsList && deal.companySubdivisionCode) {
      !dealPartnersStore.subdivisionsList.some(subdivision => subdivision.code === deal.companySubdivisionCode) &&
        dealPartnersStore.subdivisionsList.push({ code: deal.companySubdivisionCode, name: deal.companySubdivisionName });
      const initialSubdivision = dealPartnersStore.subdivisionsList.filter(subdivision => subdivision.code === deal.companySubdivisionCode);

      setSubdivisionValue(initialSubdivision && initialSubdivision[0]);
    } else {
      setSubdivisionValue(null);
    }
  }, [dealPartnersStore.subdivisionsList, deal.companySubdivisionCode]);

  const handleConsigneesChange = (newConsignee: ParticipantPartner): void => {
    if (newConsignee.code === consigneeValue?.code) {
      return;
    }
    let newAddress = null;
    dealPartnersStore.reloadAddressesList(newConsignee.customerCode).then(() => {
      if (dealPartnersStore.addressesList?.length === 1) {
        setAddressValue(dealPartnersStore.addressesList[0]);
        newAddress = dealPartnersStore.addressesList[0];
      } else {
        setAddressValue(null);
      }
      setConsigneeValue(newConsignee);
    });
    dealStore.apiStore
      .apiClientDeal()
      .dealsItemUpdatePartner(dealStore.dealCode, { participantCode: newConsignee.code, type: 'consignee' })
      .then(() => updateDealPartners(newConsignee, payerValue, newAddress));
  };
  const handleAddressChange = (newAddress: Address): void => {
    if (newAddress.code === addressValue?.code) {
      return;
    }
    setAddressValue(newAddress);
    dealStore.apiStore
      .apiClientDeal()
      .dealsItemUpdatePartnerAddress(dealStore.dealCode, {
        participantCode: consigneeValue.code,
        type: 'consignee',
        addressCode: newAddress.code,
      })
      .then(() => updateDealPartners(consigneeValue, payerValue, newAddress));
  };
  const handlePayerChange = (newPayer: ParticipantPartner): void => {
    if (newPayer.code === payerValue?.code) {
      return;
    }
    dealStore.apiStore
      .apiClientDeal()
      .dealsItemUpdatePartner(dealStore.dealCode, { participantCode: newPayer.code, type: 'payer' })
      .then(() => updateDealPartners(consigneeValue, newPayer, addressValue));

    setPayerValue(newPayer);
  };

  const handleSubdivisionsChange = (subdivision: Subdivision): void => {
    if (subdivision.code === subdivisionValue.code) {
      return;
    }
    setSubdivisionValue(subdivision);
    dealStore.updateDeal({ companySubdivisionCode: subdivision.code }).catch(e => console.warn('Не удалось изменить подразделение', e));
  };

  const updateDealPartners = (consignee: ParticipantPartner, payer: ParticipantPartner, addressV: Address): void => {
    dealStore.updatePartners({
      consignee: consignee
        ? {
            ...consignee,
            address: addressV ? addressV : null,
          }
        : null,
      payer: payer ? { ...payer } : null,
    });
  };

  const onOpenDialog = (isOpen: boolean): void => {
    setIsDialogOpen(isOpen);
  };

  const mapBtnTitle =
    deal?.warehouseAddress?.latitude && addressValue?.latitude ? 'Показать маршрут' : addressValue?.latitude ? 'Показать на карте' : '';
  const mapBtnUrl =
    deal?.warehouseAddress?.latitude && addressValue?.latitude
      ? 'https://yandex.ru/maps/?rtext=' +
        deal?.warehouseAddress?.latitude +
        ',' +
        deal?.warehouseAddress?.longitude +
        '~' +
        addressValue?.latitude +
        ',' +
        addressValue?.longitude +
        '&rtt=auto'
      : addressValue?.latitude
      ? addressValue?.latitude + ',' + addressValue?.longitude + '&rtt=auto'
      : '';
  return (
    <TabSelectWrapper onOpen={onOpenDialog}>
      <Grid container spacing={0} minHeight={minHeightForTabSections} sx={{ height: '100%' }}>
        <Grid container flexDirection={'column'} sx={{ maxHeight: '100%', overflow: 'hidden' }}>
          <DealSummaryTabHeader Icon={HubRoundedIcon} isDialogOpen={isDialogOpen} dealItemStore={dealStore} />
          <Grid item mb={2} mt={1}>
            <Grid container alignItems={'center'}>
              <Grid item flex={'1 1 auto'}>
                <Autocomplete
                  value={consigneeValue || null}
                  onChange={(_, v) => handleConsigneesChange(v)}
                  disablePortal
                  getOptionLabel={i => `${i.title} ИНН/КПП ${i?.companyInn || '-'}/${i?.companyKpp || '-'}`}
                  isOptionEqualToValue={(option, value) => option.code === value.code}
                  disableClearable
                  title={`${consigneeValue?.title} ИНН/КПП ${consigneeValue?.companyInn || '-'}/${consigneeValue?.companyKpp || '-'}` || ''}
                  size="small"
                  options={dealPartnersStore?.consigneesList || []}
                  renderInput={params => <TextField {...params} label="Грузополучатель" />}
                  disabled={dealStore.isViewOnly() || dealStore.isSaving}
                />
              </Grid>
              <Grid item>
                <Box ml={'4px'} mt={'-1px'}>
                  <MxLink href={`/app/clients/${encodeURIComponent(consigneeValue?.customerCode)}`}>
                    <IconButton size="small" title="Перейти к грузополучателю" disabled={!consigneeValue?.code || dealStore.isSaving}>
                      <SearchRoundedIcon />
                    </IconButton>
                  </MxLink>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item mb={2}>
            <Grid container alignItems={'center'}>
              <Grid item flex={'1 1 auto'}>
                <Autocomplete
                  getOptionKey={option => option.code}
                  value={addressValue || null}
                  onChange={(_, v) => handleAddressChange(v)}
                  disablePortal
                  getOptionLabel={i => i.addressFull}
                  isOptionEqualToValue={(option, value) => option.code === value.code}
                  disableClearable
                  size="small"
                  options={dealPartnersStore?.addressesList || []}
                  renderInput={params => <TextField {...params} label="Адрес" />}
                  disabled={dealStore.isViewOnly() || dealStore.isSaving}
                  title={addressValue?.addressFull || ''}
                />
              </Grid>
              <Grid item>
                <Box ml={'4px'} mt={'-1px'}>
                  {addressValue ? (
                    <MxLink href={mapBtnUrl} target="_blank">
                      <IconButton size="small" title={mapBtnTitle} disabled={!payerValue?.code || dealStore.isSaving}>
                        <NearMeTwoToneIcon />
                      </IconButton>
                    </MxLink>
                  ) : (
                    <IconButton size="small" title={'Выберете адрес для отображения маршрута'} disabled>
                      <NearMeTwoToneIcon />
                    </IconButton>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item mb={2}>
            <Grid container alignItems={'center'}>
              <Grid item flex={'1 1 auto'}>
                <Autocomplete
                  value={payerValue || null}
                  onChange={(_, v) => handlePayerChange(v)}
                  disablePortal
                  getOptionLabel={i => `${i.title} ИНН/КПП ${i?.companyInn || '-'}/${i?.companyKpp || '-'}`}
                  isOptionEqualToValue={(option, value) => option.code === value.code}
                  disableClearable
                  size="small"
                  title={`${payerValue?.title} ИНН/КПП ${payerValue?.companyInn || '-'}/${payerValue?.companyKpp || '-'}` || ''}
                  options={dealPartnersStore?.payersList || []}
                  renderInput={params => <TextField {...params} label="Плательщик" />}
                  disabled={dealStore.isViewOnly() || dealStore.isSaving}
                />
              </Grid>
              <Grid item>
                <Box ml={'4px'} mt={'-1px'}>
                  <MxLink href={`/app/clients/${encodeURIComponent(payerValue?.customerCode)}`}>
                    <IconButton size="small" title="Перейти к плательщику" disabled={!payerValue?.code || dealStore.isSaving}>
                      <SearchRoundedIcon />
                    </IconButton>
                  </MxLink>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item mb={2}>
            <Grid container alignItems={'center'}>
              <Grid item flex={'1 1 auto'}>
                <Autocomplete
                  value={subdivisionValue || null}
                  onChange={(_, subdivision) => handleSubdivisionsChange(subdivision)}
                  disablePortal
                  getOptionLabel={i => i.name}
                  isOptionEqualToValue={(option, value) => option.code === value.code}
                  disableClearable
                  size="small"
                  options={dealPartnersStore.subdivisionsList || []}
                  title={subdivisionValue?.name || ''}
                  renderInput={params => <TextField {...params} label="Подразделение" />}
                  disabled={dealStore.isViewOnly() || dealStore.isSaving}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </TabSelectWrapper>
  );
});
