import {
  Address,
  BillPosition,
  ContractCostAgreement,
  Deal,
  DealApproval,
  DealDistributor,
  DealLifting,
  DealPayment,
  DealPosition,
  DealPositionLifting,
  DealPositionPrevious,
  DealPositionUnit,
  DealPositionUnitCostTypeEnum,
  DealTransport,
  DealUpdateLog,
  DealUpdateLogChangedPosition,
  DealUpdates,
  DealUpdatesPosition,
  DealUpdatesPositionUnitCostTypeEnum,
  Freeze,
  FreezeUpdatesPosition,
} from 'src/api/marketx';
import {
  AppAddress,
  AppDeal,
  AppDealApproval,
  AppDealDistributor,
  AppDealLifting,
  AppDealPayment,
  AppDealPosition,
  AppDealPositionByCode,
  AppDealPositionByProductCode,
  AppDealPositionLifting,
  AppDealPositionUnit,
  AppDealTransport,
  AppDealUpdates,
} from '.';
import hash from 'object-hash';
import { ProductsNormalType } from '../AppCatalog';
import { mapProduct } from '../AppCatalog/lib';
import { mapClientContract } from '../../views/clients/lib';
import { formatNumber2, formatNumber3, formatNumberByUnit, formatPriceCur, toFloat } from '@mx-ui/helpers';
import { FindQuantityFromList, findQuantityFromList } from '../../views/deals/DealPosition/DealPositionPrices';
import { AllowedColors } from '../../components/ui/MulticoloredTooltip';
export const findQuantityFromListForBill = (positionList: BillPosition): FindQuantityFromList => {
  const saleQuantity = toFloat(positionList?.quantity);

  const baseQuantity = toFloat(positionList?.baseQuantity);
  const currentStock = positionList?.product?.stocks.filter(s => s.warehouseCode === positionList.warehouse?.code)[0];
  let inStock = toFloat(currentStock?.inStock || 0);
  let inStockForSale = toFloat(currentStock?.inStockForSale);
  const unitShort = positionList?.unitShort || positionList?.baseUnitShort || 'т';
  if (saleQuantity && baseQuantity > 0 && saleQuantity !== baseQuantity) {
    inStock *= saleQuantity / baseQuantity;
    inStockForSale *= saleQuantity / baseQuantity;
  }
  let colorForStock: AllowedColors = 'green';
  if (saleQuantity > inStock) {
    colorForStock = 'red';
  } else if (saleQuantity > inStockForSale) {
    colorForStock = 'yellow';
  }
  const titleForStock = `есть ${formatNumberByUnit(inStockForSale, positionList.baseUnitCode, unitShort)} из ${formatNumberByUnit(
    inStock,
    positionList.baseUnitCode,
    unitShort
  )}`;
  return {
    saleQuantity,
    baseQuantity,
    colorForStock,
    titleForStock,
    inStock,
    inStockForSale,
  };
};
interface MapReserveWidgetReturn {
  titleForStock: string;
  colorForStock: AllowedColors;
  baseColor: string;
  baseUnit: string;
  infoText: string;
  baseQuantity: number;
  isBalanceShown: boolean;
  isShipmentBlockVisible: boolean;
  hasExpectationReserve: boolean;
  reserveProgress: number;
  softReserve: number;
  hardReserve: number;
  reserveTotal: number;
  inWaybillReserve: number;
  shipmentQuantity: number;
  billBaseQuantity: number;
  currentReserve: number;
  dealBaseQuantity: number;
  inAwaitingQuantity: number;
  inStock?: number;
  inStockForSale?: number;
}
// eslint-disable-next-line complexity
export const mapReserveWidgetForBillOrDeal = (
  position: AppDealPosition | BillPosition,
  documentState: string,
  isDeal = false
): MapReserveWidgetReturn => {
  // '#FFF200' желтый
  // '#ff7f00' оранжевый
  // '#B9B9BA' серый
  // '#317E03' зеленый
  // '#ED1C24' красный
  let baseColor;
  let baseQuantity;
  let baseUnit;
  let infoText;
  let isBalanceShown;
  let hasExpectationReserve;
  let reserveProgress;
  let quantityFromList;
  let reserveTotal;
  let isShipmentBlockVisible = true;
  const reservation = position.reservationSummary || {};
  const softReserve = reservation.inSoftReserveQuantity || 0;
  const hardReserve = (reservation.inHardReserveQuantity || 0) + (reservation.inWaybillQuantity || 0);
  const inWaybillReserve = reservation.inWaybillQuantity || 0;
  const shipmentQuantity = reservation.shippedQuantity || 0;
  const billBaseQuantity = reservation.billBaseQuantity || 0;
  const currentReserve = softReserve || hardReserve;
  const dealBaseQuantity = (reservation.dealBaseQuantity || 0) - shipmentQuantity;
  const inAwaitingQuantity = reservation.inAwaitingQuantityWithAutoReserveEnabled || 0;

  // Мапинг для сделки
  if (isDeal) {
    reserveTotal = ((reservation.dealBaseQuantity || 0) - (reservation.shippedQuantity || 0)).toFixed(3);
    baseQuantity = reservation.dealBaseQuantity || 0;
    // @ts-ignore
    baseUnit = position.baseUnit;
    baseColor = billBaseQuantity ? '#FFF200' : '#B9B9BA';
    if (hardReserve + inWaybillReserve > 0 || shipmentQuantity > 0) {
      baseColor = '#317E03';
    }
    // planned_quantity (reservation.billBaseQuantity) > reserved_quantity (reservation.inSoftReserveQuantity) > 0
    infoText = currentReserve ? 'По данной заявке есть резерв' : 'По данной заявке нет резерва';
    hasExpectationReserve = !currentReserve || currentReserve < dealBaseQuantity;
    isBalanceShown = currentReserve < baseQuantity || (!currentReserve && (shipmentQuantity || 0) < baseQuantity);
    reserveProgress =
      Math.round((currentReserve / dealBaseQuantity) * 100) > 100
        ? 100
        : Math.round((currentReserve / dealBaseQuantity) * 100) < 0
        ? 0
        : Math.round((currentReserve / dealBaseQuantity) * 100);
    quantityFromList = findQuantityFromList(position as AppDealPosition, billBaseQuantity);
    isShipmentBlockVisible = documentState === 'approved';
  } else {
    // Мапинг для счета
    reserveTotal = ((reservation.billBaseQuantity || 0) - (reservation.shippedQuantity || 0)).toFixed(3);
    baseQuantity = reservation.billBaseQuantity;
    // @ts-ignore
    baseUnit = position.baseUnitShort;
    baseColor = softReserve ? '#FFF200' : hardReserve ? '#317E03' : shipmentQuantity ? '#317E03' : '#ED1C24';
    hasExpectationReserve = inAwaitingQuantity;
    if (!softReserve && !hardReserve && !shipmentQuantity) {
      if (hasExpectationReserve) {
        infoText = 'Ожидание резерва';
      } else if (documentState === 'reserve_withdrawn') {
        infoText = 'Резерв снят';
      } else {
        infoText = 'Резерв не сформирован, на складе нет достаточного количества на свободном остатке';
      }
    } else if (softReserve) {
      infoText = 'Резерв сформирован, ожидание подтверждения складской системы';
    } else if (hardReserve && !shipmentQuantity) {
      infoText = 'Резерв подтвержден складской системой';
    } else if (hardReserve && shipmentQuantity < baseQuantity) {
      infoText = 'Резерв подтвержден, произведена частичная отгрузка';
    }
    isBalanceShown = !currentReserve && (shipmentQuantity || 0) < baseQuantity;
    reserveProgress =
      Math.round((currentReserve / reserveTotal) * 100) > 100
        ? 100
        : Math.round((currentReserve / reserveTotal) * 100) < 0
        ? 0
        : Math.round((currentReserve / reserveTotal) * 100);
    quantityFromList = findQuantityFromListForBill(position as BillPosition);

    if (!+currentReserve && !+reserveTotal) {
      baseColor = '#B9B9BA';
    } else if (currentReserve && reserveTotal && currentReserve < reserveTotal) {
      baseColor = '#ff7f00';
    }
  }

  // общая текстовка частичного мягкого резерва
  if (billBaseQuantity > softReserve && softReserve > 0) {
    infoText = 'Резерв сформирован частично, на складе нет достаточного количества на свободном остатке';
  }
  const { titleForStock, colorForStock, inStock, inStockForSale } = quantityFromList;
  return {
    inStock,
    inStockForSale,
    titleForStock,
    colorForStock,
    baseColor,
    baseQuantity,
    baseUnit,
    infoText,
    isBalanceShown,
    hasExpectationReserve,
    reserveProgress,
    softReserve,
    hardReserve,
    reserveTotal,
    inWaybillReserve,
    shipmentQuantity,
    billBaseQuantity,
    currentReserve,
    dealBaseQuantity,
    inAwaitingQuantity,
    isShipmentBlockVisible,
  };
};

export const mapPositionCodes = (positions: DealPosition[] | AppDealPosition[]): string[] => {
  if (!positions) return [];

  return positions.map(p => p.code);
};

export const buildHash = (obj: any | unknown): string => {
  return hash(obj);
};

export const mapNewPosition = (
  positionCode: string,
  deal: AppDeal,
  product: ProductsNormalType,
  quantity?: number,
  cost?: number,
  unitCode?: string,
  warehouseCode?: string
): AppDealPosition => {
  const result: AppDealPosition = {
    baseMinRetailUnitCost: undefined,
    minRetailUnitCost: undefined,
    minRetailUnitCostWithPowers: undefined,
    isArchive: false,
    isService: false,
    title: product.title,
    warehouseCode: warehouseCode ? warehouseCode : undefined,
    amount: quantity ? quantity.toString() : '',
    code: positionCode,
    unitCode: unitCode,
    cost: undefined,
    bareUnitCost: cost > 0 ? cost : undefined,
    lineNumber: 0,
    currency: 'RUB',
    totalCost: 0,
    postpayDays: deal?.postpayDays || 0,
    prepayPct: deal?.prepayPct || 0,
    productCode: product.code,
    servicesHash: hash([]),
    hash: '',
    changesLog: undefined,
  };

  if (result.prepayPct >= 100) {
    result.postpayDays = 0;
  }
  result.hash = buildHash(result);

  return result;
};

export const mapNewPositionForFreeze = (
  positionCode: string,
  freeze: Freeze,
  product: ProductsNormalType,
  quantity?: number
): FreezeUpdatesPosition => {
  return {
    isArchive: false,
    code: positionCode,
    productCode: product.code,
    quantity: quantity ? quantity : undefined,
  };
};

const mapPositionUnits = (units: DealPositionUnit[]): AppDealPositionUnit[] => {
  return units.map(u => ({
    code: u.code,
    title: u.title,
    isCurrent: u.isCurrent,
    weight: u.weight,
  }));
};

const mapPositionPrevious = (previous?: DealPositionPrevious): DealPositionPrevious => {
  return {
    dealCode: previous?.dealCode,
    dealCreatedAt: previous?.dealCreatedAt,
    fullUnitCost: previous?.fullUnitCost || 0,
    fullTotalCost: previous?.fullTotalCost || 0,
    managerFullCostMargin: previous?.managerFullCostMargin || 0,
    managerFullCostMarginPct: previous?.managerFullCostMarginPct || 0,
    unitCost: previous?.unitCost || 0,
    unit: previous?.unit,
    quantity: previous?.quantity,
  };
};

const mapPositionLifting = (lifting?: DealPositionLifting): AppDealPositionLifting => {
  return <AppDealPositionLifting>{
    avgPackWeight: lifting?.avgPackWeight,
    timeSeconds: lifting?.timeSeconds,
    totalCost: lifting?.totalCost,
    fullPackCount: lifting?.fullPackCount,
    partialCount: lifting?.partialCount,
    partialCost: lifting?.partialCost,
  };
};

export const mapPositionsByProductCode = (
  positions: DealPosition[],
  contractCostAgreements: Array<ContractCostAgreement>,
  warehouseCode: string
): AppDealPositionByProductCode => {
  const result = <AppDealPositionByProductCode>{};
  if (!positions) return result;

  positions.forEach(p => {
    let agreementCode: string;
    if (p.agreementPositionCode) {
      contractCostAgreements.forEach(agreement => {
        agreement.positions.forEach(pos => {
          if (pos.code === p.agreementPositionCode) {
            agreementCode = agreement.code;
          }
        });
      });
    }
    const key = agreementCode ? buildHash(p.productCode + p.warehouseCode + agreementCode) : buildHash(p.productCode + p.warehouseCode);
    result[key] = {
      agreementCode,
      amount: (p.amount || 0).toString(),
      isArchive: p.isArchive,
      productCode: p.productCode,
      code: p.code,
      unit: p.unit,
      unitCode: p.unitCode,
      warehouseCode: p.warehouseCode && warehouseCode,
    };
  });

  return result;
};

// eslint-disable-next-line complexity
const mapDealPosition = (p: DealPosition, change: DealUpdateLogChangedPosition): AppDealPosition => {
  const res = <AppDealPosition>{
    reservationSummary: p.reservationSummary,
    motivation: p.motivation,
    title: p.title,
    isArchive: p.isArchive,
    mrcUserGroups: p.mrcUserGroups || [],
    changesLog: change,
    warehouseCode: p.warehouseCode,
    warehouseName: p.warehouseName,
    isService: p.isService,
    agreementPositionCode: p.agreementPositionCode,
    amount: (p.amount || 0).toString(),
    unit: p.unit,
    code: p.code,
    cost: (p.bareUnitCost || '0').toString(),
    bareUnitCost: parseFloat((p?.bareUnitCost || 0).toString()) || 0,
    tenderUnitCost: parseFloat((p?.tenderUnitCost || 0).toString()) || 0,
    unitCostType: p.unitCostType || DealPositionUnitCostTypeEnum.Bare,
    sgMrcMarkupAmount: p?.segmentationGroupMrcMarkupAmount || 0,

    baseUnitCost: p.baseUnitCost,
    vatTotalCost: p.vatTotalCost,
    vatUnitCost: p.vatUnitCost,
    fullUnitCostWoVat: p.fullUnitCostWoVat,
    fullTotalCostWoVat: p.fullTotalCostWoVat,
    baseFullUnitCost: p.baseFullUnitCost,
    baseQuantity: p.baseQuantity,
    baseUnit: p.baseUnit,
    baseUnitCode: p.baseUnitCode,
    fullTotalCost: p.fullTotalCost,
    unitCode: p.unitCode,
    units: mapPositionUnits(p?.units || []),

    baseMinRetailUnitCost: (p.baseMinRetailUnitCost || 0).toString(),
    minRetailUnitCost: (p.minRetailUnitCost || 0).toString(),
    minRetailUnitCostWithPowers: (p.minRetailUnitCostWithPowers || 0).toString(),
    custodyAvgUnitCost: p.custodyAvgUnitCost,
    competitorAvgUnitCost: p.competitorAvgUnitCost,

    usePsUnitCost: p?.usePsUnitCost,
    psUnitCost: p?.psUnitCost || 0,

    vatRateCode: p?.vatRateCode,
    vatRateValue: p?.vatRateValue || 0,

    currency: p.currency,
    postpayDays: p.postpayDays || 0,
    prepayPct: p.prepayPct || 0,
    totalCost: p.totalCost || 0,
    productCode: p.productCode,
    lineNumber: p.lineNumber,
    recommendedUnitCost: p.recommendedUnitCost || 0,
    roundingUnitCost: p.roundingUnitCost || 0,
    roundingTotalCost: p.roundingTotalCost || 0,
    fullUnitCost: p.fullUnitCost || 0,
    calculatedBareUnitCost: p.calculatedBareUnitCost || 0,
    distributeDelivery: p.distributeDelivery || false,
    distributeDeliveryCost: p.distributeDeliveryCost || 0,
    distributeDeliverySum: p.distributeDeliverySum || 0,
    distributeCredit: p.distributeCredit || false,
    creditUnitCost: p.creditUnitCost || 0,
    creditTotalCost: p.creditTotalCost || 0,
    globalMarketRecommendedCost: p.globalMarketRecommendedCost || 0,
    managerFullCostMargin: p.managerFullCostMargin || 0,
    manufacturerMarkupLimitFactor: p.manufacturerMarkupLimitFactor || 0,
    manufacturerMarkupLimitUnitCost: p.manufacturerMarkupLimitUnitCost || 0,
    marginality: p.marginality || 0,
    marginalityPct: p.marginalityPct || 0,
    globalMarketMarginality: p.globalMarketMarginality || 0,
    globalMarketMarginalityPct: p.globalMarketMarginalityPct || 0,
    recommendedCostMarginality: p.recommendedCostMarginality || 0,
    recommendedCostMarginalityPct: p.recommendedCostMarginalityPct || 0,
    primeCostMargin: p.primeCostMargin || 0,
    primeCostMarginPct: p.primeCostMarginPct || 0,
    purchaseCostMargin: p.purchaseCostMargin || 0,
    purchaseCostMarginPct: p.purchaseCostMarginPct || 0,
    priceListCostMargin: p.priceListCostMargin || 0,
    priceListCostMarginPct: p.priceListCostMarginPct || 0,
    previous: mapPositionPrevious(p?.previous),
    lifting: mapPositionLifting(p?.lifting),
    weightedAvgUnitCost: p?.weightedAvgUnitCost,
    segment: p?.segment,
    stockCoefficient: p?.stockCoefficient,
    productParticipantName: p?.productParticipantName,

    services: (p.services || []).map(s => ({
      code: s.code,
      cost: (s.cost || '0').toString(),
      unit: s.unit || 'т',
      title: s.title,
      totalCost: s.totalCost || 0,
      enabled: s.enabled || false,
      active: s.active || false,
      baseUnitCost: s.baseUnitCost,
      baseUnit: s.baseUnit || 'т',
      currency: s.currency,
      canSetManualTotalCost: !!s?.canSetManualTotalCost,
    })),
    product: mapProduct(p.product),
    servicesHash: '',
    comments: p.comments,

    useManualServicesCost: p.useManualServicesCost,
    manualServicesCost: p.manualServicesCost,
    serviceTotalCost: p.serviceTotalCost,
    creditCost: p.creditTotalCost,

    weight: p.weight,

    hash: '',
  };

  res.servicesHash = buildHash(res.services || []);
  res.hash = buildHash(res);

  return res;
};
const handlerUserGroup = (val: string): string => {
  if (val === 'seller') {
    return 'МОП';
  } else if (val === 'seller_chief') {
    return 'РОП';
  } else if (val === 'division_chief') {
    return 'Директор дивизиона';
  } else if (val === 'office_chief') {
    return 'Директор филиала';
  } else if (val === 'company_chief') {
    return 'Директор компании';
  }
  return '';
};
export const totalMotivationDictionary = {
  p1Amount: { value: 'Премия П1', handler: formatPriceCur },
  p2Amount: { value: 'Премия П2', handler: formatPriceCur },
  selfPurchaseAmount: { value: 'Премия БСС', handler: formatPriceCur },
  centralPurchaseAmount: { value: 'Премия БСЦ', handler: formatPriceCur },
};
export const productMotivationDictionary = {
  block: {
    condition: v => !!v,
    conditionKey: true,
    items: {
      quantity: { value: 'Количество', handler: formatNumber3, unit: ' т' },
    },
  },
  block1: {
    condition: (v: number) => v != 0,
    conditionKey: 'minRetailUnitCost',
    items: {
      minRetailUnitCost: { value: 'МРЦ', handler: formatPriceCur },
      mmbUnitCost: { value: 'Базовая мотивационная цена', handler: formatPriceCur },
      saleUnitCost: { value: 'Цена товара', handler: formatPriceCur },
      baseMarginIncome: { value: 'Маржинальность от БМЦ', handler: formatPriceCur },
      mrcExceedAmount: { value: 'Превышение МРЦ', handler: formatPriceCur },
      compensationTodh: { value: 'Компенсация по неликвиду', handler: formatPriceCur },
      compensationIlliquid: { value: 'Компенсация по некондиции', handler: formatPriceCur },
    },
  },
  block2: {
    condition: v => !!v,
    conditionKey: true,
    items: {
      p1Rate: { value: 'Ставка П1', handler: formatPriceCur },
      p1Amount: { value: 'Премия П1', handler: formatPriceCur },
    },
  },
  block3: {
    condition: (v: number) => v > 0,
    conditionKey: 'mrcExceedAmount',
    items: {
      mrcExceedAmount1: { value: '1 уровень превышение МРЦ', handler: formatPriceCur },
      p2Pct: { value: 'Ставка П2 (1 уровень)', handler: formatNumber2, unit: ' %' },
      mrcExceedAmount2: { value: '2 уровень превышение МРЦ', handler: formatPriceCur },
      p2Pct2: { value: 'Ставка П2 (2 уровень)', handler: formatNumber2, unit: ' %' },
      mrcExceedAmount3: { value: '3 уровень превышение МРЦ', handler: formatPriceCur },
      p2Pct3: { value: 'Ставка П2 (3 уровень)', handler: formatNumber2, unit: ' %' },
      mrcExceedAmount4: { value: '4 уровень превышение МРЦ', handler: formatPriceCur },
      p2Pct4: { value: 'Ставка П2 (4 уровень)', handler: formatNumber2, unit: ' %' },
      compensationTodhPct: { value: 'Ставка по неликвиду', handler: formatNumber2, unit: ' %' },
      compensationIlliquidPct: { value: 'Ставка по некондиции', handler: formatNumber2, unit: ' %' },
      unitCostP2: { value: 'П2 на тонну', handler: formatPriceCur, unit: '/т' },
      p2Amount: { value: 'Премия П2', handler: formatPriceCur },
    },
  },
  block4: {
    condition: (v: number) => v <= 0 || !v,
    conditionKey: 'mrcExceedAmount',
    items: {
      p2Pct: { value: 'Ставка П2', handler: formatNumber2, unit: ' %' },
      compensationTodhPct: { value: 'Ставка по неликвиду', handler: formatNumber2, unit: ' %' },
      compensationIlliquidPct: { value: 'Ставка по некондиции', handler: formatNumber2, unit: ' %' },
      unitCostP2: { value: 'П2 на тонну', handler: formatPriceCur, unit: '/т' },
      p2Amount: { value: 'Премия П2', handler: formatPriceCur },
    },
  },
  block5: {
    condition: v => !!v,
    conditionKey: 'selfPurchase',
    items: {
      selfPurchasePct: { value: 'Ставка БСС', handler: formatNumber2, unit: ' %' },
      unitCostPurchase: { value: 'БСС на тонну', handler: formatPriceCur, unit: '/т' },
      selfPurchaseAmount: { value: 'Премия БСС', handler: formatPriceCur },
      purchaseUnitCost: { value: 'Цена закупки', handler: formatPriceCur },
      selfPurchaseMarginIncome: { value: 'Маржинальность от ЦЗ', handler: formatPriceCur },
    },
  },
  block6: {
    condition: v => !!v,
    conditionKey: 'centralPurchase',
    items: {
      cpMrcExceedAmount1: { value: '1 уровень превышение МРЦ', handler: formatPriceCur },
      absoluteCentralPurchasePct: { value: 'Ставка БСЦ (1 уровень)', handler: formatNumber2, unit: ' %' },
      cpMrcExceedAmount2: { value: '2 уровень превышение МРЦ', handler: formatPriceCur },
      cpPct2: { value: 'Ставка БСЦ (2 уровень)', handler: formatNumber2, unit: ' %' },
      cpMrcExceedAmount3: { value: '3 уровень превышение МРЦ', handler: formatPriceCur },
      cpPct3: { value: 'Ставка БСЦ (3 уровень)', handler: formatNumber2, unit: ' %' },
      cpMrcExceedAmount4: { value: '4 уровень превышение МРЦ', handler: formatPriceCur },
      cpPct4: { value: 'Ставка БСЦ (4 уровень)', handler: formatNumber2, unit: ' %' },
      unitCostPct: { value: 'БСЦ на тонну', handler: formatPriceCur, unit: '/т' },
      centralPurchaseAmount: { value: 'Премия БСЦ', handler: formatPriceCur },
    },
  },
  block7: {
    condition: v => !!v,
    conditionKey: true,
    items: {
      motivationTypeName: { value: 'Тип мотивации', handler: v => v },
      userGroupCode: { value: 'Уровень полномочий', handler: handlerUserGroup },
    },
  },
};

export const mapPositionsByCode = (positions: DealPosition[], updateLog: DealUpdateLog): AppDealPositionByCode => {
  const result = {};
  if (!positions) return result;

  positions.forEach(p => {
    const key = p.code;
    const posChangeLog = updateLog?.changed?.positions?.find(i => p.code === i.code);
    result[key] = mapDealPosition(p, posChangeLog);
  });

  return result;
};

export const mapDeal = (deal: Deal): AppDeal => {
  const positionsByCode = mapPositionsByCode(deal.positions, deal.updateLog);
  const result: AppDeal = {
    code: deal.code,
    billValidUntil: deal.billValidUntil,
    billDocumentDate: deal.billDocumentDate,
    billDocumentNumber: deal.billDocumentNumber,
    editingEnabled: deal.editingEnabled === undefined ? true : !!deal.editingEnabled,
    createdAt: deal.createdAt,
    contractCostAgreements: deal.contractCostAgreements,
    billHasSubordinateDoc: deal.billHasSubordinateDoc,
    billCreateFromDealEnabled: deal.billCreateFromDealEnabled,
    vatRateValue: deal.vatRateValue || 0,
    vatTotalPrice: deal.vatTotalPrice,
    totalPriceWoVat: deal.totalPriceWoVat,
    availableVatRates: deal.availableVatRates,
    vatRateCode: deal.vatRateCode,
    pdfGenerationEnabled: deal.pdfGenerationEnabled,
    branchOfficeCode: deal.branchOfficeCode,
    branchOfficeName: deal.branchOfficeName,
    psTotalCost: deal.psTotalCost,
    deliveryTypeName: deal.deliveryTypeName,
    currency: deal.currency,
    documents: deal.documents,
    updateLog: deal.updateLog,
    comments: deal.comments || [],
    customer: deal.customer,
    customerCode: deal.customer?.code,
    consigneeCode: deal.consigneeCode,
    consigneeTitle: deal.consigneeTitle,
    submitNotices: deal.submitNotices || [],
    deliveryAddress: mapAddress({}, deal.deliveryAddress),
    editingDisableReasons: deal.editingDisableReasons,
    // warehouseCode: deal.warehouseCode || '9fde2078-8416-11e8-a994-0cc47a43f0c8', // ????
    warehouseCode: deal.warehouseCode,
    warehouseName: deal.warehouseName,
    warehouseAddress: mapAddress({}, deal.warehouseAddress),
    payment: mapDealPayment({}, deal.payment),
    partners: deal.partners,
    companySubdivisionName: deal.companySubdivisionName,
    companySubdivisionCode: deal.companySubdivisionCode,
    deliveryType: deal.deliveryType,
    useManualDeliveryDistance: deal.useManualDeliveryDistance,
    manualDeliveryDistanceKm: deal.manualDeliveryDistanceKm,
    useManualDeliveryCost: deal.useManualDeliveryCost,
    manualDeliveryCost: deal.manualDeliveryCost,
    useManualDeliveryUnits: deal.useManualDeliveryUnits,
    manualDeliveryUnitsCount: deal.manualDeliveryUnitsCount,

    sellerBankAccountNumber: deal.sellerBankAccountNumber,
    sellerBankTitle: deal.sellerBankTitle,
    sellerPaymentDetailCode: deal.sellerPaymentDetailCode,
    sellerPaymentDetails: deal.sellerPaymentDetails,

    deliveryMarkup: deal.deliveryMarkup,

    lastSubmittedAt: deal.lastSubmittedAt,
    managerProfitMarga: deal.managerProfitMarga || 0,
    managerProfitVolume: deal.managerProfitVolume || 0,
    marginality: deal.marginality || 0,
    marginalityPct: deal.marginalityPct || 0,
    recommendedCostMarginality: deal.recommendedCostMarginality || 0,
    recommendedCostMarginalityPct: deal.recommendedCostMarginalityPct || 0,
    globalMarketMarginality: deal.globalMarketMarginality || 0,
    globalMarketMarginalityPct: deal.globalMarketMarginalityPct || 0,
    primeCostMargin: deal.primeCostMargin || 0,
    primeCostMarginPct: deal.primeCostMarginPct || 0,
    purchaseCostMargin: deal.purchaseCostMargin || 0,
    purchaseCostMarginPct: deal.purchaseCostMarginPct || 0,
    priceListCostMargin: deal.priceListCostMargin || 0,
    priceListCostMarginPct: deal.priceListCostMarginPct || 0,

    postpayCost: deal.postpayCost || 0,
    postpayDays: deal.payment.postpayDays || 0,
    creditCost: deal.creditCost || 0,
    prepayPct: deal.payment.prepayPct || 0,
    useManualCreditCost: deal.useManualCreditCost,
    manualCreditCost: deal.manualCreditCost,
    motivation: deal.motivation || null,
    motivationEmployee: deal.motivationEmployee,

    productsPrice: deal.productsPrice || 0,
    servicesPrice: deal.servicesPrice || 0,
    approval: mapDealApproval({}, deal.approval),
    creator: mapDealDistributor({}, deal.creator),
    distributor: mapDealDistributor({}, deal.distributor),
    lifting: mapDealLifting({}, deal.lifting),
    transport: mapDealTransport({}, deal.transport),
    contract: mapClientContract(deal.contract),
    state: deal.state,
    stateTitle: deal.stateTitle,
    submittedAt: deal.submittedAt,
    totalPrice: deal.totalPrice || 0,
    totalVolume: deal.totalVolume || 0,
    totalWeight: deal.totalWeight || 0,
    shipmentsTotalWeight: deal.shipmentsTotalWeight || 0,
    validUntil: deal.validUntil,
    updatedAt: deal.updatedAt,
    positions: [],
    positionCodes: mapPositionCodes(deal.positions),
    byCode: positionsByCode,
    byProductCode: mapPositionsByProductCode(deal.positions, deal.contractCostAgreements, deal.warehouseCode),
    useManualServicesCost: deal.useManualServicesCost,
    manualServicesCost: deal.manualServicesCost,
    bareCostInputAttributes: {},
    mxBillStrategy: deal.mxBillStrategy,
    defaultBillValidDays: deal.defaultBillValidDays,
    manualBillValidDays: deal.manualBillValidDays,
    useManualBillValidDays: deal.useManualBillValidDays,
  };
  result.bareCostInputAttributes = isAllPositionsBareCostInputMapper(deal.positions);
  result.positionCodes.forEach((c: string) => {
    result.positions.push(positionsByCode[c]);
  });

  return result;
};

export const isAllPositionsBareCostInputMapper = (
  positions: DealPosition[]
): { isSwitcherDisabled?: boolean; isSwitcherActive?: boolean; changedPositions?: AppDealPosition[] } => {
  let isSwitcherDisabled = true;
  let isSwitcherActive = true;
  const changedPositions = [];
  positions
    .filter(i => !i.isArchive)
    .forEach(pos => {
      if (!pos.agreementPositionCode && isSwitcherDisabled) {
        isSwitcherDisabled = false;
      }
      if (!pos.agreementPositionCode) {
        changedPositions.push(pos);
        if (pos?.unitCostType !== DealPositionUnitCostTypeEnum.Tender && isSwitcherActive) {
          isSwitcherActive = false;
        }
      }
    });
  return { isSwitcherDisabled, isSwitcherActive, changedPositions };
};

export const inputValueToPositiveFloat = (v: any): number => {
  if (v === '' || v === '0') {
    return 0;
  }
  let amount = parseFloat((v || '').toString().trim().split(' ').join('').split(',').join('.'));
  if (isNaN(amount) || amount === 0) {
    amount = undefined;
  }
  if (amount < 0) {
    return 0;
  }
  return amount;
};

export const mapDealUpdatesPositions = (deal: AppDealUpdates): DealUpdatesPosition[] => {
  const result = [];
  (deal?.positionCodes || []).forEach(code => {
    const p = deal.byCode[code];

    const amount = inputValueToPositiveFloat(p.amount);

    const position: DealUpdatesPosition = {
      amount: amount,
      warehouseCode: p.warehouseCode,
      code: p.code,
      productLeadTitle: p.productLeadTitle,
      agreementPositionCode: p.agreementPositionCode,
      bareUnitCost: inputValueToPositiveFloat(p.bareUnitCost),
      tenderUnitCost: inputValueToPositiveFloat(p.tenderUnitCost),
      unitCostType: p.unitCostType ? (p.unitCostType as string as DealUpdatesPositionUnitCostTypeEnum) : undefined,
      unitCode: p.unitCode,
      isArchive: p.isArchive,
      postpayDays: p.postpayDays,
      distributeDelivery: p.distributeDelivery,
      distributeCredit: p.distributeCredit,
      prepayPct: p.prepayPct,
      productCode: p.productCode,
      services: !p.services
        ? undefined
        : (p.services || []).map(s => {
            const result = { code: s.code };
            if (typeof s.enabled === 'boolean') {
              result['enabled'] = !!s.enabled;
            }
            if (typeof s.manualTotalCost === 'number') {
              result['manualTotalCost'] = s.manualTotalCost;
            }
            return result;
          }),

      useManualServicesCost: p.useManualServicesCost,
      manualServicesCost: p.manualServicesCost,
      usePsUnitCost: p.usePsUnitCost,
      psUnitCost: p.psUnitCost,
      comments: deal.byCode[deal.positionCodes[0]].comments,
    };

    if (position.prepayPct >= 100) {
      position.postpayDays = 0;
    }
    result.push(position);
  });

  if (!result.length) return undefined;
  return result;
};

// Маппинга объекта обновления сделки.
// Из внутреннего объекта фронта в объект API.
export const mapDealUpdates = (deal: AppDealUpdates): DealUpdates => {
  if (!deal?.positionCodes?.length && deal.byCode) {
    deal.positionCodes = Object.keys(deal.byCode);
  }

  let postpayDays = deal.postpayDays;

  if (deal.prepayPct || deal.prepayPct === 0) {
    postpayDays = deal.prepayPct >= 100 ? 0 : deal.postpayDays;
  }

  return {
    addressCode: deal.addressCode,
    agreementReset: deal.agreementReset,
    customerCode: deal.customerCode,
    consigneeCode: deal.consigneeCode,
    contractCode: deal.contractCode,
    vatRateCode: deal.vatRateCode,
    leadPositions: deal.leadPositions,
    comments: deal.comments,
    distributorCode: deal.distributorCode,
    manualBillValidDays: deal.manualBillValidDays,
    useManualBillValidDays: deal.useManualBillValidDays,
    companySubdivisionCode: deal.companySubdivisionCode,
    sellerPaymentDetailCode: deal.sellerPaymentDetailCode,

    warehouseCode: deal.warehouseCode,
    deliveryType: deal.deliveryType,
    useManualDeliveryDistance: deal.useManualDeliveryDistance,
    manualDeliveryDistanceKm: deal.manualDeliveryDistanceKm,
    useManualDeliveryCost: deal.useManualDeliveryCost,
    manualDeliveryCost: deal.manualDeliveryCost,
    useManualDeliveryUnits: deal.useManualDeliveryUnits,
    manualDeliveryUnitsCount: deal.manualDeliveryUnitsCount,

    positions: mapDealUpdatesPositions(deal),
    useManualServicesCost: deal.useManualServicesCost,
    manualServicesCost: deal.manualServicesCost,

    postpayDays: postpayDays,
    prepayPct: deal.prepayPct,
    useManualCreditCost: deal.useManualCreditCost,
    manualCreditCost: deal.manualCreditCost,
  };
};

export const mapAddress = (current?: AppAddress, actual?: Address): AppAddress => {
  const result = current || <AppAddress>{};
  result.code = actual?.code;
  result.addressFull = actual?.addressFull;
  result.cityCode = actual?.cityCode;
  result.cityName = actual?.cityName;
  result.flatNumber = actual?.flatNumber;
  result.street = actual?.street;
  result.houseNumber = actual?.houseNumber;
  result.latitude = actual?.latitude;
  result.longitude = actual?.longitude;
  return result;
};

export const mapDealApproval = (current?: AppDealApproval, actual?: DealApproval): AppDealApproval => {
  const result = current || <AppDealApproval>{};
  result.askCode = actual?.askCode;
  result.askDate = actual?.askDate;
  result.controlVisible = actual?.controlVisible;
  result.state = actual?.state;
  result.stateTitle = actual?.stateTitle;
  result.resolutionDate = actual?.resolutionDate;
  result.currentAccessGroup = actual?.currentAccessGroup;
  result.resolutionAccess = actual?.resolutionAccess;
  result.selfPossible = actual?.selfPossible;
  result.selfBlockReasons = actual?.selfBlockReasons || [];
  result.currentBlockReasons = actual?.currentBlockReasons || [];
  result.resolutionGroupReasons = actual?.resolutionGroupReasons || [];
  result.resolutionMinimumGroup = actual?.resolutionMinimumGroup;
  result.resolutionPossibleGroups = actual?.resolutionPossibleGroups;
  result.resolutionEmployeeCode = actual?.resolutionEmployeeCode;
  result.resolutionEmployeeSurname = actual?.resolutionEmployeeSurname;
  result.resolutionEmployeeName = actual?.resolutionEmployeeName;
  result.resolutionEmployeePatronymic = actual?.resolutionEmployeePatronymic;
  result.resolutionComment = actual?.resolutionComment;
  return result;
};

export const mapDealDistributor = (current?: AppDealDistributor, actual?: DealDistributor): AppDealDistributor => {
  current = current || <AppDealDistributor>{};
  current.employeeCode = actual.employeeCode;
  current.surname = actual.surname;
  current.name = actual.name;
  current.patronymic = actual.patronymic;
  return current;
};

export const mapDealTransport = (current?: AppDealTransport, actual?: DealTransport): AppDealTransport => {
  const result = current || <AppDealTransport>{};
  result.distance = actual?.distance;
  result.efficiency = actual?.efficiency;
  result.expressDeliveryCost = actual?.expressDeliveryCost;
  result.totalCost = actual?.totalCost;
  result.unitsCode = actual?.unitsCode;
  result.items = actual?.items;
  return result;
};

export const mapDealLifting = (current?: AppDealLifting, actual?: DealLifting): AppDealLifting => {
  const result = current || <AppDealLifting>{};
  result.totalCost = actual?.totalCost;
  result.timeSeconds = actual?.timeSeconds;
  return result;
};
export const mapDealPayment = (current?: AppDealPayment, actual?: DealPayment): AppDealPayment => {
  const result = current || <AppDealPayment>{};
  result.officeMaxPostpayDays = actual?.officeMaxPostpayDays ?? 30;
  result.min = 0;
  result.max = 100;
  result.step = 100;
  switch (actual?.officePrepayPercents) {
    case '[0,50,100]': {
      result.step = 50;
      break;
    }
    case '[100]': {
      result.min = 100;
      result.step = 200;
      result.officeMaxPostpayDays = 0;
      break;
    }
  }
  result.officePrepayPercents = actual?.officePrepayPercents;
  result.postpayDays = actual?.postpayDays;
  result.prepayPct = actual?.prepayPct;
  return result;
};
