import React, { FC, useCallback, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import CustomCard from 'src/views/deals/components/CustomCard';
import { CustomPopper } from '../components/CustomPopper';
import { displayNumber, getMarginalityColor } from '../lib';
import { formatNumber2, formatNumber3, formatNumberByUnit, formatPrice, formatPriceCur } from '@mx-ui/helpers';
import { AppDealPosition } from '../../../slices/AppDeal';
import { DealItemStore } from '../../../store/DealItemStore';
import { observer } from 'mobx-react-lite';
import { useAuthStore } from '../../../store/MobxStoreProvider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { PositionMotivationList } from '../../../components/Deals/Motivation/PositionMotivationList';
import { ShippedProgress } from '../../../components/Bills/BillListItem';

export const LoadingSkeleton = (): JSX.Element => {
  return (
    <CustomCard
      noBackground
      md={3}
      xs={12}
      style={{
        position: 'relative',
        paddingTop: '0px',
        marginTop: '0px',

        paddingLeft: '0px',
        borderRadius: '0px',
      }}
    >
      <Grid container spacing={0}>
        <Grid
          item
          xs={10}
          sx={{
            height: 32,
            padding: '4px',
            paddingLeft: '0px',
            paddingRight: '14px',
          }}
        />

        <Grid
          item
          xs={2}
          sx={{
            height: 32,
            padding: '4px',
            paddingLeft: '14px',
            paddingRight: '6spx',
            textAlign: 'right',
          }}
        />

        <Grid
          item
          xs={12}
          sx={{
            paddingTop: '4px',
            paddingLeft: '8px',
            height: 'calc(100% - 32px)',
            // border: `solid 2px #eee`,
            position: 'absolute',
            borderRadius: '4px',
            top: 32,
            left: 0,

            width: 'calc(100%)',
          }}
        >
          <Grid container spacing={0} sx={{ marginTop: '38px' }}>
            <Grid item xs={6}>
              <Skeleton variant="text" width={'95%'} />
              <Skeleton variant="rectangular" width={'95%'} height={60} />
            </Grid>

            <Grid item xs={6} sx={{ textAlign: 'right' }}>
              <Skeleton variant="text" width={'95%'} />
              <Skeleton variant="rectangular" width={'95%'} height={60} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Box
        sx={{
          width: 'calc(100% - 24px)',
        }}
      >
        <Grid container spacing={0}>
          <Grid item xs={6} />

          <Grid item xs={6} sx={{ textAlign: 'right' }} />
        </Grid>
      </Box>
    </CustomCard>
  );
};

interface DealPositionTotalProps {
  dealStore: DealItemStore;
  position: AppDealPosition;
  borderColor?: string;
}

export const DealPositionTotal: FC<DealPositionTotalProps> = observer(({ dealStore, position, borderColor }): JSX.Element => {
  // Виджет маржинальности
  const [marginalityPopperAnchor, setMarginalityPopperAnchor] = useState(null);
  const [vatRateAnchor, setVatRatePopperAnchor] = useState(null);
  const [marginalityPopperOpen, setMarginalityPopperOpen] = useState(false);
  const [vatRatePopperOpen, setVatRatePopperOpen] = useState(false);
  const unit = position?.unit || 'т';
  const currency = position?.currency || '₽';
  const totalShipped = position?.reservationSummary?.shippedQuantity || 0;
  const handleMarginalityClick = useCallback(
    (e): void => {
      setMarginalityPopperAnchor(e.currentTarget);
      setMarginalityPopperOpen(!marginalityPopperOpen);
    },
    [setMarginalityPopperAnchor, setMarginalityPopperOpen]
  );
  const handleVatRateClick = useCallback(
    (e): void => {
      setVatRatePopperAnchor(e.currentTarget);
      setVatRatePopperOpen(!marginalityPopperOpen);
    },
    [setVatRatePopperAnchor, setVatRatePopperOpen]
  );

  const handleMarginalityClose = useCallback((): void => {
    setMarginalityPopperOpen(false);
  }, []);
  const handleVatRateClose = useCallback((): void => {
    setVatRatePopperOpen(false);
  }, []);

  const { profile } = useAuthStore();

  if (!dealStore.isLoaded) {
    return <LoadingSkeleton />;
  }

  return (
    <Grid item xs={12} minHeight={248} p={1.5}>
      <Grid container flexDirection={'column'} minHeight={248 - 12 - 12} justifyContent={'space-between'} height={'100%'}>
        <Grid container item>
          <Grid container justifyContent={'space-between'} spacing={1}>
            <Grid item xs={12} xl={6}>
              <Grid
                container
                p={1}
                sx={{ border: `1px solid ${borderColor || '#B9B9BA'}`, borderRadius: '6px', height: '100%' }}
                flexWrap="nowrap"
                alignItems="center"
              >
                {!position.isService && (
                  <>
                    <Grid item container>
                      <Grid item xs={12}>
                        <Typography variant="body2" fontSize={'13px'} fontWeight={500} color={getMarginalityColor(position.marginalityPct)}>
                          Маржиналь&shy;ность
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body2" fontSize={'13px'} fontWeight={500} color={getMarginalityColor(position.marginalityPct)}>
                          {`${displayNumber(position.marginalityPct)}% (${formatPriceCur(position?.marginality)})`}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <IconButton onClick={handleMarginalityClick} size="small" style={{ padding: '0px', marginTop: '-4px' }}>
                        <KeyboardArrowDownIcon htmlColor={getMarginalityColor(position.marginalityPct)} />
                      </IconButton>
                    </Grid>

                    <CustomPopper
                      isOpen={marginalityPopperOpen}
                      anchorEl={marginalityPopperAnchor}
                      onClose={handleMarginalityClose}
                      style={{ width: '300px' }}
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <Typography sx={{ fontSize: '14px' }}>От МРЦ</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
                            {formatNumber2(position?.marginalityPct)}% ({formatPriceCur(position?.marginality)})
                          </Typography>
                        </Grid>

                        <Grid item xs={6}>
                          <Typography sx={{ fontSize: '14px' }}>От прайслиста</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
                            {formatNumber2(position?.priceListCostMarginPct)}%
                            {(position?.priceListCostMargin > 0 || position?.priceListCostMargin < 0) && (
                              <> ({formatPriceCur(position?.priceListCostMargin)})</>
                            )}
                          </Typography>
                        </Grid>

                        {profile?.features?.canSeeDealPrimeCostMargin && (
                          <>
                            <Grid item xs={6}>
                              <Typography sx={{ fontSize: '14px' }}>От цены закупки</Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
                                {formatNumber2(position?.purchaseCostMarginPct)}% ({formatPriceCur(position?.purchaseCostMargin)})
                              </Typography>
                            </Grid>
                          </>
                        )}

                        {profile?.features?.canSeeDealPrimeCostMargin && (
                          <>
                            <Grid item xs={6}>
                              <Typography sx={{ fontSize: '14px' }}>От ПлС</Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>
                                {formatNumber2(position?.primeCostMarginPct)}% ({formatPriceCur(position?.primeCostMargin)})
                              </Typography>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </CustomPopper>
                  </>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12} xl={6}>
              <Grid
                container
                p={1}
                sx={{ border: '1px solid #B9B9BA', borderRadius: '6px', height: '100%' }}
                flexWrap="nowrap"
                alignItems="center"
              >
                <Grid item container>
                  <Grid item xs={12} mb={'1px'}>
                    <Typography variant="body2" fontSize={'13px'} fontWeight={500} color={'text.secondary'}>
                      Сумма по позиции
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" fontSize={'13px'} fontWeight={500} color={'text.secondary'}>
                      {`${formatPrice(position?.totalCost)}\xa0₽\xa0\xa0`}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item>
                  <IconButton onClick={handleVatRateClick} size="small" style={{ padding: '0px', marginTop: '-4px' }}>
                    <KeyboardArrowDownIcon />
                  </IconButton>
                </Grid>
                <CustomPopper isOpen={vatRatePopperOpen} anchorEl={vatRateAnchor} onClose={handleVatRateClose} style={{ width: '300px' }}>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Typography sx={{ fontSize: '14px' }}>Сумма без НДС</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography sx={{ fontSize: '14px' }}>
                        {formatPriceCur(position.fullTotalCostWoVat ?? 0, `${currency}/${unit}`)}
                      </Typography>
                    </Grid>

                    <Grid item xs={6} alignSelf={'center'}>
                      <Typography sx={{ fontSize: '14px' }}>НДС, %</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography sx={{ fontSize: '14px' }}>{position.vatRateValue ?? 0}&nbsp;%</Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <Typography sx={{ fontSize: '14px' }}>НДС</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography sx={{ fontSize: '14px' }}>{`${formatPrice(position.vatTotalCost ?? 0)}\xa0₽\xa0\xa0`}</Typography>
                    </Grid>
                  </Grid>
                </CustomPopper>
              </Grid>
            </Grid>
          </Grid>
          <Grid container flexDirection="row" pt={1}>
            <Grid item container xs={12}>
              <Grid item xs={6} alignSelf="center">
                <Typography color="text.secondary" variant="caption" fontWeight={500}>
                  Количество
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography color="text.secondary" variant="caption" fontWeight={500} component="span">
                  {formatNumberByUnit(position.baseQuantity, position.baseUnitCode, position.baseUnit)}
                </Typography>
              </Grid>
            </Grid>
            {position.baseUnitCode !== 'TNE' && (
              <Grid item container xs={12}>
                <Grid item xs={6} alignSelf="center">
                  <Typography color="text.secondary" variant="caption" fontWeight={500}>
                    Вес
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color="text.secondary" variant="caption" fontWeight={500} component="span">
                    {formatNumberByUnit((position.weight || 0) / 1000, 'TNE', 'т')}
                  </Typography>
                </Grid>
              </Grid>
            )}
            {totalShipped > 0 && (
              <>
                <Grid container item xs={12} alignItems={'center'}>
                  <Grid item xs={6} alignSelf="center">
                    <Typography color="text.secondary" variant="caption" fontWeight={500}>
                      Отгружено
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography color="text.secondary" variant="caption" fontWeight={500} component="span">
                      {formatNumberByUnit(totalShipped, position.baseUnitCode, position.baseUnit)}
                    </Typography>
                  </Grid>
                  <Grid item ml={1}>
                    <ShippedProgress currentQuantity={totalShipped} baseQuantity={position.reservationSummary?.billBaseQuantity} />
                  </Grid>
                </Grid>
                <Grid container item xs={12}>
                  <Grid item xs={6} alignSelf="center">
                    <Typography color="text.secondary" variant="caption" fontWeight={500}>
                      Остаток
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography color="text.secondary" variant="caption" fontWeight={500} component="span">
                      {formatNumber3(Number(position.baseQuantity) - totalShipped)} {position.baseUnit}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
        <PositionMotivationList position={position} isForDeal />
      </Grid>
    </Grid>
  );
});
