import { makeAutoObservable, onBecomeUnobserved, runInAction } from 'mobx';
import hash from 'object-hash';
import { ApiStore, AxiosCallContext, getCallContext } from '../Global/ApiStore';
import { RootStore } from '../StoreManager';
import {
  AgreementUpdates,
  AgreementUpdatesPosition,
  ContractCostAgreement,
  ContractCostPosition,
  ContractCostAgreementsCreateRequest,
  ContractCostAgreementsCreateResponse,
  ContractCostAgreementsItemResponse,
  ContractCostAgreementsItemSaveRequest,
  ContractCostAgreementsItemSaveResponse,
  DocumentAgreementApproval,
  DocumentNoticeTypeEnum,
  DocumentNotice,
  ContractCostAgreementsApprovalConfirmResponse,
  ContractCostAgreementsApprovalDeclineResponse,
  ContractCostAgreementsApprovalWithdrawResponse,
  Employee,
  DealApprovalStateEnum,
  DealDocument,
  DealPositionUnitCostTypeEnum,
  CatalogProductsFastSearchResponse,
  AgreementUpdatesLeadPosition,
} from '../../api/marketx';
import { v4 as uuidv4, v4 as uuidV4 } from 'uuid';
import { AxiosPromise, AxiosResponse } from 'axios';
import { castInput, ValueStore, ValueStoreInputTypes, ValueStoreInputTypesType } from '../ValueStore';
import { isOfflineError } from '../../utils/network';
import { buildHash } from 'src/slices/AppDeal/lib';
import {
  DealApprovalAction,
  DealApprovalActionType,
  DealApprovalState,
  ErrorForButton,
} from 'src/components/Deals/ApproveResolutionControl';
import { ApprovalStoreInterface, ConfirmDialogContent } from '../DealItemStore';
import { AppDealApproval, AppDealDistributor } from 'src/slices/AppDeal';
import { SnackbarStore } from '../SnackbarStore';
import { AgreementPaymentStore } from './AgreementPaymentStore';
import { AgreementTopBarStore } from './AgreementTopBarStore';
import { setClear } from '../../utils/mobx';
import { AgreementApprovalHistoryStore } from './AgreementApprovalHistoryStore';
import { MsgType, WebSocketStore } from '../Global/WebSocketStore';
import { IncomeMessage } from '../Global/EventManager';
import { CreditStateStore } from './CreditStateStore';
import { AgreementDistributorListStore } from './AgreementDistributorListStore';
import { ErrorDocuments } from '../Documents/types';
import { PositionsManufacturersListStore } from '../Deals/PositionsManufacturersListStore';

export interface AgreementUpdatesSource {
  distributorCode?: string;
  positions?: Record<string, AgreementUpdatesPosition>;
  comment?: string;
  leadPositions?: Array<AgreementUpdatesLeadPosition> | null;
  prepayPct?: number;
  postpayDays?: number;
  contractCode?: string;
  lineNumber?: number;
}

enum ActionType {
  save = 'save',
  upload = 'upload',
  deleteFiles = 'deleteFiles',
  movePosition = 'movePosition',
}

interface UpdateQueueItem {
  hash: string; // хэш объекта изменений
  properties: string; // хэш названий изменяемых полей
  actionType: ActionType;
  positionCode?: string;
  uploadPositionCode?: string;
  uploadFiles?: File[];
  deleteFilesCodes?: string[];
  documentTypeCode?: string;
  updates?: AgreementUpdatesSource;

  onResolve?: (UpdateQueueItem) => void;
  onReject?: (UpdateQueueItem) => void;
}

export interface ContractCostAgreementWithBar extends ContractCostAgreement {
  bareCostInputAttributes?: {
    isSwitcherActive?: boolean;
  };
}

class ApprovalStore implements ApprovalStoreInterface {
  readonly store: AgreementItemStore;
  labelsMenuItems = {
    confirm: 'Подтвердить соглашение',
    decline: 'Отклонить соглашение',
  };
  labelsControls = {
    [DealApprovalState.Declined]: 'Соглашение отклонено',
  };
  constructor(store: AgreementItemStore) {
    this.store = store;
  }
  get isSaving(): boolean {
    return this.store?.isSaving ?? false;
  }
  stateCode(): DealApprovalStateEnum {
    return this.store.agreement.approval?.stateCode as unknown as DealApprovalStateEnum;
  }
  get errors(): ErrorForButton[] {
    return getErrorsApprovalControl(this.store.agreement?.approval, this.store.agreement?.approval?.confirmNotices || []);
    // return getErrorsApprovalControl(
    //   this.store.agreement?.approval,
    //   this.store.agreement?.positions || [],
    //   this.store.agreement?.approval?.confirmNotices
    // );
  }

  get isLoading(): boolean {
    return this.store?.isLoading ?? false;
  }
  get approval(): DocumentAgreementApproval {
    return this.store.agreement.approval;
  }
  get code(): string {
    return this.store.agreementCode;
  }
  get creator(): AppDealDistributor {
    return mapCreator(this.store.agreement.creator);
  }
  async imposeApprovalResolutionByType(action: DealApprovalActionType, comment?: string): Promise<void> {
    return this.store.imposeApprovalResolutionByType(action, comment);
  }

  buildContentForDialog(action: DealApprovalActionType, label: string): ConfirmDialogContent {
    const cdc = {
      header: label,
      contentHeader: '',
      text: '',
      isShowInput: false,
      isInputRequired: false,
      action: action,
    } as ConfirmDialogContent;

    switch (action) {
      case DealApprovalAction.Confirm:
        cdc.contentHeader = 'Вы подтверждаете актуальность соглашения?';
        cdc.text = 'После подтверждения редактирование соглашения станет недоступно.';
        cdc.isShowInput = true;
        break;
      case DealApprovalAction.Decline:
        cdc.contentHeader = 'Вернуть соглашение в работу ?';
        cdc.isShowInput = true;
        cdc.isInputRequired = true;
        break;

      default:
        throw new Error(`Необработанный action ${action}`);
    }
    return cdc;
  }
}

const getErrorsApprovalControl = (approval: AppDealApproval, confirmNotices: DocumentNotice[] = []): ErrorForButton[] => {
  const errors: ErrorForButton[] = [];
  if (!approval?.currentAccessGroup) {
    errors.push({ text: 'Вы не можете согласовывать данное соглашение.' });
  }
  if (!approval?.resolutionMinimumGroup) {
    errors.push({ text: 'Согласование невозможно' });
  }
  if (confirmNotices?.length > 0) {
    confirmNotices
      .filter(i => i.type === DocumentNoticeTypeEnum.Error)
      .map(ntc => {
        errors.push({ text: ntc.message || 'Ошибка: ' + ntc.code });
      });
  }
  return errors;
};

/**
 * Одно соглашение
 */
export class AgreementItemStore {
  public apiStore: ApiStore;
  public rootStore: RootStore;
  webSocketStore: WebSocketStore;

  snackbarStore: SnackbarStore;
  private paymentStore: AgreementPaymentStore = null;
  private topBarEntityStore: AgreementTopBarStore = null;

  public isLoading = false;
  public isDeleted = false;
  public isLoaded = false;

  private updateDebounceTimeout: NodeJS.Timeout;
  private updateDebounceTimeoutDelay = 600;
  private offlineRequestDelay = 9000;

  agreementCode = '';
  creditStateStore: CreditStateStore;

  agreement: ContractCostAgreementWithBar = {};

  /**
   * Сторы для значений позиций (количество, цена).
   * Позволяет организовать удобный для пользователя ввод с обновлениями от бэка.
   */
  positionsValuesStores = new WeakMap<ContractCostPosition, Map<string, ValueStore>>();
  valuesStores = new Map<string, ValueStore>();

  /**
   * Очередь обновлений соглашения
   */
  updatesQueue = new Array<UpdateQueueItem>();

  /**
   * Прямо сейчас выполняется очередь обновлений.
   */
  public isSaving = false;

  /**
   * Требуется обновить соглашение
   */
  public reloadRequired = false;
  public agreementPositionsbyProductCode: Record<string, ContractCostPosition> = {};

  positionsManufacturersStore: PositionsManufacturersListStore;
  approvalStore: ApprovalStore;
  approvalHistoryStore: AgreementApprovalHistoryStore;
  error: ErrorDocuments = null;
  /**
   * Свернутые позиции
   */
  positionClosed: Record<string, boolean> = {};

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.apiStore = rootStore.getApiStore();
    this.webSocketStore = rootStore.getWebSocket();
    this.snackbarStore = rootStore.getSnackbar();
    this.approvalStore = new ApprovalStore(this);
    this.approvalHistoryStore = new AgreementApprovalHistoryStore(this);
    this.handleWs = this.handleWs.bind(this);
    makeAutoObservable(this, {
      apiStore: false,
      positionsValuesStores: false,
      valuesStores: false,
      mapAgreementPositionsbyProductCode: false,
      updatesQueue: false,
    });

    onBecomeUnobserved(this, 'agreement', () => {
      this.webSocketStore.removeSubscriptions(['agreement.' + this.agreement.code]);
    });
  }
  movePositionOfDealManually(startIndex: number, endIndex: number): void {
    const [removed] = this.agreement.positions.splice(startIndex, 1);
    this.agreement.positions.splice(endIndex, 0, removed);
  }

  movePositionOfAgreement(positionCode: string, newPositionForPositionOfDeal: number, currentPositionForPositionOfDeal: number): void {
    const updItem = <UpdateQueueItem>{
      hash: uuidv4(),
      actionType: 'movePosition',
      positionCode,
      updates: {
        lineNumber: newPositionForPositionOfDeal,
      },
    };
    this.movePositionOfDealManually(currentPositionForPositionOfDeal - 1, newPositionForPositionOfDeal - 1);
    updItem.onResolve = () => {};
    this.updatesQueue.push(updItem);
    this.updateQueueDebounce(this.updateDebounceTimeoutDelay);
  }
  mapAgreementPositionsbyProductCode(v: ContractCostPosition[]): Record<string, ContractCostPosition> {
    return (this.agreementPositionsbyProductCode = v.reduce<Record<string, ContractCostPosition>>((acc, pos) => {
      acc[buildHash(pos.productCode)] = pos;
      return acc;
    }, {}));
  }

  isViewOnly(): boolean {
    return !this.isLoaded || !this.agreement || !this.agreement.editingEnabled || this.isDeleted;
  }

  setAgreementCode(code: string): void {
    this.agreementCode = code;
  }
  setAgreement(agreement: ContractCostAgreement): void {
    if (this.agreement === agreement) {
      return;
    }
    runInAction(() => {
      this.agreement = agreement;
      this.setAgreementCode(agreement.code);
      this.isLoading = false;
      this.isLoaded = true;
    });
  }

  replacePositionProduct(positionCode: string, productCode: string): Promise<UpdateQueueItem> {
    const changes: AgreementUpdatesSource = {
      positions: {
        [positionCode]: {
          code: positionCode,
          productCode: productCode,
        },
      },
    };
    return this.updateAgreement(changes);
  }

  isAllPositionsClosed(): boolean {
    return this.agreement?.positions?.length === this.agreement?.positions?.filter(position => !!this.positionClosed[position.code]).length;
  }
  isPositionClosed(position: any): boolean {
    return this.positionClosed[position.code];
  }
  closePosition(code: string): void {
    this.positionClosed[code] = !this.positionClosed[code];
  }
  closeAllPositions(): void {
    if (!this.agreement?.positions?.length) {
      console.warn('empty positionsCodes');
      return;
    }
    const isAllClosed =
      this.agreement?.positions?.length === this.agreement?.positions?.filter(position => !!this.positionClosed[position.code]).length;
    runInAction(() => {
      this.agreement?.positions?.forEach(position => {
        this.positionClosed[position.code] = !isAllClosed;
      });
    });
  }
  handleWs(msg: IncomeMessage): void {
    if (msg.msgType === MsgType.WEB_ENTITY_TOUCH_TOUCH || msg.msgType === MsgType.SHOP_FRONT_AGREEMENT_PRODUCT_ADDED) {
      const arr = msg.data.entities || [];
      if (arr.includes('agreement.' + this.agreement?.code) || msg.data?.agreementCode === this.agreement?.code) {
        this.startIdleRefresh(true);
      }
    }
  }
  getCreditStateStore(): CreditStateStore {
    if (!this.creditStateStore) {
      this.creditStateStore = new CreditStateStore(this.rootStore);
    }
    return this.creditStateStore;
  }
  handleViewAgreementItemChange(): void {
    this.snackbarStore.showInfo(`Соглашение обновлено до актуального состояния`);
  }

  startIdleRefresh(isIdleRefresh = false): void {
    runInAction(() => (this.isLoading = true));

    this.apiStore
      .apiClientCustomer()
      .contractCostAgreementsItem(this.agreementCode)
      .then((res: AxiosResponse<ContractCostAgreementsItemResponse>) => {
        this.setLoadingResult(getCallContext(res), res.data.agreement);

        if (isIdleRefresh) {
          if (this.updatesQueue.length) {
            return;
          }
          this.snackbarStore.showInfo(`Соглашение обновлено до актуального состояния`);
        }
      })
      .catch(r => {
        runInAction(() => {
          const error = r.response?.data?.error;
          this.error = {
            code: error.code || 400,
            message: error.message || 'Не найдено',
            reason: error.reason === 'agreement_not_found' ? 'not_found_document' : error.reason || 'error',
          };
          this.isLoaded = true;
          this.isLoading = false;
        });
        console.warn('Не удалось загрузить соглашение', r);
      });
  }

  pageUrl(): string {
    return '/app/agreements/' + (this.agreement?.code || this.agreementCode);
  }

  /**
   * Создание нового соглашения для клиента
   * @param customerCode
   */
  createForCustomer(customerCode: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.apiStore
        .apiClientCustomer()
        .contractCostAgreementsCreate(<ContractCostAgreementsCreateRequest>{
          frontCode: uuidV4(),
          customerCode: customerCode,
        })
        .then((res: AxiosResponse<ContractCostAgreementsCreateResponse>) => {
          this.setLoadingResult(getCallContext(res), res.data.agreement);
          resolve();
        })
        .catch(reject);
    });
  }

  getDistributorsStore(): AgreementDistributorListStore {
    return this.rootStore.getLocalStoreFor(this, 'AgreementDistributorListStore', AgreementDistributorListStore, store => {
      store.loadForAgreement(this.agreement);
    });
  }

  getProduct(productCode: string): ContractCostPosition {
    return this.agreementPositionsbyProductCode[buildHash(productCode)];
  }

  addProduct(productCode: string, quantity: number, unitCode: string, frontCode: string, cost: number): Promise<void> {
    const positionByProduct = this.agreementPositionsbyProductCode[buildHash(productCode)];
    const updatesPosition: AgreementUpdatesPosition = {
      quantity: quantity || 1,
      productCode: productCode,
      useManualDeliveryTotalCost: true,
      distributeCredit: true,
      unitCode,
      bareUnitCost: cost,
      isArchive: false,
    };
    if (positionByProduct) {
      updatesPosition.code = positionByProduct.code;
    } else {
      updatesPosition.frontCode = frontCode;
    }
    return new Promise<void>((resolve, reject): void => {
      this.apiStore
        .apiClientCustomer()
        .contractCostAgreementsItemSave(this.agreementCode, <ContractCostAgreementsItemSaveRequest>{
          updates: <AgreementUpdates>{
            positions: [updatesPosition],
          },
        })
        .then((res: AxiosResponse<ContractCostAgreementsItemSaveResponse>) => {
          this.setLoadingResult(getCallContext(res), res.data.agreement);
          resolve();
        })
        .catch(r => reject(r));
    });
  }

  /**
   * Стор для вывода и управления схемой (оплаты) сделки
   */
  getPaymentStore(): AgreementPaymentStore {
    if (!this.paymentStore) {
      this.paymentStore = new AgreementPaymentStore(this);
    }
    return this.paymentStore;
  }

  /**
   * Стор для вывода данных в шапке страницы
   */
  getTopBarEntityStore(): AgreementTopBarStore {
    if (!this.topBarEntityStore) {
      this.topBarEntityStore = new AgreementTopBarStore(this);
    }
    return this.topBarEntityStore;
  }

  getValueStore(field: string, type: ValueStoreInputTypesType = ValueStoreInputTypes.String): ValueStore {
    let fieldStore = this.valuesStores.get(field);
    if (!fieldStore) {
      fieldStore = new ValueStore({
        inputDelay: 700,
        value: this.filterDisplayField(field, this.agreement[field]),
        onInputChangeDebounced: (value: string) => {
          this.updateAgreement({ [field]: castInput(value, type) });
        },
      });
      this.valuesStores.set(field, fieldStore);
    }
    return fieldStore;
  }
  getValueStoreByPos(
    position: ContractCostPosition,
    field: string,
    type: ValueStoreInputTypesType = ValueStoreInputTypes.String
  ): ValueStore {
    let stores = this.positionsValuesStores.get(position);
    if (!stores) {
      stores = new Map<string, ValueStore>();
      this.positionsValuesStores.set(position, stores);
    }
    let fieldStore = stores.get(field);
    if (!fieldStore) {
      fieldStore = new ValueStore({
        inputDelay: 700,
        value: position[field],
        onInputChangeDebounced: value => {
          this.updatePosition(position, { [field]: castInput(value, type) });
        },
      });
      stores.set(field, fieldStore);
    }
    return fieldStore;
  }

  /**
   * Формирует строку из названий обновляемых полей.
   * Если такие строки совпадают, значит новый апдейт может заменить предыдущий.
   * @param updates
   */
  hashUpdatesProperties(updates: AgreementUpdatesSource): string {
    const keys = Object.keys(updates);
    if (keys.includes('positions')) {
      Object.keys(updates.positions).forEach(code => {
        const position = updates.positions[code];
        Object.keys(position).forEach(field => {
          keys.push('pos-' + code + '-' + field);
        });
      });
    }

    return keys.join(';');
  }

  updateAgreement(updates: AgreementUpdatesSource): Promise<UpdateQueueItem> {
    this.isLoading = true;
    const curHash = hash(updates);
    const prevItem = this.updatesQueue.length ? this.updatesQueue[this.updatesQueue.length - 1] : undefined;
    const wasChanged = !prevItem || prevItem.hash !== curHash;

    if (!wasChanged) {
      console.log('ignore updateAgreement, hash repeat', curHash, updates);
      return;
    }
    const properties = this.hashUpdatesProperties(updates);
    const updItem = <UpdateQueueItem>{
      actionType: ActionType.save,
      hash: curHash,
      properties,
      updates,
    };
    const promise = new Promise<UpdateQueueItem>((resolve, reject) => {
      updItem.onResolve = resolve;
      updItem.onReject = reject;
    });
    // тут можно попробовать склеить новое изменение с последним в очереди.Л
    if (prevItem && prevItem.properties === updItem.properties) {
      this.updatesQueue.splice(this.updatesQueue.length - 1, 1, updItem);
    } else {
      this.updatesQueue.push(updItem);
    }

    this.updateQueueDebounce(this.updateDebounceTimeoutDelay);
    return promise;
  }

  updatePositions(changes: AgreementUpdatesSource): Promise<UpdateQueueItem> {
    return this.updateAgreement(changes);
  }

  updatePosition(position: ContractCostPosition, updates: AgreementUpdatesPosition): Promise<UpdateQueueItem> {
    const changes: AgreementUpdatesSource = {
      positions: {
        [position.code]: { ...updates, code: position.code },
      },
    };
    return this.updateAgreement(changes);
  }

  deletePosition(position: ContractCostPosition): Promise<UpdateQueueItem> {
    const changes: AgreementUpdatesSource = {
      positions: {
        [position.code]: {
          code: position.code,
          isArchive: true,
        },
      },
    };
    return this.updateAgreement(changes);
  }

  restorePosition(position: ContractCostPosition): Promise<UpdateQueueItem> {
    const changes: AgreementUpdatesSource = {
      positions: {
        [position.code]: {
          code: position.code,
          isArchive: false,
        },
      },
    };
    return this.updateAgreement(changes);
  }

  /**
   * Загрузка файлов к соглашению
   * @param files
   * @param positionCode если есть positionCode то загружаем файл для позиции
   * @param docTypeCode тип добавляемого документа
   */
  uploadDocuments(files: File[], positionCode: string, docTypeCode: string): void {
    if (!files.length) {
      return;
    }
    const updItem = <UpdateQueueItem>{
      hash: uuidv4(),
      properties: uuidv4(),

      actionType: ActionType.upload,

      uploadPositionCode: positionCode || undefined,
      uploadFiles: files,
      documentTypeCode: docTypeCode,
    };
    let text = 'Файлы успешно загружены';
    if (files.length === 1) {
      text = 'Файл успешно загружен';
    }
    updItem.onResolve = () => {
      this.snackbarStore.showInfo(text);
    };
    this.updatesQueue.push(updItem);
    this.updateQueueDebounce(this.updateDebounceTimeoutDelay);
  }

  /**
   * Загрузка позиций соглашения из exel файла
   * @param files
   */
  priceUpload(files: File[]): AxiosPromise<ContractCostAgreement> {
    if (!files.length) {
      return;
    }
    if (files.length !== 1) {
      this.snackbarStore.showError('Загрузите один файл');
      return;
    }
    if (files[0].name.match(/\.(xlsx)/) == null) {
      this.snackbarStore.showError('Загрузите файл в формате .xlsx');
      return;
    }
    return this.apiStore.apiClientCustomer().contractCostAgreementsItemPricesUpload(this.agreementCode, files[0]);
  }

  /**
   * Удаление документов из позиции
   * @param codes
   */
  deleteDocuments(codes: string[]): void {
    if (!codes.length) {
      return;
    }
    const updItem = <UpdateQueueItem>{
      hash: uuidv4(),
      properties: uuidv4(),
      actionType: ActionType.deleteFiles,

      deleteFilesCodes: codes,
    };
    let text = 'Файлы успешно удалены';
    if (codes.length === 1) {
      text = 'Файл успешно удален';
    }
    updItem.onResolve = () => this.snackbarStore.showInfo(text);
    this.updatesQueue.push(updItem);
    this.updateQueueDebounce(this.updateDebounceTimeoutDelay);
  }

  /**
   * Запуск выполнения очереди.
   * @param delay задержка перед запуском обновлением.
   */
  updateQueueDebounce(delay: number): void {
    if (this.updateDebounceTimeout) {
      clearTimeout(this.updateDebounceTimeout);
    }
    this.updateDebounceTimeout = setTimeout(() => {
      this.updateQueueExec();
    }, delay);
  }

  updateQueueExec(): void {
    if (this.isSaving) {
      // конкурирующий процесс?
      return;
    }
    if (!this.updatesQueue.length) {
      // нет изменений
      return;
    }
    const upd: UpdateQueueItem = this.updatesQueue[0];
    runInAction(() => {
      this.isSaving = true;
    });

    const actionPromise: any[] = [];

    switch (upd.actionType) {
      case ActionType.save: {
        const promise = this.apiStore
          .apiClientCustomer()
          .contractCostAgreementsItemSave(this.agreementCode, <ContractCostAgreementsItemSaveRequest>{
            updates: mapAgreementUpdates(upd.updates),
          })
          .then((res: AxiosResponse<ContractCostAgreementsItemSaveResponse>) => {
            // если это последнее задание в очереди, то актуализируем
            if (this.updatesQueue.filter(i => i !== upd).length === 0) {
              this.setLoadingResult(getCallContext(res), res.data.agreement);
            }
          });
        actionPromise.push(promise);
        break;
      }
      case ActionType.upload: {
        const promises = upd.uploadFiles.map(file =>
          this.apiStore
            .apiClientCustomer()
            .contractCostAgreementsItemDocsUpload(this.agreementCode, upd.documentTypeCode, file, undefined, upd.uploadPositionCode)
            .then(({ data }) => {
              this.setResultUploadDocument(data);
            })
            .catch(err => {
              console.warn('Не удалось загрузить файлы', err);
            })
        );
        actionPromise.push(...promises);
        break;
      }
      case ActionType.deleteFiles: {
        const promises = upd.deleteFilesCodes.map(code =>
          this.apiStore
            .apiClientCustomer()
            .contractCostAgreementsItemDocsItemDelete(this.agreementCode, code)
            .then(() => {
              this.setResultDeleteDocuments([code]);
            })
        );
        actionPromise.push(...promises);
        break;
      }
      case ActionType.movePosition: {
        const promises = this.apiStore
          .apiClientCustomer()
          .contractCostAgreementsPositionsMove({
            agreementCode: this.agreementCode,
            positions: [{ code: upd.positionCode, lineNumber: upd.updates.lineNumber }],
          })
          .then(res => {
            this.setLoadingResult(getCallContext(res), res.data.agreement);
          });
        actionPromise.push(promises);
        break;
      }
      default: {
        actionPromise.push(new Promise((success, reject) => reject('unknown actionType ' + upd.actionType)));
      }
    }

    Promise.all(actionPromise)
      .then(() => {
        runInAction(() => {
          this.updatesQueue = this.updatesQueue.filter(q => q !== upd);
          this.isSaving = false;
        });
        if (upd.onResolve) {
          upd.onResolve(upd);
        }
        this.updateQueueDebounce(1);
      })
      .catch(error => {
        // обработка исключения возникшего во время выполнения задачи.
        runInAction(() => {
          this.isSaving = false;
        });
        if (isOfflineError(error)) {
          // Если ошибка сети, то повторяем запрос через заданный интервал, не очищая очередь.
          clearTimeout(this.updateDebounceTimeout);
          this.updateQueueDebounce(this.offlineRequestDelay);
        } else {
          if (upd.onReject) {
            upd.onReject(upd);
          } else {
            console.warn(error, upd);
          }

          // Если ошибка в логике приложения, то исключаем задачу из очереди и запускаем очередь со следующей задачи.
          runInAction(() => {
            this.updatesQueue = this.updatesQueue.filter(q => q !== upd);
          });
          clearTimeout(this.updateDebounceTimeout);
          this.updateQueueDebounce(1);
        }
        this.setReloadRequired(true);
      });
  }

  filterDisplayField(key: string, value: any): any {
    if (key === 'validUntil' && value && value.indexOf('2000-') === 0) {
      // Не показываем дату окончания до 2000 года.
      // Это дефолтное значение, означает что дата не указана.
      return '';
    }
    return value;
  }

  setLoadingResult(ctx: AxiosCallContext, agreement: ContractCostAgreement): void {
    const same = this.agreementCode === agreement?.code;
    this.isDeleted = !!agreement.isDeleted;
    this.isLoading = false;

    if (!this.isLoaded) {
      this.sendTouchUpdateWs(agreement?.code);
    }

    if (same) {
      // Сделка та же самая. Нужно сохранить ValueStore, чтобы не потерять пользовательские изменения.
      const prevPositions = new Map<string, AgreementUpdatesPosition>();
      this.agreement?.positions?.forEach(p => {
        prevPositions.set(p.code, p);
      });

      setClear(this.agreement, mapAgreement(agreement));
      if (!this.isLoaded) {
        this.agreement?.positions?.forEach(p => {
          this.positionClosed[p.code] = !!p.isService;
        });
      }
      this.isLoaded = true;

      this.valuesStores.forEach((value, key) => {
        const val = this.filterDisplayField(key, this.agreement[key]);
        value.handleModelChange(val, ctx.startTime);
      });
      this.agreement?.positions?.forEach(newPos => {
        const prevPos = prevPositions.get(newPos.code);
        if (prevPos) {
          const prevStores = this.positionsValuesStores.get(prevPos);
          if (prevStores) {
            prevStores.forEach((value, key) => {
              value.handleModelChange(newPos[key], ctx.startTime);
            });
            this.positionsValuesStores.set(newPos, prevStores);
          }
        }
      });
    } else {
      // Какая-то другая сделка. Вообще такого не должно происходить, но мало ли.
      this.agreementCode = agreement?.code;
      setClear(this.agreement, mapAgreement(agreement));
      this.isLoaded = true;
    }
    if (this.paymentStore) {
      this.paymentStore.setAgreement(this, ctx.startTime);
    }
    if (!this.positionsManufacturersStore) {
      this.positionsManufacturersStore = new PositionsManufacturersListStore(this.rootStore);
      if (agreement) {
        this.positionsManufacturersStore.loadForAgreement(agreement);
      }
    }
    this.agreementPositionsbyProductCode = this.mapAgreementPositionsbyProductCode(this.agreement.positions || []);
  }

  sendTouchUpdateWs(agreementCode?: string): void {
    const entities = agreementCode ? ['agreement.' + agreementCode] : [];
    const sendUpdate = (): void => {
      if (this.webSocketStore.isConnected) {
        this.webSocketStore.addSubscriptions(entities);
      } else {
        setTimeout(sendUpdate, 1000);
      }
    };
    sendUpdate();
  }

  setResultUploadDocument(file: DealDocument): void {
    this.agreement.documents.push(file);
  }

  setResultDeleteDocuments(codes: string[]): void {
    if (this.agreement?.documents) {
      this.agreement.documents = this.agreement.documents.filter(document => !codes.includes(document.code));
    }
  }

  async imposeApprovalResolutionByType(action: DealApprovalActionType, comment?: string): Promise<void> {
    const promises: Promise<any>[] = [];
    this.isLoading = true;
    switch (action) {
      case DealApprovalAction.Confirm: {
        promises.push(
          this.apiStore
            .apiClientCustomer()
            .contractCostAgreementsApprovalConfirm({
              agreementCode: this.agreementCode,
              comment: comment,
            })
            .then((res: AxiosResponse<ContractCostAgreementsApprovalConfirmResponse>) => {
              this.setLoadingResult(getCallContext(res), res.data.agreement);
            })
        );
        break;
      }
      case DealApprovalAction.Decline: {
        promises.push(
          this.apiStore
            .apiClientCustomer()
            .contractCostAgreementsApprovalDecline({
              agreementCode: this.agreementCode,
              comment: comment,
            })
            .then((res: AxiosResponse<ContractCostAgreementsApprovalDeclineResponse>) => {
              this.setLoadingResult(getCallContext(res), res.data.agreement);
            })
        );
        break;
      }
      case DealApprovalAction.Withdraw: {
        promises.push(
          this.apiStore
            .apiClientCustomer()
            .contractCostAgreementsApprovalWithdraw({
              agreementCode: this.agreementCode,
              comment: comment,
            })
            .then((res: AxiosResponse<ContractCostAgreementsApprovalWithdrawResponse>) => {
              this.setLoadingResult(getCallContext(res), res.data.agreement);
            })
        );
        break;
      }
      default:
        console.error('unknown resolution action', action);
    }
    return Promise.all(promises)
      .then(() => {})
      .catch(() => {
        this.startIdleRefresh();
      });
  }

  setReloadRequired(required: boolean): void {
    this.reloadRequired = required;
  }
  async fastSearhInCatalog(query: string): Promise<CatalogProductsFastSearchResponse> {
    if (query !== '') {
      return this.apiStore
        .apiClientCatalog()
        .catalogProductsFastSearch({
          query: query || '',
          warehouse: undefined, // отсутствует
          branchOffice: this.agreement.branchOfficeCode,
          limit: 30,
          // stockRequired,
        })
        .then((res): CatalogProductsFastSearchResponse => {
          return res.data;
        });
    } else {
      return Promise.resolve({ products: [], total: 0 });
    }
  }
}
const mapAgreementUpdates = (updates: AgreementUpdatesSource): AgreementUpdates => {
  return Object.assign(updates, {
    positions: updates?.positions ? Object.values(updates?.positions) : undefined,
  });
};
const mapAgreement = (agreement: ContractCostAgreement): ContractCostAgreementWithBar => {
  return {
    ...agreement,
    positions: agreement?.positions?.map(mapAgreementPosition),
    bareCostInputAttributes: isAllAgreementPositionsBareMapper(agreement?.positions),
  };
};
export const isAllAgreementPositionsBareMapper = (positions: ContractCostPosition[]): { isSwitcherActive?: boolean } => {
  let isSwitcherActive = true;
  if (!positions?.length) {
    isSwitcherActive = false;
    return { isSwitcherActive };
  }
  positions.forEach(pos => {
    if (pos?.unitCostType !== DealPositionUnitCostTypeEnum.Tender && isSwitcherActive && !pos?.isArchive) {
      isSwitcherActive = false;
    }
  });
  return { isSwitcherActive };
};
const mapAgreementPosition = (position: ContractCostPosition): ContractCostPosition => {
  return {
    ...position,
    quantity: position.quantity ?? 0,
    bareUnitCost: position.bareUnitCost ?? 0,
    manualMrcUnitCost: position.manualMrcUnitCost ?? 0,
  };
};
const mapCreator = (creator: Employee): AppDealDistributor => {
  return {
    surname: creator.surname,
    name: creator.name,
    patronymic: creator.patronymic,
    employeeCode: creator.code,
  };
};
