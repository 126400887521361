import React, { FC, JSX, useCallback, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { formatDateSwaggerZ, formatNumberByUnit } from '@mx-ui/helpers';
import { observer } from 'mobx-react-lite';
import { renderPriceCur } from '../../utils/renderFormat';
import { Bill, BillPosition, Customer, Employee } from '../../api/marketx';
import { currencyDefault, formatNumber02, formatNumber3, pluralRus } from '@mx-ui/helpers';
import { ThreadLink } from './ThreadLink';
import { ClientItemStore } from '../../store/ClientItemStore';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CollapseButton } from '../../views/deals/components/CollapseButton';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import { formatValueCurPayment } from 'src/components/report/payment/PaymentTabPanelClients';
import { formatContractTitle } from '../Deals/ContractSelect/ContractSelect';
import { LabelColor, MxLabel, MxLinearProgress } from '@mx-ui/ui';
import { ChipTitleStyled } from '../../views/deals/DealPosition/DealPositionPrices';
import { ProductLinkBtn } from '../Product/ProductLinkBtn/ProductLinkBtn';
import { AttentionBlock } from '../Clients/AttentionBlock/AttentionBlock';
import { LabelNonResident } from 'src/views/clients/components/CompanyExtInfo';
import { BillListMenu } from './BillListMenu';
import Box from '@mui/material/Box';
import { ButtonInvoicing } from './BillInvoicing/ButtonInvoicing';
import { BillItemInvoicingStore } from './BillInvoicing/BillItemInvoicingStore';
import { BillListStore } from '../../store/Documents/BillListStore';
import IconButton from '@mui/material/IconButton';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useAuthStore } from '../../store/MobxStoreProvider';
import { HistoryLogsDropDownBills } from '../Deals/ApproveResolutionControl/ApproveHistoryWithUpdateLogsDropDown';
import { Link as MxLink } from '@mx-ui/ui';
import { MaxDocumentDate } from '../../types/constants';

const PREFIX = 'Bills';

const classes = {
  text: `${PREFIX}-text`,
};

export const BillStyledPaper = styled(Paper)(({ theme }) => ({
  // border: '1px solid transparent',
  marginBottom: 16,
  padding: theme.spacing(1),
  position: 'relative',
  [`& .${classes.text}`]: {
    fontSize: '14px',
    cursor: 'default',
  },
}));

interface BillListItemProps {
  bill: Bill;
  invoicingStore?: BillItemInvoicingStore;
  client?: ClientItemStore;
  customer?: Customer;
  billListStore?: BillListStore;
  withoutFilters?: boolean;
}

export const ShippedProgress: FC<{
  currentQuantity?: number | undefined;
  baseQuantity?: number | undefined;
  color?: string | undefined;
  minWidth?: string | undefined;
}> = ({ currentQuantity, baseQuantity, color, minWidth }): JSX.Element => {
  const val =
    Math.trunc(((currentQuantity || 0) / baseQuantity) * 100) > 100 ? 100 : Math.trunc(((currentQuantity || 0) / baseQuantity) * 100);
  return (
    <Box display="flex" alignItems="center" position="relative" minWidth={minWidth || '63px'} sx={{ alignItems: 'center' }}>
      <Box width={'100%'} mr={1}>
        <MxLinearProgress
          sx={{ maxWidth: '100%', height: '5px', p: 0 }}
          color="secondary"
          variant="determinate"
          htmlColor={color || '#3F48CC'}
          value={isNaN(val) ? 0 : val}
        />
      </Box>
      <Typography color="text.secondary" variant="caption" fontWeight={500}>{`${isNaN(val) ? 0 : val}%`}</Typography>
    </Box>
  );
};

export const paymentStateColor = (paymentStateCode: string): LabelColor => {
  switch (paymentStateCode) {
    case 'paid':
      return 'success';
    case 'confirmed':
      return 'success';
    case 'not_formed':
      return 'warning';
    case 'partially':
    case 'awaiting':
      return 'warning';
    case 'formed':
      return 'success';
    case 'unpaid':
      return 'error';
    case 'partially_formed':
      return 'warning';
    default:
      return 'secondary';
  }
};

export const billStateColor = (billStateCode: string): LabelColor => {
  return billStateCode === 'inactive' || billStateCode === 'reserve_withdrawn'
    ? 'error'
    : billStateCode === 'awaiting_deal_approval' ||
      billStateCode === 'reserve_unconfirmed' ||
      billStateCode === 'partially_reserved' ||
      billStateCode === 'reserved'
    ? 'warning'
    : 'success';
};

export const billShipmentStateColor = (billShipmentStateCode: string): LabelColor => {
  if (billShipmentStateCode === 'partially_shipped' || billShipmentStateCode === 'unshipped') {
    return 'warning';
  }
  return 'success';
};

export const BillListItem: FC<BillListItemProps> = observer(
  // eslint-disable-next-line complexity
  ({ bill, client, customer, invoicingStore, billListStore, withoutFilters = false }): JSX.Element => {
    const matchesMd = useMediaQuery('(min-width:900px)');
    const matchesXs = useMediaQuery('(min-width:450px)');
    const authStore = useAuthStore();
    const currentRole = authStore.getDefaultDealsEmployeeFilter();
    const allAccordionIndexes = bill?.positions.map((item, index) => `panel${index}`) || [];
    const [expandAll, setExpandAll] = useState([]);
    const handleCloseAllPositions = (): void => {
      setExpandAll(prevState => {
        if (prevState.length) {
          return [];
        }
        return allAccordionIndexes;
      });
    };
    const handleAccordionChange = (panelIndex: string): void => {
      setExpandAll(prevState => {
        const position = prevState.indexOf(panelIndex);
        if (position === -1) {
          return [...prevState, panelIndex];
        }
        return prevState.filter((item, index) => index !== position);
      });
    };

    const customerInfo = client?.details || bill.customer || customer;

    const handleEmployeeSetChange = useCallback(
      (emp?: Employee) => {
        billListStore.employeeSetChange(currentRole, emp);
      },
      [billListStore]
    );

    const handleChangeFilterByStatus = useCallback(
      (status?: string) => {
        if (billListStore.request?.status?.includes(status)) {
          return;
        }
        billListStore.mergeRequest({ status: [status] });
      },
      [billListStore]
    );

    const handleRemoveState = useCallback(
      (status?: string) => {
        if (!billListStore.request?.status?.includes(status)) {
          return;
        }
        const newStatusCodes = billListStore.request?.status?.filter(i => i !== status);
        billListStore.mergeRequest({ status: newStatusCodes });
      },
      [billListStore]
    );

    const handleChangeFilterByShipmentStatus = useCallback(
      (status?: string) => {
        if (billListStore.request?.shipmentStateCodes?.includes(status)) {
          return;
        }
        billListStore.mergeRequest({ shipmentStateCodes: [status] });
      },
      [billListStore]
    );

    const handleRemoveShipmentState = useCallback(
      (status?: string) => {
        if (!billListStore.request?.shipmentStateCodes?.includes(status)) {
          return;
        }
        const newStatusCodes = billListStore.request?.shipmentStateCodes?.filter(i => i !== status);
        billListStore.mergeRequest({ shipmentStateCodes: newStatusCodes });
      },
      [billListStore]
    );

    const handleChangeFilterByPaymentStatus = useCallback(
      (status?: string) => {
        if (billListStore.request?.paymentStateCodes?.includes(status)) {
          return;
        }
        billListStore.mergeRequest({ paymentStateCodes: [status] });
      },
      [billListStore]
    );

    const handleRemovePaymentState = useCallback(
      (status?: string) => {
        if (!billListStore.request?.paymentStateCodes?.includes(status)) {
          return;
        }
        const newStatusCodes = billListStore.request?.paymentStateCodes?.filter(i => i !== status);
        billListStore.mergeRequest({ paymentStateCodes: newStatusCodes });
      },
      [billListStore]
    );

    const handleCustomerChange = useCallback(
      (customerCode?: string) => {
        if (billListStore.request?.customerCodes?.includes(customerCode)) {
          return;
        }
        billListStore.mergeRequest({ customerCodes: customerCode ? [customerCode] : undefined });
      },
      [billListStore]
    );

    const handleCustomerRemoveChange = useCallback(
      (customerCode?: string) => {
        if (!billListStore.request?.customerCodes?.includes(customerCode)) {
          return;
        }
        const newCustomerCodes = billListStore.request?.customerCodes?.filter(i => i !== customerCode);
        billListStore.mergeRequest({ customerCodes: newCustomerCodes });
      },
      [billListStore]
    );

    const handleChangeFilterByPayerCode = useCallback(
      (payerCode?: string) => {
        if (billListStore.request?.payerCodes?.includes(payerCode)) {
          return;
        }
        billListStore.mergeRequest({ payerCodes: payerCode ? [payerCode] : undefined });
      },
      [billListStore]
    );

    const handleRemoveFilterByPayerCode = useCallback(
      (payerCode?: string) => {
        if (!billListStore.request?.payerCodes?.includes(payerCode)) {
          return;
        }
        const newPayerCodes = billListStore.request?.payerCodes?.filter(i => i !== payerCode);
        billListStore.mergeRequest({ payerCodes: newPayerCodes });
      },
      [billListStore]
    );

    return (
      <BillStyledPaper className="withCustomScroll" style={{ display: 'grid', gridTemplateColumns: 'minmax(310px, 1fr)' }}>
        <BillListMenu bill={bill} />
        {bill?.actionLogs && /BL/.test(bill?.documentNumber) && (
          <Box display="flex" alignItems={'center'} flexDirection={'row'} position={'absolute'} right={'60px'} top={'0px'}>
            <HistoryLogsDropDownBills actionLogs={bill.actionLogs} />
          </Box>
        )}
        <Grid container pb={1} justifyContent="space-between" spacing={2}>
          <Grid item md={5} xs={12}>
            <Grid container justifyContent="space-between" mb={1}>
              <Grid item container xs={12} alignItems="center">
                <Grid item mr={1}>
                  <CreditCardOutlinedIcon fontSize="medium" color="primary" />
                </Grid>
                <Grid item>
                  <MxLink href={`/app/bills/${encodeURIComponent(bill?.documentNumber)}`} color="primary" fontWeight={600}>
                    {`${bill?.documentNumber}${matchesXs ? ' от ' + formatDateSwaggerZ(bill.documentDate) : ''}`}
                  </MxLink>
                </Grid>
                <Grid item>
                  <ThreadLink color="primary" link={`/app/thread/bills/${encodeURIComponent(bill.documentNumber)}`} />
                </Grid>
              </Grid>
            </Grid>
            {customerInfo && (
              <Grid container mb={2} flexDirection="column">
                <Grid item>
                  <Typography variant="body2">ИНН {customerInfo?.inn || '—'}</Typography>
                </Grid>
                <Grid
                  item
                  sx={{ maxWidth: matchesXs ? 'auto' : '320px', overflow: 'hidden', textOverflow: 'ellipsis' }}
                  container
                  alignItems={'center'}
                  flexWrap={'nowrap'}
                >
                  {!!customerInfo?.nonResident && (
                    <Grid item mr={1}>
                      <LabelNonResident />
                    </Grid>
                  )}
                  {!!customerInfo?.attentions?.length && (
                    <Grid item mr={1}>
                      <AttentionBlock attentions={customerInfo?.attentions} />
                    </Grid>
                  )}
                  <Grid item>
                    <MxLink
                      href={`/app/clients/${encodeURIComponent(customerInfo?.code)}`}
                      color="text.secondary"
                      fontWeight={600}
                      fontSize={'15px'}
                    >
                      {customerInfo?.shortTitle || customerInfo?.title}
                    </MxLink>
                  </Grid>
                  {billListStore && !withoutFilters && customerInfo.code ? (
                    billListStore.request?.customerCodes?.includes(customerInfo?.code) ? (
                      <Grid item ml={1}>
                        <IconButton
                          size={'small'}
                          onClick={() => handleCustomerRemoveChange(customerInfo.code)}
                          title={`Убрать фильтрацию по ${customerInfo?.shortTitle || customerInfo?.title}`}
                        >
                          <DoDisturbOnOutlinedIcon color={'primary'} sx={{ fontSize: '20px' }} />
                        </IconButton>
                      </Grid>
                    ) : (
                      <Grid item ml={1}>
                        <IconButton
                          size={'small'}
                          onClick={() => handleCustomerChange(customerInfo.code)}
                          title={`Найти счета ${customerInfo?.shortTitle || customerInfo?.title}`}
                        >
                          <FilterListIcon color={'primary'} sx={{ fontSize: '20px' }} />
                        </IconButton>
                      </Grid>
                    )
                  ) : null}
                </Grid>
              </Grid>
            )}
            <Grid container mb={1} flexDirection="column">
              <Grid container mb={1} justifyContent="space-between">
                <Grid item xs={6} md={5}>
                  <Typography fontSize="14px" fontWeight={600} color="text.secondary">
                    Дата документа
                  </Typography>
                </Grid>
                <Grid item xs={6} md={7}>
                  <Typography variant="body2" fontWeight={600} color="text.secondary">
                    {formatDateSwaggerZ(bill.documentDate)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container mb={1} justifyContent="space-between">
                <Grid item xs={6} md={5}>
                  <Typography fontSize="14px" fontWeight={600} color="text.secondary">
                    Создан
                  </Typography>
                </Grid>
                <Grid item xs={6} md={7}>
                  <Typography variant="body2" fontWeight={600} color="text.secondary">
                    {formatDateSwaggerZ(bill.createdAt)}
                  </Typography>
                </Grid>
              </Grid>
              {new Date(bill.validUntil).getFullYear() !== MaxDocumentDate && (
                <Grid container mb={1} justifyContent="space-between">
                  <Grid item xs={6} md={5}>
                    <Typography fontSize="14px" fontWeight={600} color="text.secondary">
                      Действителен до
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={7}>
                    <Typography variant="body2" fontWeight={600} color="text.secondary">
                      {formatDateSwaggerZ(bill.validUntil)}
                    </Typography>
                  </Grid>
                </Grid>
              )}

              {bill?.state && (
                <Grid container mb={1} justifyContent="space-between">
                  <Grid item xs={6} md={5}>
                    <Typography fontSize="14px" color="text.secondary">
                      Статус
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={7}>
                    <Grid container alignItems="center">
                      <MxLabel color={billStateColor(bill?.state?.code)}>{bill?.state?.title}</MxLabel>
                      {billListStore && !withoutFilters ? (
                        billListStore.request?.status?.includes(bill?.state?.code) ? (
                          <Box>
                            <IconButton
                              size={'small'}
                              onClick={() => handleRemoveState(bill?.state?.code)}
                              title={`Убрать фильтрацию по статусу ${bill?.state?.title || ''}`}
                            >
                              <DoDisturbOnOutlinedIcon color={'primary'} sx={{ fontSize: '20px' }} />
                            </IconButton>
                          </Box>
                        ) : (
                          <Box>
                            <IconButton
                              size={'small'}
                              onClick={() => handleChangeFilterByStatus(bill?.state?.code)}
                              title={`Найти счета по статусу ${bill?.state?.title || ''}`}
                            >
                              <FilterListIcon color={'primary'} sx={{ fontSize: '20px' }} />
                            </IconButton>
                          </Box>
                        )
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {bill?.deliveryType?.name && (
                <Grid container mb={1} justifyContent="space-between">
                  <Grid item xs={6} md={5}>
                    <Typography fontSize="14px" color="text.secondary">
                      Способ доставки
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={7}>
                    <Typography variant="body2" color="text.secondary">
                      {bill?.deliveryType?.name || '-'}
                    </Typography>
                  </Grid>
                </Grid>
              )}

              {bill.contract ? (
                <Grid container mb={1} justifyContent="space-between">
                  <Grid item xs={6} md={5}>
                    <Typography variant="body2" color="text.secondary">
                      Договор
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={7} position={'relative'}>
                    <Typography variant="body2" color="text.secondary">
                      <MxLink
                        color="inherit"
                        href={`/app/contracts/${encodeURIComponent(bill.contract.code)}?customerCode=${
                          bill.customer?.code
                        }&branchOfficeCode=${bill.contract.branchOfficeCode}`}
                        title="Перейти к договору"
                        fontSize={'14px'}
                      >
                        {formatContractTitle(bill.contract)}
                      </MxLink>
                    </Typography>
                    {/* Ссылка для тредов договора*/}
                    {/* <Grid item sx={{ position: 'absolute', left: '-30px', top: '50%', transform: 'translateY(-50%)' }}>*/}
                    {/*  <ThreadLink link={`/app/thread/contracts/${encodeURIComponent(bill.contract.code)}`} />*/}
                    {/* </Grid>*/}
                  </Grid>
                </Grid>
              ) : null}

              {bill.partners?.payer?.title && (
                <Grid container mb={1} justifyContent="space-between">
                  <Grid item xs={6} md={5}>
                    <Typography fontSize="14px" color="text.secondary">
                      Плательщик
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={7}>
                    {bill.partners?.payer?.customerCode ? (
                      <Grid container alignItems={'center'}>
                        <Typography fontSize="14px" color="text.secondary">
                          <MxLink
                            href={`/app/clients/${encodeURIComponent(bill.partners?.payer?.customerCode)}`}
                            color="inherit"
                            title="Перейти к плательщику"
                            fontSize={'14px'}
                          >
                            {bill.partners?.payer?.title}
                          </MxLink>
                        </Typography>
                        {billListStore && !withoutFilters ? (
                          billListStore.request?.payerCodes?.includes(bill?.partners?.payer?.code) ? (
                            <Box>
                              <IconButton
                                size={'small'}
                                onClick={() => handleRemoveFilterByPayerCode(bill?.partners?.payer?.code)}
                                title={`Убрать фильтрацию по плательщику ${bill?.partners?.payer?.title || ''}`}
                              >
                                <DoDisturbOnOutlinedIcon color={'primary'} sx={{ fontSize: '20px' }} />
                              </IconButton>
                            </Box>
                          ) : (
                            <Box>
                              <IconButton
                                size={'small'}
                                onClick={() => handleChangeFilterByPayerCode(bill?.partners?.payer?.code)}
                                title={`Найти счета по плательщику ${bill?.partners?.payer?.title || ''}`}
                              >
                                <FilterListIcon color={'primary'} sx={{ fontSize: '20px' }} />
                              </IconButton>
                            </Box>
                          )
                        ) : null}
                      </Grid>
                    ) : (
                      <Typography fontSize="14px" color="text.secondary">
                        {bill.partners?.payer?.title}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              )}

              {bill.partners?.consignee?.title && (
                <Grid container mb={1} justifyContent="space-between">
                  <Grid item xs={6} md={5}>
                    <Typography fontSize="14px" color="text.secondary">
                      Грузополучатель
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={7}>
                    {bill.partners.consignee?.customerCode ? (
                      <Typography fontSize="14px" color="text.secondary">
                        <MxLink
                          href={`/app/clients/${encodeURIComponent(bill.partners.consignee?.customerCode)}`}
                          color="inherit"
                          title="Перейти к грузополучателю"
                          fontSize={'14px'}
                        >
                          {bill.partners.consignee?.title}
                        </MxLink>
                      </Typography>
                    ) : (
                      <Typography fontSize="14px" color="text.secondary">
                        {bill.partners.consignee?.title}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              )}

              {bill?.distributorEmployee && (
                <Grid container mb={1} justifyContent="space-between">
                  <Grid item xs={6} md={5}>
                    <Typography fontSize="14px" color="text.secondary">
                      Реализатор
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={7}>
                    <Grid container alignItems="center">
                      <Typography variant="body2" color="text.secondary">
                        {bill?.distributorEmployee?.surname || ''} {bill?.distributorEmployee?.name || ''}{' '}
                        {bill?.distributorEmployee?.patronymic || ''}
                      </Typography>
                      {billListStore && !withoutFilters ? (
                        billListStore.request?.employeeSetCode === `~emp~2~${bill?.customer?.mainEmployee?.code}` ? (
                          <Box>
                            <IconButton
                              size={'small'}
                              onClick={() => handleEmployeeSetChange()}
                              title={`Убрать фильтрацию по ${bill?.distributorEmployee?.surname || ''} ${
                                bill?.distributorEmployee?.name || ''
                              } ${bill?.distributorEmployee?.patronymic || ''}`}
                            >
                              <DoDisturbOnOutlinedIcon color={'primary'} sx={{ fontSize: '20px' }} />
                            </IconButton>
                          </Box>
                        ) : (
                          <Box>
                            <IconButton
                              size={'small'}
                              onClick={() => handleEmployeeSetChange(bill?.distributorEmployee)}
                              title={`Найти счета ${bill?.distributorEmployee?.surname || ''} ${bill?.distributorEmployee?.name || ''} ${
                                bill?.distributorEmployee?.patronymic || ''
                              }`}
                            >
                              <FilterListIcon color={'primary'} sx={{ fontSize: '20px' }} />
                            </IconButton>
                          </Box>
                        )
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {Boolean(bill.dealCode) && (
                <Grid container mb={1} justifyContent="space-between" alignItems="center">
                  <Grid item xs={6} md={5}>
                    <Typography fontSize="14px" fontWeight={600} color="text.secondary">
                      Заявка
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={7}>
                    <Grid container alignItems={'center'} position={'relative'} flexWrap={'nowrap'}>
                      <Grid item>
                        <Typography variant="body2" color="text.secondary">
                          <MxLink href={`/app/deals/${bill.dealCode}`} color="inherit" title="Перейти к заявке" fontSize={'14px'}>
                            {bill.dealCode}
                          </MxLink>
                          <span>{` от`}</span> {formatDateSwaggerZ(bill.dealCreatedAt)}
                        </Typography>
                      </Grid>
                      <Grid item sx={{ position: 'absolute', left: '-30px', top: '50%', transform: 'translateY(-50%)' }}>
                        <ThreadLink link={`/app/thread/deals/${encodeURIComponent(bill.dealCode)}`} />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}

              <Grid container mb={1} justifyContent="space-between">
                <Grid item xs={6} md={5}>
                  <Typography variant="body2" color="text.secondary">
                    Отрасль
                  </Typography>
                </Grid>
                <Grid item xs={6} md={7}>
                  <Typography variant="body2" color="text.secondary">
                    {customerInfo?.occupationTitle ?? '-'}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container mb={1} justifyContent="space-between">
                <Grid item xs={6} md={5}>
                  <Typography variant="body2" color="text.secondary">
                    Сегмент по потреблению
                  </Typography>
                </Grid>
                <Grid item xs={6} md={7}>
                  <Typography variant="body2" color="text.secondary">
                    {customerInfo?.segmentCode ?? '-'}
                  </Typography>
                </Grid>
              </Grid>

              {bill.hasSubordinateDoc && (
                <Grid container justifyContent="space-between">
                  <Grid item xs={6} md={5}>
                    <Typography variant="body2" color="text.secondary">
                      Есть подчиненные документы
                    </Typography>
                  </Grid>
                </Grid>
              )}
              {/* <Grid container mb={1} justifyContent="space-between">*/}
              {/*  <Grid item xs={6}>*/}
              {/*    <Typography variant="body2" color="text.secondary">*/}
              {/*      Склад*/}
              {/*    </Typography>*/}
              {/*  </Grid>*/}
              {/*  <Grid item xs={6}>*/}
              {/*    <Typography variant="body2" color="text.secondary">*/}
              {/*      {warehouse.name}*/}
              {/*    </Typography>*/}
              {/*  </Grid>*/}
              {/* </Grid>*/}
            </Grid>
          </Grid>

          <Grid item md={7} xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Grid container mb={1} flexDirection="column">
                  <Grid container justifyContent="space-between" mb={1}>
                    <Grid item xs={6}>
                      <Typography fontWeight={700} color="textPrimary">
                        Сумма счета
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography fontWeight={700} color="textPrimary">
                        {renderPriceCur(bill.totalCost)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container mb={1} justifyContent="space-between">
                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary">
                        Количество
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary">
                        {formatNumber3(bill.positionsTotalWeight / 1000)}
                        {` т`}
                      </Typography>
                    </Grid>
                  </Grid>
                  {bill?.state && (
                    <Grid container mb={1} justifyContent="space-between">
                      <Grid item xs={6}>
                        <Typography fontSize="14px" color="text.secondary">
                          Статус отгрузки
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Grid container alignItems={'center'}>
                          <MxLabel color={billShipmentStateColor(bill?.shipmentsState?.code)}>{bill?.shipmentsState?.title}</MxLabel>
                          {billListStore && !withoutFilters ? (
                            billListStore.request?.shipmentStateCodes?.includes(bill?.shipmentsState?.code) ? (
                              <Box>
                                <IconButton
                                  size={'small'}
                                  onClick={() => handleRemoveShipmentState(bill?.shipmentsState?.code)}
                                  title={`Убрать фильтрацию по статусу отгрузки ${bill?.shipmentsState?.title || ''}`}
                                >
                                  <DoDisturbOnOutlinedIcon color={'primary'} sx={{ fontSize: '20px' }} />
                                </IconButton>
                              </Box>
                            ) : (
                              <Box>
                                <IconButton
                                  size={'small'}
                                  onClick={() => handleChangeFilterByShipmentStatus(bill?.shipmentsState?.code)}
                                  title={`Найти счета по статусу отгрузки ${bill?.shipmentsState?.title || ''}`}
                                >
                                  <FilterListIcon color={'primary'} sx={{ fontSize: '20px' }} />
                                </IconButton>
                              </Box>
                            )
                          ) : null}
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  <Grid container mb={1} justifyContent="space-between">
                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary">
                        Сумма отгрузок
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary">
                        {renderPriceCur(bill.shipmentsTotalCost)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container mb={1} alignItems={'center'}>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary">
                        Отгружено
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" color="text.secondary">
                        {formatNumber3(bill.shipmentsTotalWeight / 1000)}
                      </Typography>
                    </Grid>
                    <Grid item ml={1}>
                      <ShippedProgress currentQuantity={bill.shipmentsTotalWeight / 1000} baseQuantity={bill.positionsTotalWeight / 1000} />
                    </Grid>
                    {invoicingStore ? (
                      <Grid item ml={1}>
                        <ButtonInvoicing billItem={bill} invoicingStore={invoicingStore} />
                      </Grid>
                    ) : null}
                  </Grid>
                  <Grid container mb={1}>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary">
                        Остаток
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2" color="text.secondary">
                        {formatNumber3((bill.positionsTotalWeight / 1000 || 0) - bill.shipmentsTotalWeight / 1000 || 0)}
                        {` т`}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container mb={1} justifyContent="space-between">
                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary">
                        Предоплата
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary">
                        {formatNumber02(bill.prepayPct)} %
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container mb={1} justifyContent="space-between">
                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary">
                        Отсрочка
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary">
                        {bill.postpayDays} {pluralRus(bill.postpayDays, ['день', 'дня', 'дней'])}
                      </Typography>
                    </Grid>
                  </Grid>
                  {bill.paymentSummary && (
                    <>
                      <Grid container mb={1} justifyContent="space-between">
                        <Grid item xs={6}>
                          <Typography variant="body2" color="text.secondary">
                            Статус оплаты
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Grid container alignItems={'center'}>
                            <MxLabel color={paymentStateColor(bill.paymentSummary?.paymentStateCode)}>
                              {bill.paymentSummary?.paymentStateTitle}
                            </MxLabel>
                            {billListStore && !withoutFilters ? (
                              billListStore.request?.paymentStateCodes?.includes(bill.paymentSummary?.paymentStateCode) ? (
                                <Box>
                                  <IconButton
                                    size={'small'}
                                    onClick={() => handleRemovePaymentState(bill.paymentSummary?.paymentStateCode)}
                                    title={`Убрать фильтрацию по статусу отгрузки ${bill.paymentSummary?.paymentStateTitle || ''}`}
                                  >
                                    <DoDisturbOnOutlinedIcon color={'primary'} sx={{ fontSize: '20px' }} />
                                  </IconButton>
                                </Box>
                              ) : (
                                <Box>
                                  <IconButton
                                    size={'small'}
                                    onClick={() => handleChangeFilterByPaymentStatus(bill.paymentSummary?.paymentStateCode)}
                                    title={`Найти счета по статусу оплаты ${bill.paymentSummary?.paymentStateTitle || ''}`}
                                  >
                                    <FilterListIcon color={'primary'} sx={{ fontSize: '20px' }} />
                                  </IconButton>
                                </Box>
                              )
                            ) : null}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container mb={1} justifyContent="space-between">
                        <Grid item xs={6}>
                          <Typography variant="body2" color="text.secondary">
                            ДЗ
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body2" color="text.secondary">
                            {formatValueCurPayment(bill.paymentSummary?.receivablesAmount)}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container mb={1} justifyContent="space-between">
                        <Grid item xs={6}>
                          <Typography variant="body2" color="text.secondary">
                            Оплачено {bill.paymentSummary?.debtReliefAmount > 0 && <>(включая списание долга)</>}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body2" color="text.secondary">
                            {formatValueCurPayment(bill.paymentSummary?.paidAmount)}
                          </Typography>
                        </Grid>
                      </Grid>
                      {bill.paymentSummary?.debtReliefAmount > 0 && (
                        <Grid container mb={1} justifyContent="space-between">
                          <Grid item xs={6}>
                            <Typography variant="body2" color="text.secondary">
                              Списание долга
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="body2" color="text.secondary">
                              {formatValueCurPayment(bill.paymentSummary?.debtReliefAmount)}
                            </Typography>
                          </Grid>
                        </Grid>
                      )}

                      <Grid container mb={1} justifyContent="space-between">
                        <Grid item xs={6}>
                          <Typography variant="body2" color="text.secondary">
                            Просрочено
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body2" color={bill?.paymentSummary?.overdueReceivablesAmount ? 'red' : 'text.secondary'}>
                            {formatValueCurPayment(bill?.paymentSummary?.overdueReceivablesAmount)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {!!bill.positions.length ? (
          matchesMd ? (
            <>
              <Grid mb={'20px'} container>
                {bill.positions.map((p: BillPosition, index: number) => {
                  return (
                    <Grid item key={index} xs={12}>
                      <Grid container px={2}>
                        <Grid item xs={12}>
                          <Grid container flexWrap={'nowrap'} alignItems={'center'} spacing={1}>
                            <Grid item>
                              <ChipTitleStyled label={`#${(p.position || 0) + 1}`} />
                            </Grid>
                            <Grid item>
                              <Typography component="span" style={{ fontSize: '11px' }}>
                                {`${p.product.code || ''} `}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <MxLink
                                color="inherit"
                                href={
                                  p?.warehouse?.isGroup || !p?.warehouse?.code
                                    ? `/app/product?productCode=${encodeURIComponent(p?.product?.code)}`
                                    : `/app/product?productCode=${encodeURIComponent(p?.product?.code)}&warehouseCode=${encodeURIComponent(
                                        p?.warehouse?.code
                                      )}`
                                }
                                fontSize={'14px'}
                              >
                                {`${p.product.title || ''}`}
                              </MxLink>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} container justifyContent="space-between">
                          <Grid px={1} item>
                            <Grid container flexDirection="column" alignItems="flex-end">
                              <Grid item>
                                <Typography fontSize={'12px'}>Цена с услугами</Typography>
                              </Grid>
                              <Grid item>
                                <Typography fontSize={'12px'} color="text.secondary">
                                  {!isNaN(p?.unitCostFinal)
                                    ? renderPriceCur(p?.unitCostFinal || 0, currencyDefault + '/' + p?.unitShort)
                                    : '-'}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid px={1} item>
                            <Grid container flexDirection="column" alignItems="flex-end">
                              <Grid item>
                                <Typography fontSize={'12px'}>Количество</Typography>
                              </Grid>
                              <Grid item>
                                <Typography fontSize={'12px'} color="text.secondary">
                                  {formatNumberByUnit(p.quantity || 0, p.unitCode) + ' ' + p.unitShort}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid px={1} item>
                            <Grid container flexDirection="column" alignItems="flex-end">
                              <Grid item>
                                <Typography fontSize={'12px'}>Сумма по позиции</Typography>
                              </Grid>
                              <Grid item>
                                <Typography fontSize={'12px'} color="text.secondary">
                                  {!isNaN(p?.sumCost) ? renderPriceCur(p?.sumCost) : renderPriceCur(p?.unitCost * p?.quantity)}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid px={1} item>
                            <Grid container flexDirection="column" alignItems="flex-end">
                              <Grid item>
                                <Typography fontSize={'12px'}>Услуги</Typography>
                              </Grid>
                              <Grid item>
                                <Typography fontSize={'12px'} color="text.secondary">
                                  {renderPriceCur(p?.serviceCost)}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid px={1} item sx={{ display: 'flex', alignItems: 'center' }}>
                            <Grid container flexDirection="column" alignItems="flex-end">
                              <Grid item>
                                <Typography fontSize={'12px'}>Отгружено</Typography>
                              </Grid>
                              <Grid item>
                                <Typography fontSize={'12px'} color="text.secondary">
                                  {formatNumberByUnit(p.reservationSummary?.shippedQuantity || 0, p.unitCode)} {p.baseUnitShort}
                                </Typography>
                              </Grid>
                            </Grid>

                            <Grid item ml={1}>
                              <ShippedProgress
                                currentQuantity={p.reservationSummary?.shippedQuantity}
                                baseQuantity={p.reservationSummary?.billBaseQuantity}
                              />
                            </Grid>
                            {invoicingStore ? (
                              <Grid item ml={1} minWidth={'40px'}>
                                <ButtonInvoicing billItem={bill} invoicingStore={invoicingStore} billPosition={p} />
                              </Grid>
                            ) : null}
                          </Grid>
                          <Grid px={1} item>
                            <Grid container flexDirection="column" alignItems="flex-end">
                              <Grid item>
                                <Typography fontSize={'12px'}>Остаток</Typography>
                              </Grid>
                              <Grid item>
                                <Typography fontSize={'12px'} color="text.secondary">
                                  {formatNumber3(
                                    (p.reservationSummary?.billBaseQuantity || 0) - p.reservationSummary?.shippedQuantity || 0
                                  )}{' '}
                                  {p.baseUnitShort}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        {index !== bill.positions.length - 1 && <Divider />}
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </>
          ) : (
            <Grid mb={'20px'}>
              <Grid container justifyContent="flex-end" pr={2} mb={1}>
                <CollapseButton color={'#757575'} type={expandAll.length ? 'expand-all' : 'less-all'} onClick={handleCloseAllPositions} />
              </Grid>
              {bill.positions.map((p: BillPosition, index: number) => {
                return (
                  <Accordion
                    key={index}
                    expanded={expandAll.includes(`panel${index}`)}
                    onChange={() => handleAccordionChange(`panel${index}`)}
                    sx={{
                      margin: '0px !important',
                      boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%)',
                      ':before': {
                        display: 'none',
                      },
                    }}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ borderTop: '1px solid #DBDBDB' }}>
                      <Grid container flexWrap="nowrap" justifyContent="space-between" alignItems="center">
                        <Grid item alignItems="center">
                          <Typography component="span" style={{ fontSize: '11px' }}>
                            {`${p.product.code || ''} `}
                          </Typography>
                          <Typography component="span" fontSize={'14px'}>
                            {`${p.product.title || ''}`}
                          </Typography>
                          <ProductLinkBtn productCode={p.product.code} />
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                          <Typography fontSize={'12px'}>Цена с услугами</Typography>
                        </Grid>
                        <Grid item>
                          <Typography fontSize={'12px'} color="text.secondary">
                            {!isNaN(p?.unitCostFinal) ? renderPriceCur(p?.unitCostFinal || 0, currencyDefault + '/' + p?.unitShort) : '-'}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider />
                      <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                          <Typography fontSize={'12px'}>Количество</Typography>
                        </Grid>
                        <Grid item>
                          <Typography fontSize={'12px'} color="text.secondary">
                            {formatNumberByUnit(p.quantity || 0, p.unitCode) + ' ' + p.unitShort}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider />
                      <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                          <Typography fontSize={'12px'}>Сумма по позиции</Typography>
                        </Grid>
                        <Grid item>
                          <Typography fontSize={'12px'} color="text.secondary">
                            {!isNaN(p?.sumCost) ? renderPriceCur(p?.sumCost) : renderPriceCur(p?.unitCost * p?.quantity)}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider />
                      <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                          <Typography fontSize={'12px'}>Услуги</Typography>
                        </Grid>
                        <Grid item>
                          <Typography fontSize={'12px'} color="text.secondary">
                            {renderPriceCur(p?.serviceCost)}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Divider />
                      <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                          <Typography fontSize={'12px'}>Отгружено</Typography>
                        </Grid>
                        <Grid item>
                          <Typography fontSize={'12px'} color="text.secondary">
                            {formatNumberByUnit(p.reservationSummary?.shippedQuantity || 0, p.unitCode)} {p.baseUnitShort}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider />
                      <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                          <Typography fontSize={'12px'}>% отгрузки</Typography>
                        </Grid>
                        <Grid item>
                          <ShippedProgress
                            currentQuantity={p.reservationSummary?.shippedQuantity}
                            baseQuantity={p.reservationSummary?.billBaseQuantity}
                          />
                        </Grid>
                      </Grid>
                      <Divider />
                      <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                          <Typography fontSize={'12px'}>Остаток</Typography>
                        </Grid>
                        <Grid item>
                          <Typography fontSize={'12px'} color="text.secondary">
                            {formatNumber3((p.reservationSummary?.billBaseQuantity || 0) - p.reservationSummary?.shippedQuantity || 0)}{' '}
                            {p.baseUnitShort}
                          </Typography>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Grid>
          )
        ) : (
          <Grid item xs={12} container>
            <Grid item xs={12} mb={1}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'body2'}>Без позиций</Typography>
            </Grid>
          </Grid>
        )}
      </BillStyledPaper>
    );
  }
);
