import { Dialog, DialogTitle, IconButton } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';

type ClientPowersOfAttorneyPdfPreviewDialogProps = {
  open: boolean;
  onClose: () => void;
  printFormUrl: string;
  fileName: string;
};

export const ClientPowersOfAttorneyPdfPreviewDialog: FC<ClientPowersOfAttorneyPdfPreviewDialogProps> = observer(props => {
  const { onClose, open, fileName, printFormUrl } = props;
  const [blobPdfUrl, setBlobPdfUrl] = useState('');

  useEffect(() => {
    const fetchPdfData = async (): Promise<void> => {
      try {
        const response = await fetch(printFormUrl);
        const blob = await response.blob();
        const url = URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }));
        setBlobPdfUrl(url);
      } catch (error) {
        console.error('Error fetching PDF:', error);
      }
    };

    fetchPdfData();

    return () => {
      if (blobPdfUrl) {
        URL.revokeObjectURL(blobPdfUrl);
      }
    };
  }, []);

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ sx: { width: '70vw', height: '100%', '&.MuiDialog-paper': { maxWidth: '100%' } } }}>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        {fileName}{' '}
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      {blobPdfUrl && <embed src={blobPdfUrl} type="application/pdf" width="100%" height="100%" />}
    </Dialog>
  );
});
