import React, { FC, JSX, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { BillItemStore } from '../../store/Documents/BillItemStore';
import { Address, ParticipantPartner, Subdivision } from '../../api/marketx';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import NearMeTwoToneIcon from '@mui/icons-material/NearMeTwoTone';
import HubRoundedIcon from '@mui/icons-material/HubRounded';
import { Link as MxLink } from '@mx-ui/ui';
import { BillSummaryTabHeader } from './BillSummaryTabHeader';
interface BillSummaryPartnersForMxProps {
  billStore: BillItemStore;
  minHeightForTabSections?: string;
  isDialogOpen?: boolean;
}

export const BillSummaryPartnersForMx: FC<BillSummaryPartnersForMxProps> = observer(
  ({ billStore, minHeightForTabSections, isDialogOpen }): JSX.Element => {
    const bill = billStore.item;
    const deal = billStore.dealStore.deal;
    const [consigneeValue, setConsigneeValue] = useState(bill.partners.consignee);
    const [addressValue, setAddressValue] = useState(bill.partners.consignee?.address);
    const [payerValue, setPayerValue] = useState(bill.partners.payer);
    const [subdivisionValue, setSubdivisionValue] = useState<Subdivision | null>(null);
    const isEditing = billStore.item.editingPermissions.canEditPartners && billStore.isEditing && !billStore.isSaving;
    const isEditingSubdivision = billStore.item.editingPermissions.canEditCompanySubdivision && billStore.isEditing && !billStore.isSaving;

    const billPartnersStore = billStore.billPartnersStore;

    useEffect(() => {
      if (billPartnersStore && bill && !billPartnersStore.billCode) {
        billPartnersStore.loadListForBill(bill);
      }
    }, [bill, billPartnersStore]);

    useEffect(() => {
      if (billPartnersStore.subdivisionsList && bill.companySubdivisionCode) {
        !billPartnersStore.subdivisionsList.some(subdivision => subdivision.code === bill.companySubdivisionCode) &&
          billPartnersStore.subdivisionsList.push({ code: bill.companySubdivisionCode, name: bill.companySubdivisionName });
        const initialSubdivision = billPartnersStore.subdivisionsList.filter(
          subdivision => subdivision.code === bill.companySubdivisionCode
        );

        setSubdivisionValue(initialSubdivision && initialSubdivision[0]);
      } else {
        setSubdivisionValue(null);
      }
    }, [billPartnersStore.subdivisionsList, bill.companySubdivisionCode]);

    const handleConsigneesChange = (newConsignee: ParticipantPartner): void => {
      if (newConsignee.code === consigneeValue?.code) {
        return;
      }
      billPartnersStore.reloadAddressesList(newConsignee.customerCode).then(() => {
        if (billPartnersStore.addressesList?.length === 1) {
          setAddressValue(billPartnersStore.addressesList[0]);
          updateBillPartners(newConsignee, payerValue, billPartnersStore?.addressesList?.[0]);
        } else {
          setAddressValue(null);
          updateBillPartners(newConsignee, payerValue);
        }
        setConsigneeValue(newConsignee);
      });
    };
    const handleAddressChange = (newAddress: Address): void => {
      if (newAddress.code === addressValue?.code) {
        return;
      }
      setAddressValue(newAddress);
      updateBillPartners(consigneeValue, payerValue, newAddress);
    };
    const handlePayerChange = (newPayer: ParticipantPartner): void => {
      if (newPayer.code === payerValue?.code) {
        return;
      }
      setPayerValue(newPayer);
      updateBillPartners(consigneeValue, newPayer, addressValue);
    };

    const updateBillPartners = (consignee: ParticipantPartner, payer: ParticipantPartner, addressV?: Address): void => {
      billStore.updateBill({
        partners: [
          { participantCode: consignee?.code, type: 'consignee', addressCode: addressV?.code },
          { participantCode: payer?.code, type: 'payer' },
        ],
      });
    };

    const handleSubdivisionsChange = (subdivision: Subdivision): void => {
      if (subdivision.code === subdivisionValue.code) {
        return;
      }
      setSubdivisionValue(subdivision);
      billStore.updateBill({ companySubdivisionCode: subdivision.code }).catch(e => console.warn('Не удалось изменить подразделение', e));
    };

    const mapBtnTitle =
      deal?.warehouseAddress?.latitude && addressValue?.latitude ? 'Показать маршрут' : addressValue?.latitude ? 'Показать на карте' : '';
    const mapBtnUrl =
      deal?.warehouseAddress?.latitude && addressValue?.latitude
        ? 'https://yandex.ru/maps/?rtext=' +
          deal?.warehouseAddress?.latitude +
          ',' +
          deal?.warehouseAddress?.longitude +
          '~' +
          addressValue?.latitude +
          ',' +
          addressValue?.longitude +
          '&rtt=auto'
        : addressValue?.latitude
        ? addressValue?.latitude + ',' + addressValue?.longitude + '&rtt=auto'
        : '';

    useEffect(() => {
      if (!billStore.isEditing) {
        if (consigneeValue?.code !== bill.partners?.consignee?.code) {
          setConsigneeValue(bill.partners.consignee);
        }
        if (addressValue?.code !== bill.partners?.consignee?.address?.code) {
          setAddressValue(bill.partners.consignee?.address);
        }
        if (payerValue?.code !== bill.partners?.payer?.code) {
          setPayerValue(bill.partners.payer);
        }
      }
    }, [billStore.isEditing, bill.partners?.consignee?.code, bill.partners?.consignee?.address?.code, bill.partners?.payer?.code]);

    return (
      <Grid container spacing={0} minHeight={minHeightForTabSections}>
        <Grid container flexDirection={'column'} sx={{ maxHeight: '100%', overflow: 'hidden' }}>
          <BillSummaryTabHeader Icon={HubRoundedIcon} billItemStore={billStore} isDialogOpen={isDialogOpen} dividerMb={2} />
          <Grid item mb={2} mt={1}>
            <Grid container alignItems={'center'}>
              <Grid item flex={'1 1 auto'}>
                <Autocomplete
                  value={consigneeValue || null}
                  onChange={(_, v) => handleConsigneesChange(v)}
                  disablePortal
                  getOptionLabel={i => i.title}
                  isOptionEqualToValue={(option, value) => option.code === value.code}
                  disableClearable
                  size="small"
                  options={billPartnersStore?.consigneesList || []}
                  renderInput={params => <TextField {...params} label="Грузополучатель" />}
                  disabled={!isEditing}
                  title={consigneeValue?.title || ''}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.code}>
                        {option.title}
                      </li>
                    );
                  }}
                />
              </Grid>
              <Grid item>
                <Box ml={'4px'} mt={'-1px'}>
                  <MxLink href={`/app/clients/${encodeURIComponent(consigneeValue?.customerCode)}`}>
                    <IconButton size="small" title="Перейти к грузополучателю" disabled={!consigneeValue?.code}>
                      <SearchRoundedIcon />
                    </IconButton>
                  </MxLink>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item mb={2}>
            <Grid container alignItems={'center'}>
              <Grid item flex={'1 1 auto'}>
                <Autocomplete
                  getOptionKey={option => option.code}
                  value={addressValue || null}
                  onChange={(_, v) => handleAddressChange(v)}
                  disablePortal
                  getOptionLabel={i => i.addressFull}
                  isOptionEqualToValue={(option, value) => option.code === value.code}
                  disableClearable
                  size="small"
                  options={billPartnersStore?.addressesList || []}
                  renderInput={params => <TextField {...params} label="Адрес" />}
                  title={addressValue?.addressFull || ''}
                  disabled={!isEditing}
                />
              </Grid>
              <Grid item>
                <Grid container>
                  <Box ml={'4px'} mt={'-1px'}>
                    {addressValue ? (
                      <MxLink href={mapBtnUrl} target="_blank">
                        <IconButton size="small" title={mapBtnTitle} disabled={!payerValue?.code}>
                          <NearMeTwoToneIcon />
                        </IconButton>
                      </MxLink>
                    ) : (
                      <IconButton size="small" title={'Выберете адрес для отображения маршрута'} disabled>
                        <NearMeTwoToneIcon />
                      </IconButton>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item mb={2}>
            <Grid container alignItems={'center'}>
              <Grid item flex={'1 1 auto'}>
                <Autocomplete
                  value={payerValue || null}
                  onChange={(_, v) => handlePayerChange(v)}
                  disablePortal
                  getOptionLabel={i => i.title}
                  isOptionEqualToValue={(option, value) => option.code === value.code}
                  disableClearable
                  size="small"
                  title={payerValue?.title || ''}
                  options={billPartnersStore?.payersList || []}
                  renderInput={params => <TextField {...params} label="Плательщик" />}
                  disabled={!isEditing}
                />
              </Grid>
              <Grid item>
                <Box ml={'4px'} mt={'-1px'}>
                  <MxLink href={`/app/clients/${encodeURIComponent(payerValue?.customerCode)}`}>
                    <IconButton size="small" title="Перейти к плательщику" disabled={!payerValue?.code}>
                      <SearchRoundedIcon />
                    </IconButton>
                  </MxLink>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item mb={2}>
            <Grid container alignItems={'center'}>
              <Grid item flex={'1 1 auto'}>
                <Autocomplete
                  value={subdivisionValue || null}
                  onChange={(_, subdivision) => handleSubdivisionsChange(subdivision)}
                  disablePortal
                  getOptionLabel={i => i.name}
                  isOptionEqualToValue={(option, value) => option.code === value.code}
                  disableClearable
                  size="small"
                  title={subdivisionValue?.name || ''}
                  options={billPartnersStore.subdivisionsList || []}
                  renderInput={params => <TextField {...params} label="Подразделение" />}
                  disabled={!isEditingSubdivision}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
);
