import { RootStore } from '../StoreManager';
import { ApiStore, AxiosCallContext, getCallContext } from '../Global/ApiStore';
import { makeAutoObservable, runInAction, set, toJS } from 'mobx';
import {
  ClientDocFlowState,
  ClientDocFlowStateCodeEnum,
  ClientDocFlowStatesResponse,
  ClientsForFilterResponse,
  CustomerAsFilter,
  DocFlowExchangeState,
  DocFlowExchangeStateListResponse,
  Employee,
  PaymentState,
  PaymentStatesResponse,
  SaleShipment,
  SaleShipmentType,
  ShipmentsResponse,
  ShipmentsTypesResponse,
  WarehousesForFilterResponse,
} from '../../api/marketx';
import { formatDateSwagger } from '@mx-ui/helpers';
import { setClear } from 'src/utils/mobx';
import { RouterStore } from '../RouterStore';
import { dateEnd, dateStart, defaultQuickDate } from './utils/defaultQuickDate';
import { quickDateRanges, RangeVariant } from 'src/components/SelectDateRange/MenuButtonNew';
import { AutocompleteValue } from '../../components/Clients/ClientDocuments/Hooks/useClientDocumentsSelect';
import { TotalStoreEntity, totalStoreGenerate } from 'src/components/Documents/DocumentTotalCount';
import { MyApprovalsAskingStore } from '../Deals/MyApprovalsAsking';
import { DealListRequest } from '../DealListStore';
import { AppDealDistributor } from '../../slices/AppDeal';
import equal from '@wry/equality';
const defaultPageSize = 12;
const defaultWarehousesPageSize = 100;
const defaultCustomerFilterCount = 100;

export class ShipmentsListRequest {
  customerCode?: string;
  query?: string;
  queryCustomer?: string;
  queryWarehouses?: string;
  dealCode?: string;
  employeeSetCode?: string; // Фильтр по реализатору
  customerCodes?: string[];
  recipientCodes?: string[];

  page?: number;
  officeCode?: string;
  officeCodes?: string[];
  paymentStateCodes?: string[];
  warehousesCodes?: string[];
  clientCode?: string;
  quickRange?: string;
  shipmentTypeCode?: string;
  count?: number;
  dateFrom?: Date;
  dateTo?: Date;
  customerFilterPage?: number;
  customerFilterCount?: number;
  hasEmployeeDeputy?: boolean;
  docFlowStateCodes?: ClientDocFlowStateCodeEnum[];
  docFlowExchangeStateCodes?: string[];
}
export class ShipmentsListStore {
  apiStore?: ApiStore;
  routerStore: RouterStore;
  distributorsStore: MyApprovalsAskingStore;
  public isLoading = true;
  public isStatsLoading = true;
  public isFilterCustomerLoading = true;
  public isFilterRecipientLoading = true;
  public isWarehousesLoading = false;
  quickRange: RangeVariant = defaultQuickDate;

  requestInit: ShipmentsListRequest = {};
  public isLoaded = false;
  public isMoreLoading = false;
  public routerControlEnabled = false;

  request: ShipmentsListRequest = {
    count: defaultPageSize,
    query: '',
    dateFrom: dateStart,
    dateTo: dateEnd,
  };

  public hasMore = false;
  public ignoreBeforeDate?: Date;

  public items = new Array<SaleShipment>();
  public filterBranchOffices: AutocompleteValue[] = [];
  public documentsCustomersFilterList: CustomerAsFilter[] = [];
  public recipientFilterList: CustomerAsFilter[] = [];
  public shipmentTypes: SaleShipmentType[] = [];
  public warehousesFilterList: SaleShipmentType[] = [];
  public billPaymentStates: PaymentState[] = [];
  public initialBranchOffice: AutocompleteValue = null;
  isInitialLoading = true;
  totalStoreEntity: TotalStoreEntity = null;
  clientDocFlowStatesList: ClientDocFlowState[] = [];
  docFlowExchangeStatesList: DocFlowExchangeState[] = [];

  constructor(rootStore: RootStore) {
    this.apiStore = rootStore.getApiStore();
    this.routerStore = rootStore.getRouter();
    this.loadMore = this.loadMore.bind(this);
    this.loadMoreForClient = this.loadMoreForClient.bind(this);

    makeAutoObservable(this, {
      apiStore: false,
    });
  }

  loadClientDocFlowStates(): void {
    this.apiStore
      .apiClientCustomer()
      .clientDocFlowStates()
      .then(res => {
        this.setClientDocFlowStates(res.data);
      })
      .catch(e => {
        console.warn('loadClientDocFlowStatesReq', e);
      });
  }

  loadDocFlowExchangeStates(): void {
    this.apiStore
      .apiDocFlow()
      .docFlowExchangeStateList()
      .then(res => this.setDocFlowExchangeStates(res.data))
      .catch(e => console.warn('loadDocFlowExchangeStatesReq', e));
  }

  setClientDocFlowStates(data: ClientDocFlowStatesResponse): void {
    this.clientDocFlowStatesList = data.states || [];
  }

  setDocFlowExchangeStates(data: DocFlowExchangeStateListResponse): void {
    this.docFlowExchangeStatesList = data.states || [];
  }

  employeeSetChange(currentRole: string, emp?: Employee): void {
    if (!emp) {
      this.mergeRequest({ employeeSetCode: currentRole });
      return;
    }
    let newVal = '';
    if (!this.distributorsStore.employees?.length) {
      return;
    }
    for (let i = 0; i < this.distributorsStore.employees?.length; i++) {
      if (this.distributorsStore.employees[i]?.code === emp?.code || this.distributorsStore.employees[i]?.employee?.code === emp?.code) {
        newVal = this.distributorsStore.employees[i]?.code;
        break;
      }
    }
    if (!newVal) {
      newVal = `~emp~2~${emp?.code}`;
    }
    runInAction(() => {
      const oldVal = this.request.employeeSetCode || null;
      if (newVal !== oldVal) {
        this.employeeSetMergeRequest({ employeeSetCode: newVal }, emp);
      }
    });
  }
  loadMyApprovalsAskingStore(rootStore: RootStore, initEmployee?: string): void {
    this.distributorsStore = new MyApprovalsAskingStore(rootStore);
    this.distributorsStore.loadMyList(initEmployee);
  }
  employeeSetMergeRequest(req: DealListRequest, emp: AppDealDistributor): void {
    this.distributorsStore.loadMyList(emp.employeeCode);
    this.mergeRequest(req);
  }
  loadShipmentTypeCodes(): void {
    this.apiStore
      .apiShipments()
      .shipmentsTypes()
      .then(res => {
        this.setShipmentTypeCodesResult(res.data);
      })
      .catch(err => {
        console.warn('loadShipmentTypeCodesRequest', err);
      });
  }
  loadWarehousesForFilter(queryCustomer?: string): void {
    if (queryCustomer) {
      this.request.queryWarehouses = queryCustomer;
    } else {
      this.request.queryWarehouses = undefined;
    }
    this.loadWarehouses();
  }
  loadWarehouses(r?: ShipmentsListRequest): void {
    this.ignoreBeforeDate = new Date();
    runInAction(() => {
      this.isWarehousesLoading = true;
    });
    const req = toJS(r || this.request);
    this.apiStore
      .apiWarehousesForFilter()
      .warehousesForFilter(
        'shipment',
        req.warehousesCodes?.length ? req.warehousesCodes : undefined,
        this.isInitialLoading && !req.officeCodes?.length ? undefined : req.officeCodes,
        undefined,
        undefined,
        undefined,
        req.clientCode || undefined,
        req.dealCode || undefined,
        req.paymentStateCodes?.length ? req.paymentStateCodes : undefined,
        req.shipmentTypeCode || undefined,
        req.dateFrom ? formatDateSwagger(req.dateFrom) : undefined,
        req.dateTo ? formatDateSwagger(req.dateTo) : undefined,
        req.employeeSetCode || undefined,
        req.docFlowStateCodes?.length ? req.docFlowStateCodes : undefined,
        req.query || undefined,
        1,
        defaultWarehousesPageSize,
        req.queryWarehouses || undefined
      )
      .then(res => {
        this.setWarehousesResult(res.data);
      })
      .catch(err => {
        console.warn('loadWarehousesRequest', err);
      });
  }
  loadCustomersForFilter(queryCustomer?: string, isRecipientCustomers?: boolean): void {
    if (queryCustomer) {
      this.request.queryCustomer = queryCustomer;
    } else {
      this.request.queryCustomer = undefined;
    }
    if (this.routerControlEnabled) {
      this.loadFilterCustomerCodes();
      isRecipientCustomers && this.loadFilterCustomerCodes(true);
    }
  }

  loadFilterCustomerCodes(isRecipientCustomers?: boolean): void {
    runInAction(() => {
      !isRecipientCustomers ? (this.isFilterCustomerLoading = true) : (this.isFilterRecipientLoading = true);
    });
    const req = Object.assign({}, this.request);
    req.customerFilterPage = req.customerFilterPage > 1 ? req.customerFilterPage : undefined;
    req.customerFilterCount = req.customerFilterCount || defaultCustomerFilterCount;
    this.apiStore
      .apiShipments()
      .shipmentClientsForFilter({
        hasEmployeeDeputy: !!req.hasEmployeeDeputy,
        requiredCustomerCodes: req.customerCodes?.length ? req.customerCodes : req.recipientCodes?.length ? req.recipientCodes : undefined,
        dateFrom: req.dateFrom ? formatDateSwagger(req.dateFrom) : undefined,
        dateTo: req.dateTo ? formatDateSwagger(req.dateTo) : undefined,
        employeeSet: req.employeeSetCode || undefined,
        paymentStateCodes: req.paymentStateCodes?.length ? req.paymentStateCodes : undefined,
        queryDocuments: req.query || undefined,
        shipmentTypeCode: req.shipmentTypeCode || undefined,
        warehousesCodes: req.warehousesCodes?.length ? req.warehousesCodes : undefined,
        page: req.customerFilterPage || undefined,
        count: req.customerFilterCount,
        queryCustomers: req.queryCustomer || undefined,
        docFlowStateCodes: req.docFlowStateCodes?.length ? req.docFlowStateCodes : undefined,
        docFlowExchangeStateCodes: req.docFlowExchangeStateCodes?.length ? req.docFlowExchangeStateCodes : undefined,
        isPayerCustomers: isRecipientCustomers,
      })
      .then(res => {
        !isRecipientCustomers ? this.setFilterCustomerResult(res.data) : this.setFilterRecipientResult(res.data);
      })
      .catch(e => {
        console.warn('loadFilterCustomerCodes req', e);
        runInAction(() => {
          !isRecipientCustomers ? (this.isFilterCustomerLoading = false) : (this.isFilterRecipientLoading = false);
        });
      });
  }

  setFilterCustomerResult(statuses: ClientsForFilterResponse): void {
    if (statuses.customers?.length) {
      const prevCheckedCustomers = [];

      this.documentsCustomersFilterList.forEach(prevI => {
        if (this.request.customerCodes.includes(prevI.code) && !statuses.customers.find(i => i.code === prevI.code)) {
          prevCheckedCustomers.push(prevI);
        }
      });
      if (this.request?.customerCodes?.length) {
        this.documentsCustomersFilterList = [...prevCheckedCustomers, ...statuses.customers];
      } else {
        this.documentsCustomersFilterList = [...statuses.customers];
      }
    }
    runInAction(() => {
      this.isFilterCustomerLoading = false;
    });
  }

  setFilterRecipientResult(recipients: ClientsForFilterResponse): void {
    if (recipients.customers?.length) {
      const prevCheckedPayers = [];
      this.recipientFilterList.forEach(prevI => {
        if (this.request.recipientCodes.includes(prevI.code) && !recipients.customers.find(i => i.code === prevI.code)) {
          prevCheckedPayers.push(prevI);
        }
      });

      if (this.request?.recipientCodes?.length) {
        this.recipientFilterList = [...prevCheckedPayers, ...recipients.customers];
      } else {
        this.recipientFilterList = [...recipients.customers];
      }
    }
    runInAction(() => {
      this.isFilterRecipientLoading = false;
    });
  }

  setShipmentTypeCodesResult(data: ShipmentsTypesResponse): void {
    this.shipmentTypes = data.shipmentTypes;
  }
  setWarehousesResult(data: WarehousesForFilterResponse): void {
    const prevCheckedCustomers = [];
    this.warehousesFilterList?.forEach(prevI => {
      if (this.request.warehousesCodes?.includes(prevI.code) && !data.warehouses.find(i => i.code === prevI.code)) {
        prevCheckedCustomers.push(prevI);
      }
    });
    if (data.warehouses?.length) {
      this.warehousesFilterList = [...prevCheckedCustomers, ...data.warehouses];
    }
    this.warehousesFilterList = data.warehouses;
    runInAction(() => {
      this.isWarehousesLoading = false;
    });
  }
  loadBillPaymentStates(): void {
    this.apiStore
      .apiClientPayment()
      .paymentStates()
      .then(res => {
        this.setBillPaymentStates(res.data);
      })
      .catch(e => {
        console.warn('loadDealStatuses', e);
      });
  }
  setBillPaymentStates(data: PaymentStatesResponse): void {
    this.billPaymentStates = data.paymentStates || [];
  }
  mergeRequest(req: ShipmentsListRequest): void {
    set(this.request, req);
    this.request.page = undefined;
    this.isLoaded = false;
    this.isMoreLoading = false;
    this.isLoading = true;
    this.isStatsLoading = true;
    this.actualizeRouter(toJS(this.request));
    this.refresh();
  }
  changeQuickRange(v: RangeVariant): void {
    this.quickRange = v;
    set(this.request, { quickRange: v.value });
  }
  actualizeRouter(req: ShipmentsListRequest): void {
    const reqInit = toJS(this.requestInit);
    if (!this.routerControlEnabled) {
      return;
    }
    const params = new URLSearchParams();

    if (req.query) {
      params.set('query', req.query);
    }
    if (req.quickRange) {
      params.set('quickRange', req.quickRange);
    }
    if (req.dateFrom) {
      params.set('dateFrom', formatDateSwagger(req.dateFrom));
    }
    if (req.dateTo) {
      params.set('dateTo', formatDateSwagger(req.dateTo));
    }
    if (req.shipmentTypeCode) {
      params.set('shipmentTypeCode', req.shipmentTypeCode);
    }
    if (req.paymentStateCodes) {
      params.set('paymentStateCodes', `${req.paymentStateCodes.join(',')}`);
    }
    if (req.docFlowStateCodes) {
      params.set('docFlowStateCodes', `${req.docFlowStateCodes.join(',')}`);
    }
    if (req.warehousesCodes) {
      params.set('warehousesCodes', `${req.warehousesCodes.join(',')}`);
    }
    if (req.employeeSetCode && req.employeeSetCode !== reqInit.employeeSetCode) {
      params.set('employeeSet', req.employeeSetCode);
    }
    if (req.customerCodes) {
      params.set('customerCodes', `${req.customerCodes.join(',')}`);
    }
    if (req.recipientCodes) {
      params.set('recipientCodes', `${req.recipientCodes.join(',')}`);
    }
    if (req.hasEmployeeDeputy) {
      params.set('hasEmployeeDeputy', `${req.hasEmployeeDeputy}`);
    }
    if (req.docFlowExchangeStateCodes) {
      params.set('docFlowExchangeStateCodes', `${req.docFlowExchangeStateCodes.join(',')}`);
    }

    // params.set('csid', String(this.storeIdentifier));
    let paramsStr = params.toString();
    if (paramsStr) {
      paramsStr = '?' + paramsStr;
    }
    let url = '/app/shipments';
    url += paramsStr;
    this.routerStore.replace(url, undefined, { shallow: true });
  }
  refresh(): void {
    this.loadList(this.request);
  }

  setRouterControl(enabled: boolean): void {
    this.routerControlEnabled = enabled;
  }
  setRequestInit(req: ShipmentsListRequest): void {
    setClear(this.requestInit, req);
  }
  loadList(r: ShipmentsListRequest): Promise<void> {
    this.ignoreBeforeDate = new Date();
    this.isLoading = true;
    set(this.request, r);
    const req = toJS(this.request);
    req.count = req.count || defaultPageSize;
    runInAction(() => {
      if (req.quickRange) {
        this.quickRange = quickDateRanges.find(t => t.value === req.quickRange);
      }
    });
    return this.apiStore
      .apiShipments()
      .shipments({
        hasEmployeeDeputy: !!req.hasEmployeeDeputy,
        shipmentTypeCode: req.shipmentTypeCode || undefined,
        employeeSet: req.employeeSetCode,
        query: req.query || undefined,
        customerCode: req.clientCode,
        customerCodes: req.customerCodes?.length ? req.customerCodes : undefined,
        dealCode: req.dealCode,
        page: req.page || undefined,
        count: req.count,
        dateFrom: req.dateFrom ? formatDateSwagger(req.dateFrom) : undefined,
        dateTo: req.dateTo ? formatDateSwagger(req.dateTo) : undefined,
        paymentStateCodes: req.paymentStateCodes?.length ? req.paymentStateCodes : undefined,
        warehousesCodes: req.warehousesCodes?.length ? req.warehousesCodes : undefined,
        docFlowStateCodes: req.docFlowStateCodes?.length ? req.docFlowStateCodes : undefined,
        docFlowExchangeStateCodes: req.docFlowExchangeStateCodes?.length ? req.docFlowExchangeStateCodes : undefined,
        isBranchOfficesRequired: false,
        payerCodes: req.recipientCodes?.length ? req.recipientCodes : undefined,
      })
      .then((res): void => {
        this.loadListResponse(getCallContext(res), req, res.data);
        this.loadShipmentsStats();
      });
  }
  loadShipmentsStats(): Promise<void> {
    this.ignoreBeforeDate = new Date();
    this.isStatsLoading = true;
    const req = toJS(this.request);
    req.count = req.count || defaultPageSize;
    runInAction(() => {
      if (req.quickRange) {
        this.quickRange = quickDateRanges.find(t => t.value === req.quickRange);
      }
    });

    return this.apiStore
      .apiShipments()
      .shipmentsStats({
        hasEmployeeDeputy: !!req.hasEmployeeDeputy,
        shipmentTypeCode: req.shipmentTypeCode || undefined,
        employeeSet: req.employeeSetCode,
        query: req.query || undefined,
        customerCode: req.clientCode,
        customerCodes: req.customerCodes?.length ? req.customerCodes : undefined,
        dealCode: req.dealCode,
        page: req.page || undefined,
        count: req.count,
        dateFrom: req.dateFrom ? formatDateSwagger(req.dateFrom) : undefined,
        dateTo: req.dateTo ? formatDateSwagger(req.dateTo) : undefined,
        paymentStateCodes: req.paymentStateCodes?.length ? req.paymentStateCodes : undefined,
        warehousesCodes: req.warehousesCodes?.length ? req.warehousesCodes : undefined,
        docFlowStateCodes: req.docFlowStateCodes?.length ? req.docFlowStateCodes : undefined,
        docFlowExchangeStateCodes: req.docFlowExchangeStateCodes?.length ? req.docFlowExchangeStateCodes : undefined,
        payerCodes: req.recipientCodes?.length ? req.recipientCodes : undefined,
      })
      .then((res): void => {
        runInAction(
          () => (this.totalStoreEntity = totalStoreGenerate(res.data.totalCount || 0, res.data.totalWeight || 0, res.data.totalCost || 0))
        );
      })
      .finally(() => runInAction(() => (this.isStatsLoading = false)));
  }
  loadListForeDeal(r: ShipmentsListRequest): Promise<void> {
    this.ignoreBeforeDate = new Date();
    this.isLoading = true;
    set(this.request, r);
    const req = toJS(this.request);
    req.count = undefined;
    // req.count = req.count || defaultPageSize;
    runInAction(() => {
      if (req.quickRange) {
        this.quickRange = quickDateRanges.find(t => t.value === req.quickRange);
      }
    });
    return this.apiStore
      .apiShipments()
      .shipments({
        employeeSet: req.employeeSetCode,
        query: req.query || undefined,
        customerCode: req.clientCode,
        dealCode: req.dealCode,
        page: req.page || undefined,
        count: req.count,
        dateFrom: undefined,
        dateTo: undefined,
        paymentStateCodes: req.paymentStateCodes?.length ? req.paymentStateCodes : undefined,
        warehousesCodes: req.warehousesCodes?.length ? req.warehousesCodes : undefined,
        docFlowStateCodes: req.docFlowStateCodes?.length ? req.docFlowStateCodes : undefined,
        isBranchOfficesRequired: false,
      })
      .then((res): void => {
        this.loadListResponse(getCallContext(res), req, res.data);
      });
  }
  loadListResponse(ctx: AxiosCallContext, req: ShipmentsListRequest, res: ShipmentsResponse): void {
    if (this.ignoreBeforeDate && this.ignoreBeforeDate.getTime() > ctx.startTime.getTime()) {
      console.log('ignore irrelevant consignee list response');
      return;
    }
    this.ignoreBeforeDate = ctx.startTime;
    if (this.isMoreLoading) {
      this.isMoreLoading = false;
      this.items.push(...res.shipments);
    } else {
      this.items = res.shipments;
    }

    this.isLoading = false;
    this.isLoaded = true;
    this.hasMore = res.shipments.length >= req.count;

    this.loadCustomersForFilter(undefined, true);
    this.loadWarehouses(req);
  }

  loadMore(): void {
    if (this.isLoading) {
      // уже загружается
      return;
    }
    this.isMoreLoading = true;
    this.request.page = (this.request.page || 1) + 1;
    this.loadList(this.request);
  }

  // ↓ методы для загрузки счетов со страницы клиента (документы)
  setInitialOffice(code: string, name: string): void {
    this.initialBranchOffice = { code, name };
  }

  resetItems(): void {
    runInAction(() => {
      this.items = [];
    });
  }
  loadMoreForClient(officesItems: AutocompleteValue[]): void {
    if (this.isLoading) {
      // уже загружается
      return;
    }
    runInAction(() => {
      this.isMoreLoading = true;
      this.request.officeCodes = officesItems.some(i => i.code === 'selectAll')
        ? this.filterBranchOffices.filter(i => i.code !== 'selectAll').map(i => i.code)
        : officesItems.map(i => i.code);
      this.request.page = (this.request.page || 1) + 1;
    });
    this.loadFromClientDocuments(this.request);
  }
  mergeRequestToClient(req: ShipmentsListRequest): void {
    const isRequestEqual = equal(this.request, { ...this.request, ...req });
    if (isRequestEqual) {
      return;
    }
    set(this.request, req);
    runInAction(() => {
      this.request.page = undefined;
      this.isMoreLoading = false;
      this.isLoading = true;
      this.isStatsLoading = true;
    });
    this.loadFromClientDocuments(this.request);
  }
  loadFromClientDocuments(r: ShipmentsListRequest): void {
    this.isLoading = true;
    set(this.request, r);
    const req = toJS(this.request);
    req.count = req.count || defaultPageSize;
    this.apiStore
      .apiShipments()
      .shipments({
        hasEmployeeDeputy: !!req.hasEmployeeDeputy,
        shipmentTypeCode: req.shipmentTypeCode || undefined,
        branchOfficeCodes: this.isInitialLoading && !req.officeCodes?.length ? undefined : req.officeCodes,
        customerCode: req.clientCode,
        page: req.page || undefined,
        count: req.count,
        dateFrom: req.dateFrom ? formatDateSwagger(req.dateFrom) : undefined,
        dateTo: req.dateTo ? formatDateSwagger(req.dateTo) : undefined,
        paymentStateCodes: req.paymentStateCodes?.length ? req.paymentStateCodes : undefined,
        warehousesCodes: req.warehousesCodes?.length ? req.warehousesCodes : undefined,
        docFlowStateCodes: req.docFlowStateCodes?.length ? req.docFlowStateCodes : undefined,
        docFlowExchangeStateCodes: req.docFlowExchangeStateCodes?.length ? req.docFlowExchangeStateCodes : undefined,
      })
      .then((res): void => {
        this.loadForClientResult(req, res.data);
        this.loadShipmentsStats();
      });
  }
  loadForClientResult(req: ShipmentsListRequest, res: ShipmentsResponse): void {
    if (this.isMoreLoading) {
      this.isMoreLoading = false;
      this.items.push(...res.shipments);
      if (!this.filterBranchOffices.length) {
        this.filterBranchOffices = (res.shipmentsBranchOffices as AutocompleteValue[]) || [];
      }
    } else {
      this.items = res.shipments;
      this.filterBranchOffices = (res.shipmentsBranchOffices as AutocompleteValue[]) || [];
    }
    if (!this.filterBranchOffices.some(item => item.code === this.initialBranchOffice.code)) {
      this.filterBranchOffices.push(this.initialBranchOffice);
    }
    if (this.filterBranchOffices.length > 1) {
      this.filterBranchOffices = [{ code: 'selectAll', name: 'Все филиалы' }, ...this.filterBranchOffices];
    }

    this.isInitialLoading = false;
    this.isLoading = false;
    this.isLoaded = true;
    this.hasMore = res.shipments.length >= req.count;
    this.loadWarehouses(req);
  }
}
