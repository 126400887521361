import React, { FC, JSX } from 'react';
import { observer } from 'mobx-react-lite';
import Grid from '@mui/material/Grid';
import { Link as MxLink } from '@mx-ui/ui';
import Typography from '@mui/material/Typography';
import { formatDateSwagger, formatDateSwaggerZ } from '@mx-ui/helpers';
import Divider from '@mui/material/Divider';
import { ThreadLink } from '../../Bills/ThreadLink';
import Paper from '@mui/material/Paper';
import { formatValueCurPayment } from './PaymentTabPanelClients';
import { CustomersPaymentStatType } from '../../../store/EmployeePaymentStore';

interface PaymentTabPanelClientItemProps {
  customerCode: string;
  customersPaymentStatList: CustomersPaymentStatType;
  billsKeys: string[];
  forClient?: boolean;
}

export const PaymentTabPanelClientItem: FC<PaymentTabPanelClientItemProps> = observer(
  ({ customerCode, customersPaymentStatList, billsKeys, forClient }): JSX.Element => {
    return (
      <Paper sx={{ mb: 2 }}>
        <Grid sx={{ display: 'grid', gridTemplateColumns: 'minmax(310px, 1fr)' }} className="withCustomScroll">
          <Grid container p={1} minWidth="780px">
            <Grid item xs={12}>
              {forClient ? null : (
                <MxLink href={`/app/clients/${customerCode}`} variant="h6" color="primary.main">
                  {customersPaymentStatList[customerCode].shortTitle}
                </MxLink>
              )}
            </Grid>
            {forClient ? null : (
              <Grid item xs={12} mb={1}>
                <Typography variant="body1" color="textPrimary">
                  {`Реализатор ${customersPaymentStatList[customerCode].mainEmployee}`}
                  {customersPaymentStatList[customerCode].companyFoundedDate &&
                    ` (${formatDateSwaggerZ(customersPaymentStatList[customerCode].companyFoundedDate)})`}
                </Typography>
              </Grid>
            )}
            {billsKeys.length ? (
              <Grid item xs={12}>
                <Grid container py={1}>
                  <Grid item xs={1.5}>
                    <Typography variant="body2" color="textPrimary" fontWeight="bold">
                      Дата счёта
                    </Typography>
                  </Grid>
                  <Grid item xs={1.5}>
                    <Typography variant="body2" color="textPrimary" fontWeight="bold">
                      Счет
                    </Typography>
                  </Grid>
                  <Grid item xs={1.5}>
                    <Typography variant="body2" color="textPrimary" fontWeight="bold">
                      Отгрузка
                    </Typography>
                  </Grid>
                  <Grid item xs={1.5}>
                    <Typography variant="body2" color="textPrimary" fontWeight="bold">
                      Дата ожидания
                    </Typography>
                  </Grid>
                  <Grid item xs={1.5}>
                    <Typography variant="body2" color="textPrimary" fontWeight="bold">
                      Схема оплаты
                    </Typography>
                  </Grid>
                  <Grid item xs={1.5}>
                    <Typography variant="body2" color="textPrimary" fontWeight="bold">
                      Оплачено
                    </Typography>
                  </Grid>
                  <Grid item xs={1.5}>
                    <Typography variant="body2" color="textPrimary" fontWeight="bold">
                      Просрочено
                    </Typography>
                  </Grid>
                  <Grid item xs={1.5}>
                    <Typography variant="body2" color="textPrimary" fontWeight="bold">
                      Ожидание
                    </Typography>
                  </Grid>
                </Grid>
                <Divider />
                {billsKeys.map(bill => {
                  const billCode = customersPaymentStatList[customerCode].bills[bill][0].billMdmCode;
                  return (
                    <Grid pt={1} key={bill}>
                      <Grid container>
                        <Grid item xs={3}>
                          <Grid container flexWrap="nowrap" alignItems="center">
                            <Grid item xs={6}>
                              <Typography variant="body2" color="textPrimary">
                                {formatDateSwagger(new Date(customersPaymentStatList[customerCode].bills[bill][0].billDate))}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Grid container flexWrap="nowrap" alignItems="center">
                                <Grid item>
                                  <ThreadLink link={`/app/thread/bills/${encodeURIComponent(billCode)}`} />
                                </Grid>
                                <Grid item>
                                  <MxLink href={`/app/bills/${encodeURIComponent(billCode)}`} fontSize={'14px'} color="textPrimary">
                                    {billCode}
                                  </MxLink>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={9}>
                          {customersPaymentStatList[customerCode].bills[bill].map((statItem, index) => {
                            return (
                              <Grid
                                container
                                flexWrap="nowrap"
                                alignItems="center"
                                mb={1}
                                key={`${customerCode}${bill}${statItem.shipmentDocumentNumber}${index}`}
                              >
                                <Grid item xs={2}>
                                  <Grid container flexWrap="nowrap" alignItems="center">
                                    {statItem?.shipmentDocumentNumber && (
                                      <>
                                        <Grid item>
                                          <ThreadLink
                                            link={`/app/thread/shipments/${encodeURIComponent(statItem?.shipmentDocumentNumber)}`}
                                          />
                                        </Grid>
                                        <Grid item>
                                          <MxLink
                                            href={`/app/shipments/${encodeURIComponent(statItem?.shipmentDocumentNumber)}`}
                                            fontSize={'14px'}
                                            color="textPrimary"
                                          >
                                            {statItem?.shipmentDocumentNumber}
                                          </MxLink>
                                        </Grid>
                                      </>
                                    )}
                                  </Grid>
                                </Grid>
                                <Grid item xs={2}>
                                  <Typography variant="body2" color="textPrimary">
                                    {statItem?.plannedDate}
                                  </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <Typography variant="body2" color="textPrimary">
                                    {`${statItem?.prepayPct ? statItem?.prepayPct : '0'}% (${
                                      statItem?.postpayDays ? statItem?.postpayDays : '0'
                                    } дней)`}
                                  </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <Typography variant="body2" color="textPrimary">
                                    {formatValueCurPayment(statItem?.paidAmount)}
                                  </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <Typography variant="body2" color="textPrimary">
                                    {formatValueCurPayment(statItem?.overdueAmount)}
                                  </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <Typography variant="body2" color="textPrimary">
                                    {formatValueCurPayment(statItem?.awaitingAmount)}
                                  </Typography>
                                </Grid>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Grid>
                      <Divider />
                    </Grid>
                  );
                })}
                <Grid container flexWrap="nowrap" justifyContent="flex-end">
                  <Grid item xs={9}>
                    <Grid container pt={1}>
                      <Grid item xs={2}></Grid>
                      <Grid item xs={2}>
                        <Grid container flexDirection="column">
                          <Grid item xs={12}>
                            <Typography variant="body2" color="textPrimary">
                              ИТОГО
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={2}></Grid>
                      <Grid item xs={2}>
                        <Grid container flexDirection="column">
                          <Grid item xs={12}>
                            <Typography variant="body2" color="textPrimary">
                              {formatValueCurPayment(customersPaymentStatList[customerCode].totalPaidAmount)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={2}>
                        <Grid item xs={12}>
                          <Typography variant="body2" color="textPrimary">
                            {formatValueCurPayment(customersPaymentStatList[customerCode].totalOverdueAmount)}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={2}>
                        <Grid item xs={12}>
                          <Typography variant="body2" color="textPrimary">
                            {formatValueCurPayment(customersPaymentStatList[customerCode].totalAwaitingAmount)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Typography variant="body1" color="textPrimary">
                  Нет данных
                </Typography>
              </Grid>
            )}
            {customersPaymentStatList[customerCode].notDistributedAmount > 0 && (
              <Grid container flexWrap="nowrap" justifyContent="flex-end">
                <Grid item xs={9}>
                  <Grid container pt={1}>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={2}>
                      <Grid container flexDirection="column">
                        <Grid item xs={12}>
                          <Typography variant="body2" color="textPrimary">
                            + не распределено
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={2}>
                      <Grid container flexDirection="column">
                        <Grid item xs={12}>
                          <Typography variant="body2" color="textPrimary">
                            {formatValueCurPayment(customersPaymentStatList[customerCode].notDistributedAmount)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Paper>
    );
  }
);
