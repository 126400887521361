import React, { FC, JSX, useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Product, ProductItemStock } from '../../api/marketx';
import { NewCatalogStore } from '../../store/Catalog/NewCatalogStore';
import Typography from '@mui/material/Typography';
import { formatNumberByUnit } from '@mx-ui/helpers';
import { Divider, Grid, IconButton, Modal, Paper, Skeleton } from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Box from '@mui/material/Box';
import { styleForCatalogModel } from './Reserve/TooltipForReseveList';
import { ProductWarehouseDetails } from '../../views/catalog/Listing/ProductWarehouseDetails';
import { boxGridStyle } from './Reserve/ReserveDetailsV2';
import { TableRowTitle } from '../Catalog/Table/CatalogTableRowVirtualizeItemView';

interface TooltipForWarehouseDetailsProps {
  item: Product;
  catalogStore: NewCatalogStore;
}

export const TooltipForWarehouseDetails: FC<TooltipForWarehouseDetailsProps> = observer(({ item: product, catalogStore }): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [productsStock, setProductsStock] = useState<ProductItemStock[]>(null);
  const handleTooltipClose = (): void => {
    setOpen(false);
    setProductsStock(null);
  };
  const handleTooltipOpen = useCallback((): void => {
    setOpen(true);
    setIsLoading(true);
    catalogStore
      .loadCatalogProductStocks(product.code)
      .then(res => {
        setProductsStock(res);
        setIsLoading(false);
      })
      .catch(() => {
        setProductsStock(null);
        setIsLoading(false);
      });
  }, [catalogStore, product]);
  if (!open) {
    return (
      <Grid container alignItems={'center'} justifyContent={'flex-end'}>
        {product.inStockForSale ? (
          <>
            <Typography fontSize={'14px'}>{formatNumberByUnit(product.inStockForSale, product.baseUnitCode)}</Typography>
            <IconButton size={'small'} onClick={handleTooltipOpen}>
              <InfoIcon fontSize="small" color={'primary'} />
            </IconButton>
          </>
        ) : (
          <Typography fontSize={'14px'} mr="30%">
            -
          </Typography>
        )}
      </Grid>
    );
  }
  return (
    <>
      <Grid container alignItems={'center'} justifyContent={'flex-end'}>
        <Typography fontSize={'14px'}>
          {product.inStockForSale ? formatNumberByUnit(product.inStockForSale, product.baseUnitCode) : '-'}
        </Typography>
        {product.inStockForSale !== null && product.inStockForSale !== undefined && (
          <IconButton size={'small'} onClick={handleTooltipOpen}>
            <InfoIcon fontSize="small" color={'primary'} />
          </IconButton>
        )}
      </Grid>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Modal keepMounted open={open} onClose={handleTooltipClose}>
          <Box sx={{ ...styleForCatalogModel }} position={'relative'}>
            <Paper
              sx={{
                display: 'grid',
                gridTemplateColumns: 'minmax(720px, 1fr)',
                overflow: 'auto',
                maxHeight: '450px',
                p: 1,
              }}
              className="withCustomScroll"
              id={`${product.code}ScrollableReserveContainer`}
            >
              <Grid container pt={4.5}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    position: 'absolute',
                    left: '0',
                    top: '0',
                    width: '100%',
                    backgroundColor: 'background.paper',
                    borderRadius: '15px 15px 0 0',
                    p: 1,
                    zIndex: 1100,
                  }}
                >
                  <Typography fontSize={'12px'} sx={{ pl: '2px' }} color="text.secondary">
                    <TableRowTitle item={product} catalogStore={catalogStore} />
                  </Typography>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  {isLoading ? (
                    [1].map(item => {
                      return (
                        <Box sx={boxGridStyle} key={item} mb={2}>
                          <Box display="grid">
                            <Box>
                              <Typography variant={'body2'} color={'text.secondary'} textAlign={'center'}>
                                Склад
                              </Typography>
                            </Box>
                            <Box>
                              <Skeleton />
                            </Box>
                          </Box>
                          <Box display="grid">
                            <Box>
                              <Typography variant={'body2'} color={'text.secondary'} textAlign={'center'}>
                                Всего
                              </Typography>
                            </Box>
                            <Box>
                              <Skeleton />
                            </Box>
                          </Box>
                          <Box display="grid">
                            <Box>
                              <Typography variant={'body2'} color={'text.secondary'} textAlign={'center'}>
                                Остаток
                              </Typography>
                            </Box>
                            <Box>
                              <Skeleton />
                            </Box>
                          </Box>
                          <Box display="grid">
                            <Box>
                              <Typography variant={'body2'} color={'text.secondary'} textAlign={'center'}>
                                Резерв сформирован
                              </Typography>
                            </Box>
                            <Box>
                              <Skeleton />
                            </Box>
                          </Box>
                          <Box display="grid">
                            <Box>
                              <Typography variant={'body2'} color={'text.secondary'} textAlign={'center'}>
                                Резерв подтверждён
                              </Typography>
                            </Box>
                            <Box>
                              <Skeleton />
                            </Box>
                          </Box>
                          <Box display="grid">
                            <Box>
                              <Typography variant={'body2'} color={'text.secondary'} textAlign={'center'}>
                                Фризы
                              </Typography>
                            </Box>
                            <Box>
                              <Skeleton />
                            </Box>
                          </Box>
                          <Box display="grid">
                            <Box>
                              <Typography variant={'body2'} color={'text.secondary'} textAlign={'center'}>
                                Накладные
                              </Typography>
                            </Box>
                            <Box>
                              <Skeleton />
                            </Box>
                          </Box>
                        </Box>
                      );
                    })
                  ) : (
                    <ProductWarehouseDetails
                      product={product}
                      warehouses={productsStock}
                      selectedWarehouse={catalogStore.selectedValueByWarehouse}
                      warehousesList={catalogStore.warehouseListStore.items as any}
                    />
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </Modal>
      </ClickAwayListener>
    </>
  );
});
