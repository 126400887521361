import React, { FC, useCallback, useEffect, useState } from 'react';
import Head from 'next/head';
import { observer } from 'mobx-react-lite';
import { useAuthStore } from '../../store/MobxStoreProvider';
import { FeedbackButton } from '../DashboardLayout/FeedbackButton';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import GlobalStyles from '@mui/material/GlobalStyles';
import { Link as MxLink } from '@mx-ui/ui';
import { AppBarColor } from '../DashboardLayout/TopBar/AppbarColor';
import { useLocalStorage } from '../../hooks/useLocalStorage';
// import Snowfall from 'react-snowfall';

const PREFIX = 'Landing';

const classes = {
  toolbarLink: `${PREFIX}-toolbarLink`,
  brandName: `${PREFIX}-brandName`,
  appBarColor: `${PREFIX}-appBarColor`,
  '@global': `${PREFIX}-undefined`,
  heroContent: `${PREFIX}-heroContent`,
  heroSubHeader: `${PREFIX}-heroSubHeader`,
  heroText: `${PREFIX}-heroText`,
  toolbar: `${PREFIX}-toolbar`,
  heroButtons: `${PREFIX}-heroButtons`,
  ctaButton: `${PREFIX}-ctaButton`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .MuiButton-root`]: {
    textTransform: 'none',
  },
  [`& .${classes.toolbarLink}`]: {
    color: '#fff',
    marginLeft: 12,
    fontWeight: 600,
  },
  [`& .${classes.toolbar}`]: {
    paddingRight: 0,
    minHeight: '54px',
    lineHeight: 1,
    fontSize: '0px',
    paddingLeft: '0px',
  },
  [`& .${classes.appBarColor}`]: {
    borderRadius: 'inherit',
    boxShadow: 'none',
  },
  [`& .${classes.heroContent}`]: {
    padding: theme.spacing(8, 0, 6),
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translateY(-50%) translateX(-50%)',
  },

  [`& .${classes.heroSubHeader}`]: {
    color: '#fff',
    fontSize: '3rem',
    fontWeight: 700,

    [theme.breakpoints.between('xs', 'md')]: {
      fontSize: '1.3rem',
    },
    [theme.breakpoints.between('sm', 'lg')]: {
      fontSize: '2rem',
    },
  },

  [`& .${classes.heroButtons}`]: {
    color: '#fff',
    '& a': {
      color: '#fff',
    },
  },

  [`& .${classes.ctaButton}`]: {
    backgroundColor: 'primary',
    color: 'text.primary',
    [theme.breakpoints.down('md')]: {
      fontSize: '80%',
    },
  },
}));

interface LandingProps {
  // адрес для редиректа, если он происходит
  redirectUri?: string;
  isAuth?: boolean;
  isAuthError?: boolean;
}

type LandingState = 'dummy' | 'initializing' | 'loading' | 'guest' | 'noAccess' | 'employee' | 'appError';

export const Landing: FC<LandingProps> = observer(({ isAuth, isAuthError }): JSX.Element => {
  const [themeMode] = useLocalStorage<'light' | 'dark'>('mode');
  const [domLoaded, setDomLoaded] = useState(false);
  const [logoSmallPath, setLogoSmallPath] = useState('/android-chrome-192x192.png');
  const [logoLargePath, setLogoLargePath] = useState(themeMode === 'dark' ? '/images/mx/lightMx.png' : `/images/mx/darkMx.png`);

  const auth = useAuthStore();
  const handleClickLogin = useCallback(
    (e: { preventDefault: () => void }) => {
      e.preventDefault();
      auth.login(`${window?.location?.pathname || ''}${window?.location?.search || ''}`);
    },
    [auth]
  );

  const handleClickLogout = useCallback(() => {
    auth.logout();
  }, [auth]);

  const [state, setState] = useState<LandingState>('dummy');

  useEffect((): void => {
    if (!auth.isInitialized) {
      // keycloak еще не связался с сервером
      setState('initializing');
      return;
    }
    if (!auth.isAuthenticated) {
      // Связь с keycloak установлена, пользователь там не авторизован
      setState('guest');
      return;
    }
    if (!auth.isLoaded) {
      // Загружается профиль с бэкенда
      setState('loading');
      return;
    }
    if (auth.isGuest()) {
      // Бэкенд говорит, что текущий пользователь - гость
      setState('noAccess');
      return;
    }
    if (auth?.profile && !auth.hasFrontOfficeAccess()) {
      setState('noAccess');
      return;
    }
    setState('employee');
  }, [auth, auth.isLoaded, auth.isInitialized, auth.profile]);

  useEffect(() => {
    setDomLoaded(true);
  }, []);

  useEffect(() => {
    try {
      const options = {
        ignoreSearch: true,
        ignoreMethod: true,
        ignoreVary: true,
      };
      caches.match('/android-chrome-192x192.png', options).then(res => {
        res?.url && setLogoSmallPath(res.url);
      });
      caches.match(themeMode === 'dark' ? '/images/mx/lightMx.png' : `/images/mx/darkMx.png`, options).then(res => {
        res?.url && setLogoLargePath(res.url);
      });
    } catch (e) {
      console.warn('NO caches', e);
    }
  }, [themeMode]);

  return (
    domLoaded && (
      <Root>
        <Head>
          <title>MX.pro - умная сделка</title>
        </Head>
        {!isAuth && (
          <AppBarColor sx={{ borderRadius: '0px !important', border: '0px' }}>
            <Toolbar className={classes.toolbar}>
              <MxLink
                href="/"
                typographySx={{
                  display: 'inline-block',
                  lineHeight: 'inherit',
                  fontSize: 'inherit',
                  position: 'relative',
                }}
              >
                <img src={logoSmallPath} width={54} height={'100%'} alt={''} />
                {/* новогодние приколюхи */}
                {/* <img*/}
                {/*  src={'/images/rb_542.png'}*/}
                {/*  width={54}*/}
                {/*  height={'100%'}*/}
                {/*  alt={''}*/}
                {/*  style={{*/}
                {/*    position: 'absolute',*/}
                {/*    top: '0',*/}
                {/*    left: '0',*/}
                {/*  }}*/}
                {/* />*/}
              </MxLink>
            </Toolbar>
          </AppBarColor>
        )}
        <main>
          <>
            <GlobalStyles
              styles={{
                body: {
                  backgroundColor: themeMode === 'dark' ? '#121212' : '#fff',
                  overflow: 'hidden',
                },
              }}
            />
            {/* новогодние приколюхи */}
            {/* <Snowfall />*/}
            <div style={{ position: 'absolute', top: '9px', right: '20px', zIndex: 1900 }}>
              <FeedbackButton themeMode={themeMode} />
            </div>
            {/* Hero unit */}
            <div className={classes.heroContent}>
              <Grid
                container
                flexDirection={'column'}
                maxWidth="sm"
                sx={{ minWidth: { xs: '340px', sm: '90vw' }, minHeight: '310px', width: '100%' }}
              >
                <Grid item>
                  <Grid container justifyContent={'center'} width={'100%'}>
                    <Grid
                      item
                      sx={{
                        width: { xs: '180px', sm: '230px', md: '280px' },
                        height: { xs: '95px', sm: '120px', md: '150px' },
                      }}
                    >
                      <img alt="MX" src={logoLargePath} style={{ width: '100%' }} />
                    </Grid>
                  </Grid>
                </Grid>

                {!isAuthError ? (
                  <Grid
                    item
                    sx={{
                      height: { xs: '84px', sm: '105px', md: '135px' },
                    }}
                  >
                    <Typography
                      align="center"
                      sx={{ color: themeMode === 'dark' ? '#fff' : '#080F72', fontSize: { xs: '23px', sm: '28px', md: '36px' } }}
                    >
                      Интеллектуальная
                      <br />
                      экосистема продаж
                      <br />
                    </Typography>
                  </Grid>
                ) : (
                  <Grid
                    item
                    sx={{
                      height: { xs: '84px', sm: '105px', md: '135px' },
                    }}
                  >
                    <Grid container height={'100%'} justifyContent={'center'} alignItems={'center'}>
                      <Typography
                        align="center"
                        sx={{
                          textAlign: 'center',
                          color: themeMode === 'dark' ? '#fff' : '#080F72',
                          fontSize: { xs: '23px', sm: '28px', md: '36px' },
                        }}
                      >
                        В процессе авторизации произошла ошибка
                      </Typography>
                    </Grid>
                  </Grid>
                )}

                <Grid item minHeight={'110px'}>
                  <div className={classes.heroButtons}>
                    <Grid container justifyContent="center">
                      {!isAuthError && auth.isAppError && auth.isLoaded && auth.isInitialized && auth.isAuthenticated && (
                        <Grid item>
                          <Typography variant="body2" sx={{ color: themeMode === 'dark' ? '#fff' : '#080F72' }}>
                            Не удалось связаться с сервером приложений.
                          </Typography>
                          <Typography variant="body2" sx={{ color: themeMode === 'dark' ? '#fff' : '#080F72' }}>
                            Должно быть это временная проблема,
                            <br />
                            попробуйте через пару минут
                            <a href={window.location.href} style={{ color: themeMode === 'dark' ? '#fff' : '#080F72', paddingLeft: '4px' }}>
                              обновить страницу
                            </a>
                          </Typography>
                        </Grid>
                      )}
                      {!isAuthError && state === 'guest' && (
                        <Grid item>
                          <Button
                            onClick={handleClickLogin}
                            className={classes.ctaButton}
                            variant="contained"
                            size={'large'}
                            color="primary"
                            sx={{ fontSize: { xs: '12px !important', sm: '20px !important', md: '28px !important' } }}
                          >
                            Начать работу
                          </Button>
                        </Grid>
                      )}
                      {isAuthError && (
                        <Grid item>
                          <Button
                            onClick={handleClickLogin}
                            className={classes.ctaButton}
                            variant="contained"
                            size={'large'}
                            color="primary"
                            sx={{ fontSize: { xs: '12px !important', sm: '20px !important', md: '28px !important' } }}
                          >
                            Повторная авторизация
                          </Button>
                        </Grid>
                      )}
                      {!isAuthError && state === 'noAccess' && (
                        <Grid container flexDirection={'column'} alignItems={'center'}>
                          <Grid item mb={1} textAlign={'center'}>
                            <Typography variant="body2" sx={{ color: themeMode === 'dark' ? '#fff' : '#080F72' }}>
                              Авторизация прошла успешно, однако для текущего пользователя не определены права доступа
                              {auth.profile?.email ? ' (' + auth.profile.email + ').' : '.'}
                            </Typography>
                            <Typography variant="body2" sx={{ color: themeMode === 'dark' ? '#fff' : '#080F72' }}>
                              Пожалуйста, свяжитесь с руководителем и поддержкой для решения.
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Button onClick={handleClickLogout} className={classes.ctaButton} variant="contained" color="primary">
                              Выйти из&nbsp;системы
                            </Button>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </div>
          </>
        </main>
      </Root>
    )
  );
});
