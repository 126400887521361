import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';
import {
  Autocomplete,
  Button,
  Grid,
  IconButton,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  useMediaQuery,
} from '@mui/material';
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import { formatDateSwaggerZ } from '@mx-ui/helpers';
import { BillItemStore } from 'src/store/Documents/BillItemStore';
import { DialogContainer } from '../ui/dialogs/DialogContainer';
import { DocFlowPowerOfAttorneyIncoming } from 'src/api/marketx';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { BillSummaryTabHeader } from './BillSummaryTabHeader';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { useAuthStore } from 'src/store/MobxStoreProvider';
import { AppUserGroupCodes } from 'src/types/AppUserGroup';
import { ClientPowersOfAttorneyPdfPreviewDialog } from '../Clients/ClientTabs/ClientPowersOfAttorneyPdfPreviewDialog';

type BillSummaryDocFlowPowerOfAttorneyIncomingProps = {
  billStore: BillItemStore;
  isDialogTabOpen?: boolean;
};

export const BillSummaryDocFlowPowerOfAttorneyIncoming: FC<BillSummaryDocFlowPowerOfAttorneyIncomingProps> = observer(props => {
  const { billStore, isDialogTabOpen } = props;
  const authStore = useAuthStore();
  const matches = useMediaQuery('(min-width:875px)');

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedPowerOfAttorney, setSelectedPowerOfAttorney] = useState<DocFlowPowerOfAttorneyIncoming>(null);
  const [isPdfPreviewOpen, setIsPreviewDialogOpen] = useState(false);
  const [selectedPdfFile, setSelectedPdfFile] = useState({ filename: '', printFormUrl: '' });

  const handleOpenPdfPreview = (filename: string, printFormUrl: string): void => {
    if (matches) {
      setIsPreviewDialogOpen(true);
      setSelectedPdfFile({ filename: filename, printFormUrl: printFormUrl });
    } else {
      window.open(printFormUrl);
    }
  };

  const handleClosePdfPreview = (): void => {
    setIsPreviewDialogOpen(false);
  };

  const powersOfAttorneyList = billStore.docFlowPowerOfAttorneyList;
  const billPowersOfAttorneyList = billStore.billDocFlowPowerOfAttorneyList;
  const isDocFlowPowerOfAttorneyLoading = billStore.isDocFlowPowerOfAttorneyLoading;

  const canEditPowersOfAttorney = authStore.inAnyGroup([
    AppUserGroupCodes.SELLER,
    AppUserGroupCodes.SELLER_CHIEF,
    AppUserGroupCodes.OFFICE_CHIEF,
    AppUserGroupCodes.DOC_FLOW_ADMIN,
    AppUserGroupCodes.RESERVE_ADMIN,
    AppUserGroupCodes.RESERVE_MANAGER,
  ]);

  const handleCloseDialog = (): void => {
    setIsDialogOpen(false);
  };

  const handleOpenDialog = (): void => {
    setIsDialogOpen(true);
  };

  const handleAddPowerOfAttorney = (): void => {
    billStore.updatePowerOfAttorney(billStore.item?.code, selectedPowerOfAttorney?.code).then(() => {
      setSelectedPowerOfAttorney(null);
      setIsDialogOpen(false);
    });
  };

  const handleResetPowerOfAttorney = (powerOfAttorneyCode?: string): void => {
    billStore.updatePowerOfAttorney(billStore.item?.code, powerOfAttorneyCode, true);
  };

  const handleSelectPowerOfAttorney = (_, powerOfAttorney: DocFlowPowerOfAttorneyIncoming): void => {
    setSelectedPowerOfAttorney(powerOfAttorney);
  };

  const isSaveButtonDisabled = selectedPowerOfAttorney === null;

  return (
    <>
      <BillSummaryTabHeader Icon={DescriptionOutlinedIcon} billItemStore={billStore} isDialogOpen={isDialogTabOpen} />
      <Button variant="contained" size="small" onClick={handleOpenDialog} disabled={!canEditPowersOfAttorney}>
        Добавить
      </Button>
      <DialogContainer
        open={isDialogOpen}
        onClose={handleCloseDialog}
        disabled={isSaveButtonDisabled}
        dialogTitle={'Добавление доверенности'}
        dialogContent={
          <Autocomplete
            sx={{ width: 320, p: 1, mb: 3 }}
            size="small"
            options={powersOfAttorneyList || []}
            value={selectedPowerOfAttorney || null}
            getOptionLabel={option => option.title}
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.code}>
                  {option.title}
                </li>
              );
            }}
            isOptionEqualToValue={(option, value) => option.code === value.code}
            onChange={handleSelectPowerOfAttorney}
            renderInput={params => <TextField {...params} label="Выберите доверенность" fullWidth />}
          />
        }
        canselBtnText={'Отмена'}
        successBtnText={'Сохранить'}
        handleSuccess={handleAddPowerOfAttorney}
        maxWidth={'md'}
      />
      <Grid className="withCustomScroll" container sx={{ display: 'grid', gridTemplateColumns: 'minmax(310px)', maxHeight: 230 }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">Доверенность</TableCell>
                <TableCell align="left">Дата добавления</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {billPowersOfAttorneyList?.map(powersOfAttorney => (
                <TableRow key={powersOfAttorney.code} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell align="left">{powersOfAttorney?.title}</TableCell>
                  <TableCell align="left">{formatDateSwaggerZ(powersOfAttorney?.documentDate)}</TableCell>
                  <TableCell align="right" sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                    {powersOfAttorney?.printFormUrl && (
                      <IconButton
                        onClick={() => handleOpenPdfPreview(powersOfAttorney?.filename, powersOfAttorney?.printFormUrl)}
                        disabled={!powersOfAttorney?.printFormUrl}
                        title="Посмотреть доверенность"
                      >
                        <PreviewOutlinedIcon />
                      </IconButton>
                    )}

                    {powersOfAttorney?.zipUrl && (
                      <IconButton
                        LinkComponent={Link}
                        download
                        href={powersOfAttorney?.zipUrl}
                        target="_blank"
                        disabled={!powersOfAttorney?.zipUrl}
                        title={'Скачать доверенность'}
                      >
                        <ArchiveOutlinedIcon />
                      </IconButton>
                    )}

                    {powersOfAttorney?.canEdit && canEditPowersOfAttorney && (
                      <IconButton
                        title={'Отвязать доверенность от счета'}
                        disabled={!powersOfAttorney?.canEdit || !canEditPowersOfAttorney}
                        onClick={() => handleResetPowerOfAttorney(powersOfAttorney?.code)}
                      >
                        <DeleteOutlineIcon color="error" />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              ))}
              {isPdfPreviewOpen && (
                <ClientPowersOfAttorneyPdfPreviewDialog
                  open={isPdfPreviewOpen}
                  fileName={selectedPdfFile?.filename}
                  onClose={handleClosePdfPreview}
                  printFormUrl={selectedPdfFile?.printFormUrl}
                />
              )}
              {!billPowersOfAttorneyList.length && !isDocFlowPowerOfAttorneyLoading && (
                <TableRow>
                  <TableCell align="center" colSpan={5}>
                    Нет данных
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
});
