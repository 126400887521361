import React, { FC, JSX, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Bill, Customer } from '../../api/marketx';
import { ClientItemStore } from '../../store/ClientItemStore';
import { billShipmentStateColor, billStateColor, BillStyledPaper, paymentStateColor } from './BillListItem';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import { formatDateSwaggerZ, formatNumber3 } from '@mx-ui/helpers';
import { ThreadLink } from './ThreadLink';
import { AttentionBlock } from '../Clients/AttentionBlock/AttentionBlock';
import { renderPriceCur } from '../../utils/renderFormat';
import { MxLabel } from '@mx-ui/ui';
import { LabelNonResident } from 'src/views/clients/components/CompanyExtInfo';
import { BillListMenu } from './BillListMenu';
import { ButtonInvoicing } from './BillInvoicing/ButtonInvoicing';
import { BillItemInvoicingStore } from './BillInvoicing/BillItemInvoicingStore';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import FilterListIcon from '@mui/icons-material/FilterList';
import { BillListStore } from '../../store/Documents/BillListStore';
import { Link as MxLink } from '@mx-ui/ui';
import { MaxDocumentDate } from '../../types/constants';

interface BillListItemShortenedProps {
  bill: Bill;
  client?: ClientItemStore;
  customer?: Customer;
  invoicingStore?: BillItemInvoicingStore;
  billListStore?: BillListStore;
  withoutFilters?: boolean;
}

export const BillListItemShortened: FC<BillListItemShortenedProps> = observer(
  ({ bill, client, customer, invoicingStore, billListStore, withoutFilters = false }): JSX.Element => {
    const matchesXs = useMediaQuery('(min-width:450px)');
    const customerInfo = client?.details || bill.customer || customer;
    const handleChangeFilterByStatus = useCallback(
      (status?: string) => {
        if (billListStore.request?.status?.includes(status)) {
          return;
        }
        billListStore.mergeRequest({ status: [status] });
      },
      [billListStore]
    );

    const handleRemoveState = useCallback(
      (status?: string) => {
        if (!billListStore.request?.status?.includes(status)) {
          return;
        }
        const newStatusCodes = billListStore.request?.status?.filter(i => i !== status);
        billListStore.mergeRequest({ status: newStatusCodes });
      },
      [billListStore]
    );

    const handleChangeFilterByShipmentStatus = useCallback(
      (status?: string) => {
        if (billListStore.request?.shipmentStateCodes?.includes(status)) {
          return;
        }
        billListStore.mergeRequest({ shipmentStateCodes: [status] });
      },
      [billListStore]
    );

    const handleRemoveShipmentState = useCallback(
      (status?: string) => {
        if (!billListStore.request?.shipmentStateCodes?.includes(status)) {
          return;
        }
        const newStatusCodes = billListStore.request?.shipmentStateCodes?.filter(i => i !== status);
        billListStore.mergeRequest({ shipmentStateCodes: newStatusCodes });
      },
      [billListStore]
    );

    const handleChangeFilterByPaymentStatus = useCallback(
      (status?: string) => {
        if (billListStore.request?.paymentStateCodes?.includes(status)) {
          return;
        }
        billListStore.mergeRequest({ paymentStateCodes: [status] });
      },
      [billListStore]
    );

    const handleRemovePaymentState = useCallback(
      (status?: string) => {
        if (!billListStore.request?.paymentStateCodes?.includes(status)) {
          return;
        }
        const newStatusCodes = billListStore.request?.paymentStateCodes?.filter(i => i !== status);
        billListStore.mergeRequest({ paymentStateCodes: newStatusCodes });
      },
      [billListStore]
    );

    const handleCustomerChange = useCallback(
      (customerCode?: string) => {
        if (billListStore.request?.customerCodes?.includes(customerCode)) {
          return;
        }
        billListStore.mergeRequest({ customerCodes: customerCode ? [customerCode] : undefined });
      },
      [billListStore]
    );

    const handleCustomerRemoveChange = useCallback(
      (customerCode?: string) => {
        if (!billListStore.request?.customerCodes?.includes(customerCode)) {
          return;
        }
        const newCustomerCodes = billListStore.request?.customerCodes?.filter(i => i !== customerCode);
        billListStore.mergeRequest({ customerCodes: newCustomerCodes });
      },
      [billListStore]
    );

    return (
      <BillStyledPaper>
        <BillListMenu bill={bill} />
        <Grid container pb={1} justifyContent="space-between" spacing={2}>
          <Grid item md={5} xs={12}>
            <Grid container justifyContent="space-between" mb={1}>
              <Grid item container xs={12} alignItems="center">
                <Grid item mr={1}>
                  <CreditCardOutlinedIcon fontSize="medium" color="primary" />
                </Grid>
                <Grid item>
                  <MxLink href={`/app/bills/${encodeURIComponent(bill?.documentNumber)}`} color="primary" fontWeight={600}>
                    {`${bill?.documentNumber}${matchesXs ? ' от ' + formatDateSwaggerZ(bill.documentDate) : ''}`}
                  </MxLink>
                </Grid>
                <Grid item>
                  <ThreadLink color="primary" link={`/app/thread/bills/${encodeURIComponent(bill.documentNumber)}`} />
                </Grid>
              </Grid>
            </Grid>
            {customerInfo && (
              <Grid container mb={1} flexDirection="column">
                <Grid item>
                  <Typography variant="body2">ИНН {customerInfo?.inn || '—'}</Typography>
                </Grid>
                <Grid
                  item
                  sx={{ maxWidth: matchesXs ? 'auto' : '320px', overflow: 'hidden', textOverflow: 'ellipsis' }}
                  container
                  alignItems={'center'}
                  flexWrap={'nowrap'}
                >
                  {!!customerInfo?.nonResident && (
                    <Grid item mr={1}>
                      <LabelNonResident />
                    </Grid>
                  )}
                  {!!customerInfo?.attentions?.length && (
                    <Grid item mr={1}>
                      <AttentionBlock attentions={customerInfo?.attentions} />
                    </Grid>
                  )}
                  <Grid item>
                    <MxLink
                      href={`/app/clients/${encodeURIComponent(customerInfo?.code)}`}
                      color="text.secondary"
                      fontWeight={600}
                      fontSize={'15px'}
                    >
                      {customerInfo?.shortTitle || customerInfo?.title}
                    </MxLink>
                  </Grid>
                  {billListStore && !withoutFilters && customerInfo.code ? (
                    billListStore.request?.customerCodes?.includes(customerInfo?.code) ? (
                      <Grid item ml={1}>
                        <IconButton
                          size={'small'}
                          onClick={() => handleCustomerRemoveChange(customerInfo.code)}
                          title={`Убрать фильтрацию по ${customerInfo?.shortTitle || customerInfo?.title}`}
                        >
                          <DoDisturbOnOutlinedIcon color={'primary'} sx={{ fontSize: '20px' }} />
                        </IconButton>
                      </Grid>
                    ) : (
                      <Grid item ml={1}>
                        <IconButton
                          size={'small'}
                          onClick={() => handleCustomerChange(customerInfo.code)}
                          title={`Найти счета ${customerInfo?.shortTitle || customerInfo?.title}`}
                        >
                          <FilterListIcon color={'primary'} sx={{ fontSize: '20px' }} />
                        </IconButton>
                      </Grid>
                    )
                  ) : null}
                </Grid>
              </Grid>
            )}
            <Grid container mb={1} flexDirection="column">
              <Grid container justifyContent="space-between">
                <Grid item xs={6} md={5}>
                  <Typography fontSize="14px" fontWeight={600} color="text.secondary">
                    Дата документа
                  </Typography>
                </Grid>
                <Grid item xs={6} md={7}>
                  <Typography variant="body2" fontWeight={600} color="text.secondary">
                    {formatDateSwaggerZ(bill.documentDate)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container mb={1} flexDirection="column">
              <Grid container justifyContent="space-between">
                <Grid item xs={6} md={5}>
                  <Typography fontSize="14px" fontWeight={600} color="text.secondary">
                    Создан
                  </Typography>
                </Grid>
                <Grid item xs={6} md={7}>
                  <Typography variant="body2" fontWeight={600} color="text.secondary">
                    {formatDateSwaggerZ(bill.createdAt)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {new Date(bill.validUntil).getFullYear() !== MaxDocumentDate && (
              <Grid container mb={1} flexDirection="column">
                <Grid container justifyContent="space-between">
                  <Grid item xs={6} md={5}>
                    <Typography fontSize="14px" fontWeight={600} color="text.secondary">
                      Действителен до
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={7}>
                    <Typography variant="body2" fontWeight={600} color="text.secondary">
                      {formatDateSwaggerZ(bill.validUntil)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {bill?.state && (
              <Grid container mb={1} justifyContent="space-between">
                <Grid item xs={6} md={5}>
                  <Typography fontSize="14px" color="text.secondary">
                    Статус
                  </Typography>
                </Grid>
                <Grid item xs={6} md={7}>
                  <Grid container alignItems="center">
                    <MxLabel color={billStateColor(bill?.state?.code)}>{bill?.state?.title}</MxLabel>
                    {billListStore && !withoutFilters ? (
                      billListStore.request?.status?.includes(bill?.state?.code) ? (
                        <Box>
                          <IconButton
                            size={'small'}
                            onClick={() => handleRemoveState(bill?.state?.code)}
                            title={`Убрать фильтрацию по статусу ${bill?.state?.title || ''}`}
                          >
                            <DoDisturbOnOutlinedIcon color={'primary'} sx={{ fontSize: '20px' }} />
                          </IconButton>
                        </Box>
                      ) : (
                        <Box>
                          <IconButton
                            size={'small'}
                            onClick={() => handleChangeFilterByStatus(bill?.state?.code)}
                            title={`Найти счета по статусу ${bill?.state?.title || ''}`}
                          >
                            <FilterListIcon color={'primary'} sx={{ fontSize: '20px' }} />
                          </IconButton>
                        </Box>
                      )
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
            )}

            {bill.hasSubordinateDoc && (
              <Grid container justifyContent="space-between">
                <Grid item xs={6} md={5}>
                  <Typography variant="body2" color="text.secondary">
                    Есть подчиненные документы
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>

          <Grid item md={7} xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Grid container mb={1} flexDirection="column">
                  <Grid container justifyContent="space-between" mb={1}>
                    <Grid item xs={6}>
                      <Typography fontWeight={700} color="textPrimary">
                        Сумма счета
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography fontWeight={700} color="textPrimary">
                        {renderPriceCur(bill.totalCost)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container mb={1} justifyContent="space-between">
                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary">
                        Количество
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary">
                        {formatNumber3(bill.positionsTotalWeight / 1000)}
                        {` т`}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container mb={1} justifyContent="space-between">
                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary">
                        Отгружено
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary">
                        {formatNumber3(bill.shipmentsTotalWeight / 1000)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container mb={1} justifyContent="space-between">
                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary">
                        Остаток
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="text.secondary">
                        {formatNumber3((bill.positionsTotalWeight / 1000 || 0) - bill.shipmentsTotalWeight / 1000 || 0)}
                        {` т`}
                      </Typography>
                    </Grid>
                  </Grid>
                  {bill?.state && (
                    <Grid container mb={1} justifyContent="space-between">
                      <Grid item xs={6}>
                        <Typography fontSize="14px" color="text.secondary">
                          Статус отгрузки
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Grid container alignItems={'center'}>
                          <MxLabel color={billShipmentStateColor(bill?.shipmentsState?.code)}>{bill?.shipmentsState?.title}</MxLabel>
                          {billListStore && !withoutFilters ? (
                            billListStore.request?.shipmentStateCodes?.includes(bill?.shipmentsState?.code) ? (
                              <Box>
                                <IconButton
                                  size={'small'}
                                  onClick={() => handleRemoveShipmentState(bill?.shipmentsState?.code)}
                                  title={`Убрать фильтрацию по статусу отгрузки ${bill?.shipmentsState?.title || ''}`}
                                >
                                  <DoDisturbOnOutlinedIcon color={'primary'} sx={{ fontSize: '20px' }} />
                                </IconButton>
                              </Box>
                            ) : (
                              <Box>
                                <IconButton
                                  size={'small'}
                                  onClick={() => handleChangeFilterByShipmentStatus(bill?.shipmentsState?.code)}
                                  title={`Найти счета по статусу отгрузки ${bill?.shipmentsState?.title || ''}`}
                                >
                                  <FilterListIcon color={'primary'} sx={{ fontSize: '20px' }} />
                                </IconButton>
                              </Box>
                            )
                          ) : null}
                        </Grid>
                      </Grid>
                      {invoicingStore ? (
                        <Grid item ml={1}>
                          <ButtonInvoicing billItem={bill} invoicingStore={invoicingStore} />
                        </Grid>
                      ) : null}
                    </Grid>
                  )}
                  {bill.paymentSummary && (
                    <>
                      <Grid container mb={1} justifyContent="space-between">
                        <Grid item xs={6}>
                          <Typography variant="body2" color="text.secondary">
                            Статус оплаты
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Grid container alignItems={'center'}>
                            <MxLabel color={paymentStateColor(bill.paymentSummary?.paymentStateCode)}>
                              {bill.paymentSummary?.paymentStateTitle}
                            </MxLabel>
                            {billListStore && !withoutFilters ? (
                              billListStore.request?.paymentStateCodes?.includes(bill.paymentSummary?.paymentStateCode) ? (
                                <Box>
                                  <IconButton
                                    size={'small'}
                                    onClick={() => handleRemovePaymentState(bill.paymentSummary?.paymentStateCode)}
                                    title={`Убрать фильтрацию по статусу отгрузки ${bill.paymentSummary?.paymentStateTitle || ''}`}
                                  >
                                    <DoDisturbOnOutlinedIcon color={'primary'} sx={{ fontSize: '20px' }} />
                                  </IconButton>
                                </Box>
                              ) : (
                                <Box>
                                  <IconButton
                                    size={'small'}
                                    onClick={() => handleChangeFilterByPaymentStatus(bill.paymentSummary?.paymentStateCode)}
                                    title={`Найти счета по статусу оплаты ${bill.paymentSummary?.paymentStateTitle || ''}`}
                                  >
                                    <FilterListIcon color={'primary'} sx={{ fontSize: '20px' }} />
                                  </IconButton>
                                </Box>
                              )
                            ) : null}
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </BillStyledPaper>
    );
  }
);
