import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Typography from '@mui/material/Typography';
import DialogContentText from '@mui/material/DialogContentText';
import LinearProgress from '@mui/material/LinearProgress';
import React, { FC, useState } from 'react';
import MoreVertSharpIcon from '@mui/icons-material/MoreVertSharp';
import { useRouter } from 'next/router';
import { CreateAgreementFromDealButton } from 'src/views/clients/components/CreateAgreementFromDealButton';
import { useAuthStore, useRootStore } from 'src/store/MobxStoreProvider';
import { DealItemStore } from 'src/store/DealItemStore';
import { observer } from 'mobx-react-lite';
import { ConfirmDialog } from '../../../components/ui/dialogs/ConfirmDialog';
import { duplicateDeal } from 'src/views/clients/lib';
import { v4 as uuidV4 } from 'uuid';
import { DialogContainer } from '../../../components/ui/dialogs/DialogContainer';
import { TaskDialog } from 'src/components/Home/TaskDialog';
import { TasksStore } from 'src/store/TasksStore';
import { Link as MxLink } from '@mx-ui/ui';
import { useTaskDialogCreate } from 'src/components/Clients/Hooks/useTaskDialogCreate';
import { EmployeesStore } from 'src/store/Employees/EmployeesStore';
import { FilterForTaskStore } from 'src/store/FilterForTaskStore';

interface SettingsMenuProps {
  dealStore: DealItemStore;
}
export const SettingsMenu: FC<SettingsMenuProps> = observer(({ dealStore }): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const router = useRouter();
  const open = Boolean(anchorEl);
  const topBarStore = useRootStore().getTopBarStore();
  const auth = useAuthStore();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleClickNewClient = (): void => {
    topBarStore.setDealEntityStore(dealStore);
    const url = `/app/clients?deal=${dealStore.dealCode}`;
    router.push(url);
  };

  const [openConfirmUntieAgreement, setOpenConfirmUntieAgreement] = useState(false);
  const handleClickUntieAgreement = (): void => {
    setAnchorEl(null);
    setOpenConfirmUntieAgreement(true);
  };
  const handleCloseConfirmUntieAgreement = (isOk: boolean): void => {
    if (isOk) {
      dealStore.untieAgreement();
    }
    setOpenConfirmUntieAgreement(false);
  };
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [isDuplicatingDeal, setIsDuplicatingDeal] = useState(false);

  const apiStore = useRootStore().getApiStore();
  const rt = useRootStore();

  const isProduction = process.env.NODE_ENV === 'production';
  const linkTemplate = isProduction ? 'https://www.marketx.pro/' : 'https://www.metalx.shop/';

  const taskDialogDefaultValues = {
    deadlineDate: new Date(),
    statusCode: 'todo',
    title: 'Заявка на получение КП',
    initiatorEmployeeCode: auth.profile.employee.code,
    executorEmployeeCode: '',
    description: `КП по заявке <a href="${linkTemplate}app/deals/${dealStore.dealCode}">#${dealStore.dealCode}</a>`,
    beginDate: new Date(),
    priorityCode: 'high',
    followers: [],
    labelCodes: ['sales'],
    entities: [
      {
        entityCode: dealStore.deal.customer?.code || '',
        entityTitle: dealStore.deal.customer?.shortTitle || '',
        entityTypeCode: 'customer',
      },
    ],
  };

  const [employeesStore] = useState(() => {
    return new EmployeesStore(rt);
  });
  const [tasksStore] = useState(new TasksStore(rt));

  const [newOrderFrontId] = useState(uuidV4());

  const handleClickConfirmationOpen = (): void => {
    setConfirmationOpen(true);
    handleClose();
  };
  const handleConfirmationClose = (): void => {
    setConfirmationOpen(false);
  };
  const sourceDealCode = dealStore.dealCode;
  const handleConfirmationConfirm = (): void => {
    setIsDuplicatingDeal(true);

    duplicateDeal(apiStore, newOrderFrontId, sourceDealCode)
      .then((res): void => {
        setConfirmationOpen(false);
        setIsDuplicatingDeal(false);
        router.push(`/app/deals/` + encodeURIComponent(res.data.deal.code));
      })
      .catch(e => {
        console.error(e);
        setConfirmationOpen(false);
        setIsDuplicatingDeal(false);
      });
  };

  const taskDialogParams = useTaskDialogCreate(tasksStore, taskDialogDefaultValues);
  const { openTaskDialog, selectTask, handleSaveTask, handleCloseTaskDialog, handleCreateTask } = taskDialogParams;

  const handleOpenCreateTask = (): void => {
    handleCreateTask();
    try {
      tasksStore.setRouterControl(true);
      tasksStore.setFilterStore(new FilterForTaskStore(tasksStore));
      tasksStore.setEmployeesStore(employeesStore);
      tasksStore.loadTasks(tasksStore.request);

      tasksStore.loadedAllReferences();
    } catch (e) {
      handleCloseTaskDialog();
      console.error('handleOpenCreateTask', e);
    }
  };

  return (
    <div>
      <IconButton color="primary" onClick={handleClick} component="span" size="medium" disabled={!!dealStore?.isDeleted}>
        <MoreVertSharpIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        <MenuItem onClick={handleClickConfirmationOpen}>Дублировать</MenuItem>
        <MenuItem onClick={handleClose}>
          <CreateAgreementFromDealButton dealCode={dealStore.dealCode} />
        </MenuItem>
        {dealStore?.deal?.contractCostAgreements?.length > 0 && (
          <MenuItem onClick={handleClickUntieAgreement} disabled={dealStore.isViewOnly()}>
            Отвязать соглашение
          </MenuItem>
        )}
        <MenuItem onClick={handleClickNewClient} disabled={dealStore.isViewOnly() || dealStore?.deal?.contractCostAgreements.length > 0}>
          Выбрать клиента
        </MenuItem>
        <MenuItem onClick={handleOpenCreateTask}>Создать задачу</MenuItem>
        {Boolean(dealStore.deal?.pdfGenerationEnabled) && (
          <MenuItem>
            <MxLink
              href={
                `/api/deals/` + encodeURIComponent(dealStore.dealCode) + `/bill/pdf?fn=` + encodeURIComponent(dealStore.dealCode) + `.pdf`
              }
              variant="body1"
              color="textPrimary"
              target="_blank"
            >
              Сформировать КП
            </MxLink>
          </MenuItem>
        )}
        <MenuItem disabled={dealStore.deal.state !== 'approved'}>
          <MxLink href={`/app/print-documents/specification/deals/${dealStore.dealCode}`} variant="body1" color="textPrimary">
            Сформировать спецификацию
          </MxLink>
        </MenuItem>
      </Menu>

      <ConfirmDialog
        control
        open={openConfirmUntieAgreement}
        onClose={handleCloseConfirmUntieAgreement}
        dialogTitle={'Соглашение'}
        dialogContent={
          <>
            <Typography variant="body2">Вы действительно хотите отвязать соглашение?</Typography>
          </>
        }
      />

      <DialogContainer
        open={confirmationOpen}
        onClose={handleConfirmationClose}
        dialogTitle={'Дублировать заявку?'}
        dialogContent={
          <>
            <DialogContentText id="alert-dialog-description">
              <span>Будет создана новая заявка на основе {sourceDealCode}.</span>
            </DialogContentText>
            {isDuplicatingDeal && <LinearProgress />}
          </>
        }
        canselBtnText={'Отмена'}
        successBtnText={'Создать'}
        handleSuccess={handleConfirmationConfirm}
        maxWidth={'md'}
      />

      <TaskDialog
        open={openTaskDialog}
        defaultValues={selectTask}
        store={tasksStore}
        onClose={handleCloseTaskDialog}
        onSave={handleSaveTask}
        isLoading={tasksStore.isLoading}
      />
    </div>
  );
});
