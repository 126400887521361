import React, { FC, JSX, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { Bill, Customer } from '../../api/marketx';
import { ClientItemStore } from '../../store/ClientItemStore';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { ThreadLink } from './ThreadLink';
import { formatDateSwaggerZ, formatNumber3 } from '@mx-ui/helpers';
import { renderPriceCur } from '../../utils/renderFormat';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import { MxLabel } from '@mx-ui/ui';
import { billStateColor } from './BillListItem';
import { BillListMenu } from './BillListMenu';
import useMediaQuery from '@mui/material/useMediaQuery';
import { BillListStore } from '../../store/Documents/BillListStore';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Link as MxLink } from '@mx-ui/ui';

interface BillListTableViewProps {
  bill: Bill;
  client?: ClientItemStore;
  customer?: Customer;
  billListStore?: BillListStore;
  withoutFilters?: boolean;
}

export const BillListTableView: FC<BillListTableViewProps> = observer(
  ({ bill, client, customer, billListStore, withoutFilters = false }): JSX.Element => {
    const matchesXs = useMediaQuery('(min-width:450px)');
    const customerInfo = client?.details || bill.customer || customer;
    const handleChangeFilterByStatus = useCallback(
      (status?: string) => {
        if (billListStore.request?.status?.includes(status)) {
          return;
        }
        billListStore.mergeRequest({ status: [status] });
      },
      [billListStore]
    );

    const handleRemoveState = useCallback(
      (status?: string) => {
        if (!billListStore.request?.status?.includes(status)) {
          return;
        }
        const newStatusCodes = billListStore.request?.status?.filter(i => i !== status);
        billListStore.mergeRequest({ status: newStatusCodes });
      },
      [billListStore]
    );

    const handleCustomerChange = useCallback(
      (customerCode?: string) => {
        if (billListStore.request?.customerCodes?.includes(customerCode)) {
          return;
        }
        billListStore.mergeRequest({ customerCodes: customerCode ? [customerCode] : undefined });
      },
      [billListStore]
    );

    const handleCustomerRemoveChange = useCallback(
      (customerCode?: string) => {
        if (!billListStore.request?.customerCodes?.includes(customerCode)) {
          return;
        }
        const newCustomerCodes = billListStore.request?.customerCodes?.filter(i => i !== customerCode);
        billListStore.mergeRequest({ customerCodes: newCustomerCodes });
      },
      [billListStore]
    );
    return (
      <Paper sx={{ p: 1, mb: 2, position: 'relative' }}>
        <BillListMenu bill={bill} />
        <Grid container alignItems="center" justifyContent={'space-between'} spacing={1}>
          <Grid item flex={'0 1 95%'}>
            <Grid container alignItems="center" justifyContent={'space-between'} spacing={1}>
              <Grid item textAlign={'left'} flex={'0 1 340px'}>
                <Grid container alignItems="center">
                  <Grid item mr={1}>
                    <CreditCardOutlinedIcon fontSize="medium" color="primary" />
                  </Grid>
                  <Grid item>
                    <MxLink
                      href={`/app/bills/${encodeURIComponent(bill?.documentNumber)}`}
                      color="primary"
                      typographySx={{ fontWeight: 600 }}
                    >
                      {`${bill?.documentNumber}${matchesXs ? ' от ' + formatDateSwaggerZ(bill.documentDate) : ''}`}
                    </MxLink>
                  </Grid>
                  <Grid item>
                    <ThreadLink color="primary" link={`/app/thread/bills/${encodeURIComponent(bill.documentNumber)}`} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item textAlign={'left'} flex={'0 0 230px'}>
                <Grid container alignItems="center">
                  <MxLabel color={billStateColor(bill?.state?.code)}>{bill?.state?.title}</MxLabel>
                  {billListStore && !withoutFilters ? (
                    billListStore.request?.status?.includes(bill?.state?.code) ? (
                      <Box>
                        <IconButton
                          size={'small'}
                          onClick={() => handleRemoveState(bill?.state?.code)}
                          title={`Убрать фильтрацию по статусу ${bill?.state?.title || ''}`}
                        >
                          <DoDisturbOnOutlinedIcon color={'primary'} sx={{ fontSize: '20px' }} />
                        </IconButton>
                      </Box>
                    ) : (
                      <Box>
                        <IconButton
                          size={'small'}
                          onClick={() => handleChangeFilterByStatus(bill?.state?.code)}
                          title={`Найти счета по статусу ${bill?.state?.title || ''}`}
                        >
                          <FilterListIcon color={'primary'} sx={{ fontSize: '20px' }} />
                        </IconButton>
                      </Box>
                    )
                  ) : null}
                </Grid>
              </Grid>
              <Grid item textAlign={'right'} flex={'0 1 90px'}>
                <Typography color="text.secondary">
                  {formatNumber3(bill.positionsTotalWeight / 1000)}
                  {` т`}
                </Typography>
              </Grid>
              <Grid item textAlign={'right'} flex={'0 1 130px'}>
                <Typography style={{ fontWeight: 700 }} color="textPrimary">
                  {renderPriceCur(bill.totalCost)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} mt={1}>
            <Grid container alignItems={'center'} justifyContent={'space-between'} spacing={1}>
              <Grid item>
                <Grid container alignItems={'center'}>
                  {!matchesXs && (
                    <Grid item mr={1} xs={12} mb={1}>
                      <Typography variant="body2">от {formatDateSwaggerZ(bill.documentDate)}</Typography>
                    </Grid>
                  )}
                  <Grid item mr={1}>
                    <Typography variant="body2">ИНН {customerInfo?.inn || '—'}</Typography>
                  </Grid>
                  <Grid item>
                    <MxLink
                      href={`/app/clients/${encodeURIComponent(customerInfo?.code)}`}
                      color="text.secondary"
                      typographySx={{ fontSize: 15, fontWeight: 600 }}
                    >
                      {customerInfo?.shortTitle || customerInfo?.title}
                    </MxLink>
                  </Grid>
                  {billListStore && !withoutFilters && customerInfo.code ? (
                    billListStore.request?.customerCodes?.includes(customerInfo?.code) ? (
                      <Grid item ml={1}>
                        <IconButton
                          size={'small'}
                          onClick={() => handleCustomerRemoveChange(customerInfo.code)}
                          title={`Убрать фильтрацию по ${customerInfo?.shortTitle || customerInfo?.title}`}
                        >
                          <DoDisturbOnOutlinedIcon color={'primary'} sx={{ fontSize: '20px' }} />
                        </IconButton>
                      </Grid>
                    ) : (
                      <Grid item ml={1}>
                        <IconButton
                          size={'small'}
                          onClick={() => handleCustomerChange(customerInfo.code)}
                          title={`Найти счета ${customerInfo?.shortTitle || customerInfo?.title}`}
                        >
                          <FilterListIcon color={'primary'} sx={{ fontSize: '20px' }} />
                        </IconButton>
                      </Grid>
                    )
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }
);
