import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ArchiveIcon from '@mui/icons-material/Archive';
import CalculateIcon from '@mui/icons-material/Calculate';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import LinearProgress from '@mui/material/LinearProgress';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Product } from 'src/api/marketx';
import ProductConverter from 'src/components/Product/ProductConverter/ProductConverter';
import { ProductsNormalType } from 'src/slices/AppCatalog';
import { buildHash } from 'src/slices/AppDeal/lib';
import { NewCatalogStore } from 'src/store/Catalog/NewCatalogStore';
import { MsgType } from 'src/store/Global/WebSocketStore';
import { useRootStore } from 'src/store/MobxStoreProvider';
import { toFloat } from '@mx-ui/helpers';
import { v4 as uuidV4 } from 'uuid';
import { CatalogAdditionalInfoForDealAgreement } from './CatalogAdditionalInfoForDealAgreement';
import { LightTooltip } from '@mx-ui/ui';
import { useDebounce } from '../../../utils/hooks/useDebounce';
import { Divider } from '@mui/material';
const addShoppingCartIcon = <AddShoppingCartIcon />;
const shoppingCartIcon = <ShoppingCartIcon />;
const FormControlCustom = styled(FormControl)(({ theme }) => ({
  position: 'relative',
  width: '30ch',
  display: 'flex',
  flexWrap: 'wrap',
  margin: theme.spacing(1),
}));

const getHashProductFunc = (useWarehouse = false): ((code: string, warehouseCode: string, agreementCode?: string) => string) => {
  return function (code: string, warehouseCode: string, agreementCode?: string): string {
    if (useWarehouse) {
      return agreementCode ? buildHash(code + warehouseCode + agreementCode) : buildHash(code + warehouseCode);
    } else {
      return buildHash(code);
    }
  };
};
const delayForDebounce = 1000;
interface CatalogAdditionalInputsForDealProps {
  hashProduct: string;
  onSetLoading: (v: boolean) => void;
  catalogStore: NewCatalogStore;
  stateAmount: any;
}

const CatalogAdditionalInputsForDeal: FC<CatalogAdditionalInputsForDealProps> = observer(
  ({ catalogStore, stateAmount, hashProduct, onSetLoading }) => {
    const rootStore = useRootStore();
    const snackbarStore = rootStore.getSnackbar();
    const eventMgr = rootStore.getEventManager();

    const dealStore = catalogStore.dealStore;
    const byProductCodeForStore = catalogStore.byProductCodeForStore;

    const [commentApproval, setCommentApproval] = useState('');
    const [commentWarehouse, setCommentWarehouse] = useState('');
    const [commentCommercialProposal, setCommentCommercialProposal] = useState('');

    const [currentPositionCode, setCurrentPositionCode] = useState(byProductCodeForStore[hashProduct]?.code || '');

    useEffect(() => {
      if (!dealStore) return;
      if (byProductCodeForStore[hashProduct]?.code) {
        const currentPositionCode = byProductCodeForStore[hashProduct]?.code;
        setCurrentPositionCode(currentPositionCode);
        dealStore.deal.byCode[currentPositionCode]?.comments?.forEach(item => {
          if (item.typeCode === 'approval') {
            setCommentApproval(item.comment);
          }
          if (item.typeCode === 'store') {
            setCommentWarehouse(item.comment);
          }
          if (item.typeCode === 'commercial_proposal') {
            setCommentCommercialProposal(item.comment);
          }
        });
      }
    }, [byProductCodeForStore[hashProduct], catalogStore.request.agreementCode]);

    const addCommentToApproval = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      onSetLoading(true);
      setCommentApproval(event.target.value);
      debounceAddComment(event.target.value, commentCommercialProposal, commentWarehouse);
    };

    const addCommentToWarehouse = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      onSetLoading(true);
      setCommentWarehouse(event.target.value);
      debounceAddComment(commentApproval, commentCommercialProposal, event.target.value);
    };

    const addCommentToCommercialProposal = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      onSetLoading(true);
      setCommentCommercialProposal(event.target.value);
      debounceAddComment(commentApproval, event.target.value, commentWarehouse);
    };

    const debounceAddComment = useDebounce((approvalValue: string, commercialValue: string, warehouseValue: string): void => {
      const currentPosition = dealStore.deal.byCode[currentPositionCode];
      dealStore
        .updatePosition(currentPosition, {
          comments: [
            { comment: approvalValue, typeCode: 'approval' },
            { comment: warehouseValue, typeCode: 'store' },
            { comment: commercialValue, typeCode: 'commercial_proposal' },
          ],
        })
        .then(() => {
          snackbarStore.showSuccess(`Комментарий добавлен`);
          eventMgr.processMessages({
            msgType: MsgType.SHOP_FRONT_DEAL_PRODUCT_ADDED,
            data: {
              dealCode: dealStore.dealCode,
            },
          });
          onSetLoading(false);
        })
        .catch(err => {
          console.warn('handleCommentChange', err);
          onSetLoading(false);
        });
    }, delayForDebounce);

    return (
      <Grid item>
        <TextField
          disabled={!stateAmount}
          value={commentApproval}
          label="Комментарий для согласования"
          multiline
          maxRows={4}
          autoComplete="off"
          onChange={addCommentToApproval}
          sx={{ width: '100%', mb: '10px' }}
        />
        <TextField
          disabled={!stateAmount}
          value={commentWarehouse}
          label="Комментарий для склада"
          multiline
          maxRows={4}
          autoComplete="off"
          onChange={addCommentToWarehouse}
          sx={{ width: '100%', mb: '10px' }}
        />
        <TextField
          disabled={!stateAmount}
          value={commentCommercialProposal}
          label="Комментарий для коммерческого предложения"
          multiline
          maxRows={4}
          autoComplete="off"
          onChange={addCommentToCommercialProposal}
          sx={{ width: '100%', mb: '10px' }}
        />
      </Grid>
    );
  }
);
interface CatalogCellAddedProductProps {
  item: Product;
  catalogStore: NewCatalogStore;
}
export const CatalogCellAddedProduct: FC<CatalogCellAddedProductProps> = observer(({ item, catalogStore }) => {
  const rootStore = useRootStore();
  const snackbarStore = rootStore.getSnackbar();

  const dealStore = catalogStore.dealStore;
  const agreementStore = catalogStore.agreementStore;
  const freezeStore = catalogStore.freezeStore;

  const selectedWarehouseCode = catalogStore.selectedValueByWarehouse;
  const getHashProduct = getHashProductFunc(!!dealStore);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setHashProduct(getHashProduct(item?.code, selectedWarehouseCode, catalogStore.request.agreementCode));
  }, [item?.code, selectedWarehouseCode, catalogStore.request.agreementCode]);

  const hashStr = useMemo(() => {
    if (catalogStore.request.agreementCode) {
      return getHashProduct(item?.code, selectedWarehouseCode, catalogStore?.request?.agreementCode);
    } else {
      return getHashProduct(item?.code, selectedWarehouseCode);
    }
  }, [catalogStore.request.agreementCode]);

  const [hashProduct, setHashProduct] = useState(hashStr);
  useEffect(() => {
    setHashProduct(getHashProduct(item?.code, selectedWarehouseCode, catalogStore.request.agreementCode));
  }, [item?.code, selectedWarehouseCode, catalogStore.request.agreementCode]);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleAddClick = useCallback(
    event => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );
  const typeCurrentStore = catalogStore.typeCurrentStoreAddMode;
  const fieldValueByType = typeCurrentStore === 'deal' ? 'amount' : 'quantity';
  const stateAmount = String(toFloat(catalogStore.byProductCodeForStore[hashProduct]?.[fieldValueByType]) || '');
  const productAlreadyExists = !!catalogStore.byProductCodeForStore[hashProduct];
  const handleClose = useCallback(
    (event): void => {
      event.stopPropagation();
      setAnchorEl(null);
    },
    [setAnchorEl]
  );
  const openPopover = Boolean(anchorEl);
  const [amount, setAmount] = useState<any>(0);
  const [badgeAmount, setBadgeAmount] = useState(!productAlreadyExists ? null : Math.round(Number(stateAmount) * 100) / 100);
  const eventMgr = rootStore.getEventManager();
  const [frontCode] = useState(uuidV4());

  const byProductCodeForStore = catalogStore.byProductCodeForStore;
  const positionHashCode = byProductCodeForStore[hashProduct]?.code;

  const debounceAddProduct: (
    row: ProductsNormalType,
    value: number,
    unitCode: string,
    selectedWarehouseCode: string,
    positionCode?: string
  ) => void = useDebounce((row: ProductsNormalType, value: number, unitCode = '', selectedWarehouse: string, positionCode?: string) => {
    if (!unitCode) {
      return;
    }
    setLoading(true);
    const unitRequestCode = unitCode ? unitCode : unitProduct?.code;
    if (dealStore?.deal) {
      const nonResident = dealStore?.deal?.customer?.nonResident || false;

      const weight = unitProduct?.weight;

      // Цена, которая подставляется в позицию по-умолчанию из прайслиста, если товар только добавляется
      let cost = 0;
      let unitValue = value;
      // Если не тонна, то надо цену пересчитать
      if (unitProduct?.code !== 'TNE') {
        unitValue = value * (weight || 1);
      }

      for (let i = 0; i < row.prices.length; i++) {
        const price = row.prices[i];
        const min = price?.rangeMinCount || 0;
        const max = price.rangeMaxCount;

        if (min <= unitValue && unitValue < max) {
          cost = nonResident ? price.wovSuCost : price.priceSu;
          break;
        }
      }

      // Если не тонна, то надо цену пересчитать
      if (unitProduct?.code !== 'TNE') {
        cost = Math.round(cost * (weight || 1) * 100) / 100;
      }

      if (catalogStore.request.agreementCode) {
        if (positionCode) {
          const posFromDeal = dealStore.deal.byCode[positionCode];
          const newAmount = value.toString();
          const updatePositionObj: Record<string, any> = {
            code: posFromDeal.code,
            amount: newAmount,
            warehouseCode: selectedWarehouse,
          };
          dealStore
            .updatePosition(posFromDeal, updatePositionObj)
            .then(() => {
              snackbarStore.showSuccess(`Товар добавлен "${row.title}"`);
              eventMgr.processMessages({
                msgType: MsgType.SHOP_FRONT_DEAL_PRODUCT_ADDED,
                data: {
                  dealCode: dealStore.dealCode,
                },
              });
              setLoading(false);
            })
            .catch(r => {
              console.warn('debounceAddProduct1', r);
              setLoading(false);
            });
        } else {
          const updatePositions = [];
          const agreementItem = dealStore.deal.contractCostAgreements.find(i => i.code === catalogStore.request.agreementCode);

          toJS(agreementItem?.positions || []).forEach(p => {
            if (row.code === p.productCode && !p.isArchive) {
              updatePositions.push({
                frontCode: dealStore.dealCode,
                agreementPositionCode: p.code,
                productCode: p.productCode,
                amount: value,
                warehouseCode: selectedWarehouse,
                bareUnitCost: p.baseMinRetailUnitCost,
                unitCode: p.baseUnitCode,
                comment: p.comment,
              });
            }
          });

          if (updatePositions.length) {
            dealStore
              .addPositionsFromAgreement(updatePositions, agreementItem.code, dealStore.deal.customerCode)
              .then(res => {
                if (res === 'done') {
                  snackbarStore.showSuccess(`Товар добавлен "${row.title}"`);
                  eventMgr.processMessages({
                    msgType: MsgType.SHOP_FRONT_DEAL_PRODUCT_ADDED,
                    data: {
                      dealCode: dealStore.dealCode,
                    },
                  });
                  setLoading(false);
                } else if (res === 'await') {
                  debounceAddProduct(row, value, unitCode, selectedWarehouse, positionHashCode);
                }
              })
              .catch(r => {
                console.warn('debounceAddProduct2', r);
                setLoading(false);
              });
          } else {
            setLoading(false);
          }
        }
      } else {
        dealStore
          .addPosition(row, value, cost, positionCode, unitRequestCode, selectedWarehouse)
          .then(() => {
            snackbarStore.showSuccess(`Товар добавлен "${row.title}"`);
            eventMgr.processMessages({
              msgType: MsgType.SHOP_FRONT_DEAL_PRODUCT_ADDED,
              data: {
                dealCode: dealStore.dealCode,
              },
            });
            setLoading(false);
          })
          .catch(r => {
            console.warn('debounceAddProduct3', r);
            setLoading(false);
          });
      }
    }
    if (agreementStore?.agreement) {
      let cost = row.baseMinRetailUnitCost;
      // Если не тонна, то надо цену пересчитать
      if (unitProduct?.code !== 'TNE') {
        cost = Math.round(cost * (unitProduct?.weight || 1) * 100) / 100;
      }

      agreementStore
        .addProduct(row.code, value, unitRequestCode, frontCode, cost)
        .then(() => {
          snackbarStore.showSuccess(`Товар добавлен "${row.title}"`);
          eventMgr.processMessages({
            msgType: MsgType.SHOP_FRONT_AGREEMENT_PRODUCT_ADDED,
            data: {
              agreementCode: agreementStore.agreementCode,
            },
          });
          setLoading(false);
        })
        .then(() => {
          agreementStore.handleViewAgreementItemChange();
        })
        .catch(r => {
          console.warn('debounceAddProductInAgreement4', r);
          setLoading(false);
        });
    }
    if (freezeStore?.item) {
      freezeStore
        .addPositionFromCatalog(row.code, value, frontCode)
        .then(() => {
          snackbarStore.showSuccess(`Товар добавлен "${row.title}"`);
          eventMgr.processMessages({
            msgType: MsgType.SHOP_FRONT_FREEZE_PRODUCT_ADDED,
            data: {
              freezeCode: freezeStore.freezeDocumentNumber,
            },
          });
          setLoading(false);
        })
        .catch(r => {
          console.warn('debounceAddProductInFreeze', r);
          setLoading(false);
        });
    }
  }, delayForDebounce);
  useEffect(() => {
    setAmount(stateAmount);
    setBadgeAmount(!productAlreadyExists ? null : Math.round(Number(stateAmount) * 100) / 100);
  }, [hashProduct, catalogStore.byProductCodeForStore[hashProduct]]);
  const id = openPopover ? 'amount-popover-' + hashProduct : undefined;
  // const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, row: Product): void => {
    const re = /^\d+(\.)?(\d+)?$/;
    const value = event.target.value.trim().replace(',', '.');
    if (value === '' || re.test(value)) {
      onSetValueAmount(value, row);
    }
  };
  let helperText = null;
  if (productAlreadyExists) {
    //* ищем похожие продукты
    const duplicatesValues = Object.keys(catalogStore.byProductCodeForStore).filter(
      i => catalogStore.byProductCodeForStore[i].productCode === item.code
    );

    if (duplicatesValues.length > 1) {
      const unitField = typeCurrentStore === 'agreement' ? 'unitShortName' : 'unit';
      helperText = Object.entries(
        duplicatesValues
          .map(code => catalogStore.byProductCodeForStore[code])

          .reduce((acc, i) => {
            //* получаем общее кол-во продукта с разными ед. измерения {"шт":5,"т":6}
            if (acc[i[unitField]]) {
              acc[i[unitField]] = acc[i[unitField]] + Number(i[fieldValueByType]);
            } else {
              acc[i[unitField]] = Number(i[fieldValueByType]);
            }
            return acc;
          }, {})
      )
        .map(i => `${i[1]} ${i[0]}`)
        .join(', ');
    }
  }
  const onSetValueAmount = (valueAmount: string, row: Product, addValue = 0, unitCode = ''): void => {
    let value = valueAmount;
    const unitRequestCode = unitCode ? unitCode : unitProduct?.code;
    if (addValue) {
      const parseValue = parseFloat(value) || 0;
      const [, right] = String(parseValue).split('.'); // цифры после запятой
      value = String((parseValue + addValue).toFixed(right ? right.length : 0));
    }
    setAmount(value);
    const valueData = parseFloat(value) || 0;
    setBadgeAmount(Math.round(valueData * 100) / 100);
    debounceAddProduct(row, valueData, unitRequestCode, selectedWarehouseCode, positionHashCode);
  };

  const [isCalculateFormOpen, setIsCalculateFormOpen] = useState(false);

  const handlePositionRestoreClick = useCallback((): void => {
    const position = byProductCodeForStore[hashProduct];
    if (dealStore?.deal) {
      dealStore
        .restorePosition({ code: position.code })
        .then(() => {
          snackbarStore.showSuccess(`Товар восстановлен "${item.title}"`);
        })
        .catch(r => console.warn('handlePositionRestoreClick deal', r));
    } else if (agreementStore?.agreement) {
      agreementStore
        .restorePosition({ code: position.code })
        .then(() => {
          snackbarStore.showSuccess(`Товар восстановлен "${item.title}"`);
        })
        .catch(r => console.warn('handlePositionRestoreClick agreement', r));
    } else {
      console.warn('err handlePositionRestoreClick');
    }
  }, [dealStore, item, catalogStore.request.agreementCode]);
  const unitsProduct = item.units || [];
  const [unitProduct, setUnitProduct] = useState(
    unitsProduct.find(i => i.code === byProductCodeForStore[hashProduct]?.unitCode) ||
      unitsProduct.find(i => i.code === item.officeDefaultSaleUnitCode) ||
      unitsProduct[0]
  );
  useEffect(() => {
    if (item.officeDefaultSaleUnitCode) {
      setUnitProduct(
        unitsProduct.find(i => i.code === byProductCodeForStore[hashProduct]?.unitCode) ||
          unitsProduct.find(i => i.code === item.officeDefaultSaleUnitCode) ||
          unitsProduct[0]
      );
    }
  }, [item.officeDefaultSaleUnitCode]);
  const handlePositionUnitChange = useCallback(
    (value: string, row?: ProductsNormalType): void => {
      if (unitProduct.code !== value) {
        setUnitProduct(unitsProduct.find(i => i.code === value));
        onSetValueAmount(amount, row, 0, value);
      }
    },
    [amount, unitProduct]
  );
  const handleOpenCalculateForm = (e): void => {
    e.stopPropagation();
    setIsCalculateFormOpen(!isCalculateFormOpen);
  };
  const [initialTonneVal, setInitialTonneVal] = useState('');
  const handleSaveAmountFromConverter = useCallback((amount: string, unitCode: string): void => {
    const currentAmount = amount.replace(/\s+/g, '');
    handlePositionUnitChange(unitCode);
    onSetValueAmount(currentAmount, item, 0, unitCode);
  }, []);
  const pricesSpaces = useMemo(() => {
    return item.prices.slice(1);
  }, [item]);

  useEffect(() => {
    if (amount !== '') {
      if (unitProduct?.code === 'TNE') {
        setInitialTonneVal(amount);
      } else {
        setInitialTonneVal(`${+amount * unitProduct?.weight}`);
      }
    }
  }, [amount, unitProduct?.code]);

  return (
    <>
      <Box display="flex" alignItems="center" justifyItems="center">
        {!!catalogStore.byProductCodeForStore[hashProduct]?.isArchive ? (
          <IconButton aria-label="cart" onClick={handlePositionRestoreClick} size="large" title="Восстановить позицию">
            <ArchiveIcon />
          </IconButton>
        ) : (
          <>
            {catalogStore.isGroupSelectedWareHouse ? (
              <LightTooltip
                title={
                  <Box p={'4px'}>
                    <Typography fontSize="14px">Необходимо выбрать конкретный склад</Typography>
                  </Box>
                }
              >
                <Box sx={{ cursor: 'not-allowed' }} component="span">
                  <IconButton aria-label="cart" size="large" disabled>
                    {addShoppingCartIcon}
                  </IconButton>
                </Box>
              </LightTooltip>
            ) : (
              <IconButton aria-label="cart" onClick={handleAddClick} size="large">
                <Badge badgeContent={badgeAmount} color="secondary" showZero max={999}>
                  {badgeAmount === null ? addShoppingCartIcon : shoppingCartIcon}
                </Badge>
              </IconButton>
            )}
          </>
        )}
      </Box>
      <Popper
        id={id}
        open={openPopover}
        style={{ zIndex: 1300 }}
        anchorEl={anchorEl}
        placement={'bottom'}
        transition
        onResize={undefined}
        onResizeCapture={undefined}
      >
        {({ TransitionProps }) => (
          <>
            <ClickAwayListener mouseEvent={'onMouseDown'} onClickAway={handleClose}>
              <Grow {...TransitionProps}>
                <Grid container sx={{ position: 'relative' }}>
                  <Paper elevation={8} sx={{ display: 'flex' }}>
                    <FormControlCustom variant="standard">
                      <Typography color="text.secondary" fontSize={'12px'}>
                        {item.title}
                      </Typography>
                      <Grid py={0.5}>
                        <Divider />
                      </Grid>
                      <Grid container flexDirection="column">
                        <Grid item mb={2}>
                          <TextField
                            value={amount}
                            helperText={helperText ? `Всего в заявке: ${helperText}` : null}
                            autoFocus={true}
                            autoComplete="off"
                            variant="standard"
                            onChange={event => handleChange(event, item)}
                            sx={{ width: '100%' }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  {item.units.length > 1 && !freezeStore ? (
                                    <FormControl sx={{ width: '100%', height: '28px', mb: '2px' }}>
                                      <Select
                                        sx={{ height: '100%', '& legend': { width: 0 } }}
                                        displayEmpty
                                        onChange={e => handlePositionUnitChange(e.target.value, item)}
                                        value={unitProduct?.code}
                                        size="small"
                                        margin="dense"
                                      >
                                        {unitsProduct.map(unit => (
                                          <MenuItem value={unit.code} key={unit.code}>
                                            {unit.name}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  ) : (
                                    <Box component="span">{unitProduct?.name}</Box>
                                  )}

                                  <IconButton
                                    title={`Добавить 1 ${unitProduct?.name ?? ''}`}
                                    edge="end"
                                    color="inherit"
                                    onClick={() => onSetValueAmount(amount, item, 1)}
                                  >
                                    {addShoppingCartIcon}
                                  </IconButton>
                                  {item.units.length > 1 && (
                                    <IconButton
                                      title="Калькулятор/конвертор"
                                      color="inherit"
                                      aria-label="unit converter"
                                      onClick={handleOpenCalculateForm}
                                      sx={{ padding: '12px' }}
                                    >
                                      <CalculateIcon sx={{ color: 'secondary.light' }} />
                                    </IconButton>
                                  )}
                                </InputAdornment>
                              ),
                            }}
                            label="Количество"
                            // disabled={loading}
                          />
                        </Grid>
                        {item.units.length > 1 && (
                          <Grow
                            className="convertorForm"
                            in={isCalculateFormOpen}
                            style={{ position: 'absolute', left: '105%', top: '-10px' }}
                          >
                            <Paper elevation={8} sx={{ display: 'flex', marginTop: '10px' }}>
                              <FormControlCustom variant="standard">
                                <Grid container flexDirection="column">
                                  {item.units.map((unit, index) => (
                                    <ProductConverter
                                      key={index}
                                      unit={unit}
                                      initialTonneVal={initialTonneVal}
                                      handleSaveAmountFromConverter={handleSaveAmountFromConverter}
                                      isForSave={true}
                                      pricesSpaces={pricesSpaces}
                                    />
                                  ))}
                                </Grid>
                              </FormControlCustom>
                            </Paper>
                          </Grow>
                        )}
                        {typeCurrentStore === 'deal' && (
                          <CatalogAdditionalInfoForDealAgreement productCode={item.code} catalogStore={catalogStore} />
                        )}
                        {typeCurrentStore === 'deal' && (
                          <CatalogAdditionalInputsForDeal
                            catalogStore={catalogStore}
                            onSetLoading={v => {
                              setLoading(v);
                            }}
                            hashProduct={hashProduct}
                            stateAmount={stateAmount}
                          />
                        )}
                      </Grid>
                      {loading && (
                        <Grid item sx={{ position: 'absolut', bottom: '0' }}>
                          <LinearProgress />
                        </Grid>
                      )}
                    </FormControlCustom>
                  </Paper>
                </Grid>
              </Grow>
            </ClickAwayListener>
          </>
        )}
      </Popper>
    </>
  );
});
