import { RootStore } from './StoreManager';
import { makeAutoObservable, runInAction, set, toJS } from 'mobx';
import { ApiStore } from './Global/ApiStore';
import { CatalogProductsFastSearchResponse, Category, Product, SaleShipment } from 'src/api/marketx';
import { WarehouseListStoreNew } from './WarehouseListStore';
import { ProductPacksStore } from './ProductPacksStore';
import { AuthStore } from './AuthStore';
import { DatesRange, formatDateSwaggerZ } from '@mx-ui/helpers';
import { AppCategoriesCodes } from '../types/AppCatalog';
import { RouterStore } from './RouterStore';
import { uuid4 } from '@sentry/utils';
import { TopBarEntityStore, entityType } from './TopBarStore';
import { AxiosPromise } from 'axios';

const warehouseCode = '~total';
export class ProductItemStoreRequest {
  warehouseCode?: string;
  productCode?: string;
  subtab?: string;
  innerTab?: string;
  tab?: string;
}

export class ProductTopBarEntityStore implements TopBarEntityStore {
  productItemStore: ProductItemStore = null;

  constructor(productItemStore: ProductItemStore) {
    this.productItemStore = productItemStore;
    makeAutoObservable(this);
  }

  entityCode(): string {
    return this.productItemStore.product?.title;
  }

  titleForCatalog(): string {
    return 'Выберите товарную позицию для замены товара-лида';
  }

  titleForClient(): string {
    return '';
  }

  typeName(): entityType {
    return 'product';
  }

  customerTitle(): string {
    return '';
  }
}

export class ProductItemStore {
  warehouseListStore: WarehouseListStoreNew;
  product: Product = null;
  isLoaded = false;
  isLoading = true;
  isRedirecting = false;
  routerStore: RouterStore;
  public routerControlEnabled = false;

  isShipmentsLoaded = false;
  isShipmentsLoading = false;
  request: ProductItemStoreRequest = {};
  categories: Category[] = [];
  apiStore: ApiStore;
  authStore: AuthStore;
  rootStore: RootStore;
  productPacksStore: ProductPacksStore;
  shipments: SaleShipment[] = [];
  topBarEntityStore: ProductTopBarEntityStore;
  selectedProduct: Product = {};

  constructor(rootStore: RootStore) {
    this.authStore = rootStore.getAuth();
    this.rootStore = rootStore;
    this.routerStore = rootStore.getRouter();
    this.apiStore = rootStore.getApiStore();
    this.warehouseListStore = new WarehouseListStoreNew(rootStore);

    makeAutoObservable(this, {
      apiStore: false,
      rootStore: false,
    });
  }
  createFreeze(): void {
    if (!this.product?.code) {
      return;
    }
    runInAction(() => {
      this.isRedirecting = true;
    });
    this.apiStore
      .apiClientFreeze()
      .freezesSave({
        code: uuid4(),
        updates: {
          positions: [
            {
              code: uuid4(),
              productCode: this.product.code,
              quantity: 1,
              isArchive: false,
            },
          ],
        },
      })
      .then(res => {
        this.routerStore.push(`/app/freeze/${res.data.freeze.documentNumber}`);
      })
      .catch(e => {
        console.warn('createFreezeRequest', e);
        this.isRedirecting = false;
      });
  }
  get canCreateLeadLink(): boolean {
    return this.authStore.profile.resources?.some(i => i.uri === 'http://api/catalog/products/{productCode}/leadLink');
  }
  loadWarehouseList(warehouseCode?: string): void {
    const chosenBranchOfficeCode = this.authStore.profile.chosenBranchOfficeCode || this.authStore.profile.branchOfficeCode;
    this.warehouseListStore.loadListWithWarehouseCode(chosenBranchOfficeCode, warehouseCode);
  }
  setProduct(products: Array<Product>): void {
    this.product = products[0];
  }
  mergeRequest(req: ProductItemStoreRequest): void {
    set(this.request, req);
    this.actualizeRouter(toJS(this.request));
  }
  changeWarehouse(warehouseCode?: string): void {
    this.mergeRequest({ warehouseCode: warehouseCode });
    if (this.product?.code) {
      this.loadProduct(this.product.code);
    }
  }

  setCategories(categories: Array<Category>): void {
    const findChildByParent = (code: string, array: Category[], newArray: Category[]): Category[] => {
      const item = array.find(i => i.parentCode === code);
      if (item) {
        newArray.push(item);
        return findChildByParent(item.code, array, newArray);
      } else {
        return newArray;
      }
    };
    const categoriesNew = categories.filter(i => i.code !== AppCategoriesCodes.GOODS_CODE);
    const categoriesMain = categoriesNew.find(i => i.parentCode === AppCategoriesCodes.GOODS_CODE);
    if (categoriesMain) {
      const categoriesCrumb = [categoriesMain];
      this.categories = findChildByParent(categoriesMain.code, categoriesNew, categoriesCrumb);
    }
  }
  setRouterControl(enabled: boolean): void {
    this.routerControlEnabled = enabled;
  }
  actualizeRouter(req: ProductItemStoreRequest): void {
    if (!this.routerControlEnabled) {
      return;
    }
    const params = new URLSearchParams();

    if (req.warehouseCode) {
      params.set('warehouseCode', req.warehouseCode);
    }
    if (req.productCode) {
      params.set('productCode', req.productCode);
    }
    if (req.innerTab) {
      params.set('innerTab', req.innerTab);
    }
    if (req.tab) {
      params.set('tab', req.tab);
    }
    if (req.subtab) {
      params.set('subtab', req.subtab);
    }

    // params.set('csid', String(this.storeIdentifier));
    let paramsStr = params.toString();
    if (paramsStr) {
      paramsStr = '?' + paramsStr;
    }
    let url = '/app/product';
    url += paramsStr;
    this.routerStore.replace(url, undefined, { shallow: true });
  }
  setShipments(shipments: SaleShipment[]): void {
    this.shipments = shipments;
    this.isShipmentsLoaded = true;
    this.isShipmentsLoading = false;
  }

  getTopBarEntityStore(): ProductTopBarEntityStore {
    if (!this.topBarEntityStore) {
      this.topBarEntityStore = new ProductTopBarEntityStore(this);
    }
    return this.topBarEntityStore;
  }

  setSelectedProduct(product: Product): void {
    this.selectedProduct = product;
  }

  setCatalogProductsItemLeadLink(productCode: string, targetProductCode: string): AxiosPromise<void> {
    return this.apiStore.apiClientCatalog().catalogProductsItemLeadLink(productCode, { targetProductCode: targetProductCode });
  }

  loadProductShipmentByDatePeriod({ start, end }: DatesRange): void {
    runInAction(() => {
      this.isShipmentsLoading = true;
    });
    const offset = new Date().getTimezoneOffset();

    this.loadProductShipment(this.product.code, new Date(start.getTime() - offset * 60 * 1000), end);
  }
  async loadProductShipment(code: string, dateFrom: Date, dateTo: Date): Promise<void> {
    try {
      const { data } = await this.apiStore
        .apiClientCatalog()
        .catalogProductsItemShipments(code, formatDateSwaggerZ(dateFrom), formatDateSwaggerZ(dateTo));
      this.setShipments(data.shipments);
    } finally {
      runInAction(() => {
        this.isShipmentsLoading = false;
      });
    }
  }

  async loadProduct(code?: string): Promise<void> {
    if (!code) {
      this.isLoading = false;
      this.isLoaded = true;
      return;
    }
    this.isLoading = true;
    const req = Object.assign({}, this.request);
    try {
      const { data } = await this.apiStore
        .apiClientCatalog()
        .catalogProducts(code, undefined, undefined, undefined, undefined, undefined, undefined, req.warehouseCode);
      runInAction(() => {
        this.setProduct(data.products);
        this.setCategories(data.categories);
        this.productPacksStore = this.rootStore.getLocalStoreFor(this, 'ProductPacksStore', ProductPacksStore, store => {
          store.loadForProduct(this.product?.code, warehouseCode);
        });
        // * if this.dataPeriod = current_month
        // const value = getCurrentMonthRange();
        // this.loadProductShipmentByDatePeriod(value);
        this.isLoaded = true;
        this.isLoading = false;
      });
    } catch (err) {
      this.isLoading = false;
      this.isLoaded = true;
    }
  }

  async fastSearhInCatalog(query: string): Promise<CatalogProductsFastSearchResponse> {
    if (query !== '') {
      const branchOfficeCodeAuth = this.authStore.profile?.chosenBranchOfficeCode || this.authStore.profile?.branchOfficeCode;
      return this.apiStore
        .apiClientCatalog()
        .catalogProductsFastSearch({
          query: query || '',
          warehouse: this.request.warehouseCode, // отсутствует
          branchOffice: branchOfficeCodeAuth,
          limit: 30,
          // stockRequired,
        })
        .then((res): CatalogProductsFastSearchResponse => {
          return res.data;
        });
    } else {
      return Promise.resolve({ products: [], total: 0 });
    }
  }
}
